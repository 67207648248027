import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useUser } from '../userContext';
import { Menu, Transition } from '@headlessui/react';
import { UserGroupIcon, ChevronDownIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { UserCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useClient } from '../clientContext';

export default function ClientSelector({ sidebarExpanded }) {
  const navigate = useNavigate();
  const { businessProfile, switchActiveClient, agencyId, userProfile } = useUser();
  const { clients, refreshClients } = useClient();
  const [originalCompany, setOriginalCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (userProfile?.companyId) {
      setOriginalCompany(userProfile.companyId);
      setIsLoading(false);
    }
  }, [userProfile]);

  const isImpersonating = !isLoading && businessProfile?.id !== originalCompany;

  const getDropdownPosition = () => {
    if (!buttonRef.current) return {};
    const rect = buttonRef.current.getBoundingClientRect();
    return {
      top: `${rect.bottom + 8}px`,
      left: `${rect.left}px`
    };
  };

  return (
    <div className="relative" style={{ isolation: 'isolate' }}>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button 
              ref={buttonRef}
              className={`
                w-full inline-flex items-center gap-2 px-3 py-2 text-sm font-medium rounded-lg
                ${isLoading 
                  ? 'text-gray-700 bg-white dark:text-gray-200 dark:bg-gray-800'
                  : isImpersonating 
                    ? 'text-violet-700 bg-violet-50 hover:bg-violet-100 dark:text-violet-200 dark:bg-violet-900/50 dark:hover:bg-violet-900/70'
                    : 'text-gray-700 bg-white hover:bg-gray-50 dark:text-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700/70'
                } border border-gray-200 dark:border-gray-700/60 shadow-sm focus:outline-none transition-colors duration-150
              `}
            >
              {!sidebarExpanded ? (
                <UserGroupIcon className={`h-5 w-5 mx-auto ${isImpersonating ? 'text-violet-500' : 'text-gray-400 dark:text-gray-500'}`} />
              ) : (
                <>
                  <span className="flex items-center gap-2 truncate flex-1 text-left">
                    {isImpersonating ? (
                      <>
                        {businessProfile?.headlinePhoto ? (
                          <img 
                            src={businessProfile.headlinePhoto} 
                            alt={`${businessProfile.name} logo`}
                            className="h-5 w-5 flex-shrink-0 rounded-sm object-contain"
                          />
                        ) : (
                          <UserCircleIcon className="h-5 w-5 text-violet-500" />
                        )}
                        <span className="truncate">{businessProfile?.name}</span>
                      </>
                    ) : (
                      <>
                        <UserGroupIcon className="h-5 w-5 text-gray-400 dark:text-gray-500" />
                        <span className="truncate">Switch Client</span>
                      </>
                    )}
                  </span>
                  <ChevronDownIcon 
                    className={`h-5 w-5 flex-shrink-0 ${
                      isImpersonating ? 'text-violet-500' : 'text-gray-400 dark:text-gray-500'
                    }`} 
                    aria-hidden="true" 
                  />
                </>
              )}
            </Menu.Button>

            {open && createPortal(
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`
                    fixed z-[2147483647] rounded-lg bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
                    border border-gray-200 dark:border-gray-700/60
                    ${sidebarExpanded ? 'w-56' : 'w-48'}
                  `}
                  style={getDropdownPosition()}
                >
                  <div className="py-0">
                    {isImpersonating && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => switchActiveClient(null)}
                            className={`
                              flex items-center gap-2 w-full px-4 py-2 text-sm font-medium
                              ${active ? 'bg-violet-50 dark:bg-violet-900/50 text-violet-700 dark:text-violet-200' : 'text-violet-600 dark:text-violet-400'}
                              border-b border-gray-200 dark:border-gray-700/60
                            `}
                          >
                            <ArrowLeftIcon className="h-4 w-4" />
                            <span>Exit Client View</span>
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    
                    {clients.length > 0 ? (
                      <div className="py-0">
                        {clients.map((client) => (
                          <Menu.Item key={client.id}>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  switchActiveClient(client);
                                  navigate('/');
                                }}
                                className={`
                                  flex items-center w-full px-4 py-2 text-sm
                                  ${businessProfile?.id === client.id 
                                    ? 'bg-violet-50 text-violet-700 dark:bg-violet-900/50 dark:text-violet-200' 
                                    : 'text-gray-700 dark:text-gray-200'
                                  }
                                  ${active && businessProfile?.id !== client.id
                                    ? 'bg-gray-50 dark:bg-gray-700' 
                                    : ''
                                  }
                                `}
                              >
                                {client.headlinePhoto ? (
                                  <img 
                                    src={client.headlinePhoto} 
                                    alt={`${client.name} logo`}
                                    className="h-4 w-4 mr-2 flex-shrink-0 rounded-sm object-contain"
                                  />
                                ) : (
                                  <UserCircleIcon className="h-4 w-4 mr-2 flex-shrink-0" />
                                )}
                                <span className="truncate">{client.name}</span>
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                        
                        {!isImpersonating && (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => navigate('/clients')}
                                className={`
                                  flex items-center w-full px-4 py-2 text-sm border-t border-gray-200 dark:border-gray-700/60
                                  ${active ? 'bg-gray-50 dark:bg-gray-700' : ''}
                                  text-gray-700 dark:text-gray-200
                                `}
                              >
                                <PlusIcon className="h-4 w-4 mr-2 flex-shrink-0" />
                                Add New Client
                              </button>
                            )}
                          </Menu.Item>
                        )}
                      </div>
                    ) : (
                      <div className="py-0">
                        <a
                          href="/clients"
                          className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700"
                        >
                          <PlusIcon className="h-4 w-4 mr-2 flex-shrink-0" />
                          Add New Client
                        </a>
                      </div>
                    )}
                  </div>
                </Menu.Items>
              </Transition>,
              document.body
            )}
          </>
        )}
      </Menu>
    </div>
  );
} 