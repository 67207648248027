// Add at the top of the file
const DEFAULT_COLORS = {
  primary: '#4F46E5',
  secondary: '#4B5563',
  accent: '#4B5563'
};

const DEFAULT_FONTS = {
  primary: 'Inter',
  secondary: 'Inter'
};

// At the top of the file, add these default theme values
const DEFAULT_THEME = {
  colors: {
    primary: '#4F46E5',
    accent: '#4B5563',
    background: {
      gradient: {
        start: '#F3F4F6',
        end: '#E5E7EB'
      }
    },
    cardBackground: '#FFFFFF',
    cardOpacity: 0.9,
    titleText: '#1F2937',
    subtitleText: '#4B5563',
    buttonBackground: '#4F46E5',
    buttonText: '#FFFFFF',
    headlineText: '#1F2937',
    subheadlineText: '#4B5563',
    results: {
      cardBackground: '#FFFFFF',
      cardBackgroundOpacity: 0.9,
      scoreLabelText: '#4B5563',
      scoreValueText: '#1F2937',
      joinButtonGradientFrom: '#4F46E5',
      joinButtonGradientTo: '#7C3AED',
      joinButtonText: '#FFFFFF'
    },
    endCard: {
      background: '#FFFFFF',
      backgroundOpacity: 0.9,
      playAgainBackground: '#4F46E5',
      playAgainText: '#FFFFFF',
      shareBackground: '#4B5563',
      shareText: '#FFFFFF',
      ctaButtonBackground: '#7C3AED',
      ctaButtonText: '#FFFFFF',
      ctaButtonOpacity: 1
    },
    leaderboard: {
      background: '#FFFFFF',
      backgroundOpacity: 0.9,
      leaderboardTitleText: '#1F2937',
      rankText: '#6B7280',
      nameText: '#111827',
      scoreText: '#4F46E5',
      userBackground: '#E5E7EB',
      tabPrimary: '#4F46E5',
      tabSecondary: '#6B7280',
      joinButton: {
        background: '#4F46E5',
        text: '#FFFFFF',
        opacity: 1
      },
      joinButtonGradientFrom: '#4F46E5',
      joinButtonGradientTo: '#7C3AED'
    }
  },
  typography: {
    primaryFont: 'Inter',
    secondaryFont: 'Inter',
    titleFont: 'Inter',
    subtitleFont: 'Inter',
    scoreFont: 'Inter',
    scoreValueFont: 'Inter',
    leaderboardTitleFont: 'Inter',
    rankFont: 'Inter',
    nameFont: 'Inter',
    joinButtonFont: 'Inter',
    ctaButtonFont: 'Inter'
  }
};

// Color utility functions
const hexToRgb = (hex) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return { r, g, b };
};

const rgbToHex = (r, g, b) => {
  // Ensure values are numbers and within valid range (0-255)
  const safeValue = (val) => {
    const num = Number(val);
    if (isNaN(num)) return 0;
    return Math.max(0, Math.min(255, Math.round(num)));
  };

  try {
    const values = [r, g, b].map(safeValue);
    return `#${values.map(x => x.toString(16).padStart(2, '0')).join('')}`;
  } catch (error) {
    console.error('Error in rgbToHex:', error);
    return '#000000'; // Fallback to black if conversion fails
  }
};

// Calculate relative luminance for contrast calculations
const getLuminance = (hex) => {
  const { r, g, b } = hexToRgb(hex);
  const [rs, gs, bs] = [r, g, b].map(c => {
    c = c / 255;
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
};

// Calculate contrast ratio between two colors
const getContrastRatio = (color1, color2) => {
  const l1 = getLuminance(color1);
  const l2 = getLuminance(color2);
  const lighter = Math.max(l1, l2);
  const darker = Math.min(l1, l2);
  return (lighter + 0.05) / (darker + 0.05);
};

// Adjust color to meet minimum contrast ratio
const ensureContrast = (color, backgroundColor, minContrast = 4.5) => {
  let { r, g, b } = hexToRgb(color);
  const currentContrast = getContrastRatio(color, backgroundColor);
  
  if (currentContrast >= minContrast) return color;

  const targetLuminance = getLuminance(backgroundColor);
  const step = targetLuminance > 0.5 ? -5 : 5;
  
  while (getContrastRatio(rgbToHex(r, g, b), backgroundColor) < minContrast) {
    r = Math.max(0, Math.min(255, r + step));
    g = Math.max(0, Math.min(255, g + step));
    b = Math.max(0, Math.min(255, b + step));
  }

  return rgbToHex(r, g, b);
};

// Improved darken function with luminance preservation
const darkenColor = (hex, factor = 0.8) => {
  const { r, g, b } = hexToRgb(hex);
  const hsl = rgbToHsl(r, g, b);
  hsl.l = Math.max(0, hsl.l * factor);
  const rgb = hslToRgb(hsl.h, hsl.s, hsl.l);
  return rgbToHex(rgb.r, rgb.g, rgb.b);
};

// HSL conversion utilities (for better color manipulation)
const rgbToHsl = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return { h, s, l };
};

const hslToRgb = (h, s, l) => {
  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
};

// Modify the getStylePresets function to be more defensive
export const getStylePresets = (gameTypeColor, businessProfile, gameType) => {
  try {
    if (typeof gameTypeColor !== 'string') {
      gameTypeColor = DEFAULT_COLORS.primary;
    }

    if (typeof gameType !== 'string') {
      gameType = '';
    }

    const defaultTheme = {
      colors: {
        primary: gameTypeColor || DEFAULT_COLORS.primary,
        accent: DEFAULT_COLORS.accent
      },
      typography: {
        primaryFont: DEFAULT_FONTS.primary,
        secondaryFont: DEFAULT_FONTS.secondary,
        titleFont: DEFAULT_FONTS.primary,
        subtitleFont: DEFAULT_FONTS.secondary,
        scoreFont: DEFAULT_FONTS.primary,
        scoreValueFont: DEFAULT_FONTS.primary,
        leaderboardTitleFont: DEFAULT_FONTS.primary,
        rankFont: DEFAULT_FONTS.secondary,
        nameFont: DEFAULT_FONTS.primary,
        joinButtonFont: DEFAULT_FONTS.primary,
        ctaButtonFont: DEFAULT_FONTS.primary
      }
    };

    const theme = defaultTheme;
    if (businessProfile?.theme?.colors?.primary) {
      theme.colors.primary = businessProfile.theme.colors.primary;
    }
    if (businessProfile?.theme?.colors?.accent) {
      theme.colors.accent = businessProfile.theme.colors.accent;
    }
    if (businessProfile?.theme?.typography) {
      const { primaryFont, secondaryFont } = businessProfile.theme.typography;
      
      theme.typography = {
        primaryFont: primaryFont || DEFAULT_FONTS.primary,
        secondaryFont: secondaryFont || DEFAULT_FONTS.secondary,
        titleFont: primaryFont || DEFAULT_FONTS.primary,
        subtitleFont: secondaryFont || DEFAULT_FONTS.secondary,
        scoreFont: primaryFont || DEFAULT_FONTS.primary,
        scoreValueFont: primaryFont || DEFAULT_FONTS.primary,
        leaderboardTitleFont: primaryFont || DEFAULT_FONTS.primary,
        rankFont: secondaryFont || DEFAULT_FONTS.secondary,
        nameFont: primaryFont || DEFAULT_FONTS.primary,
        joinButtonFont: primaryFont || DEFAULT_FONTS.primary,
        ctaButtonFont: primaryFont || DEFAULT_FONTS.primary
      };
    }

    const primaryColor = theme.colors.primary;
    const accentColor = theme.colors.accent;

    let gradientEndColor;
    try {
      const rgb = hexToRgb(primaryColor);
      if (!rgb) throw new Error('Invalid RGB conversion');
      
      const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
      if (!hsl) throw new Error('Invalid HSL conversion');
      
      const lighterPrimary = hslToRgb(hsl.h, hsl.s, Math.min(hsl.l * 1.2, 0.9));
      if (!lighterPrimary) throw new Error('Invalid lighter color conversion');
      
      gradientEndColor = rgbToHex(lighterPrimary.r, lighterPrimary.g, lighterPrimary.b);
      if (!gradientEndColor) throw new Error('Invalid hex conversion');
    } catch (colorError) {
      gradientEndColor = primaryColor;
    }

    // Return basic presets if anything goes wrong
    const presets = {
      default: {
        colors: {
          primary: primaryColor,
          accent: accentColor,
          background: {
            gradient: {
              start: '#F3F4F6',
              end: '#E5E7EB'
            }
          }
        },
        typography: theme.typography
      }
    };

    // Only add other presets if we have valid colors
    if (primaryColor && accentColor) {
      // Brand preset
      presets.brand = {
        colors: {
          background: {
            gradient: {
              start: primaryColor,
              end: darkenColor(primaryColor)
            }
          },
          cardBackground: '#FFFFFF',
          cardOpacity: 0.95,
          titleText: '#1F2937',
          subtitleText: '#4B5563',
          buttonBackground: primaryColor,
          buttonText: '#FFFFFF',
          headlineText: '#1F2937',
          subheadlineText: '#4B5563',
          results: {
            cardBackground: '#FFFFFF',
            cardBackgroundOpacity: 0.95,
            scoreLabelText: '#4B5563',
            scoreValueText: '#1F2937',
            joinButtonGradientFrom: primaryColor,
            joinButtonGradientTo: gradientEndColor,
            joinButtonText: '#FFFFFF',
          },
          endCard: {
            background: '#FFFFFF',
            backgroundOpacity: 0.95,
            playAgainBackground: '#374151',
            playAgainText: '#FFFFFF',
            shareBackground: '#374151',
            shareText: '#FFFFFF',
            ctaButtonBackground: primaryColor,
            ctaButtonText: '#FFFFFF',
            ctaButtonOpacity: 1
          },
          leaderboard: {
            background: '#FFFFFF',
            backgroundOpacity: 0.95,
            leaderboardTitleText: '#1F2937',
            rankText: '#6B7280',
            nameText: '#6B7280',
            scoreText: accentColor,
            userBackground: `${primaryColor}20`,
            tabPrimary: primaryColor,
            tabSecondary: '#6B7280',
            joinButtonGradientFrom: primaryColor,
            joinButtonGradientTo: gradientEndColor,
            joinButtonText: '#FFFFFF'
          }
        },
        typography: {
          ...theme.typography,
          buttonFont: theme.typography.primaryFont,
          playAgainFont: theme.typography.primaryFont,
          shareFont: theme.typography.primaryFont,
          startButtonFont: theme.typography.primaryFont
        }
      };

      // Transparent preset
      presets.transparent = {
        colors: {
          background: {
            gradient: {
              start: '#E2E8F0',
              end: '#CBD5E1'
            }
          },
          cardBackground: '#1F2937',
          cardOpacity: 0.6,
          titleText: '#FFFFFF',
          subtitleText: '#E5E7EB',
          buttonBackground: '#0F172A',
          buttonText: '#FFFFFF',
          headlineText: '#FFFFFF',
          subheadlineText: '#E5E7EB',
          results: {
            cardBackground: '#1F2937',
            cardBackgroundOpacity: 0.6,
            scoreLabelText: '#E5E7EB',
            scoreValueText: '#FFFFFF',
            joinButtonGradientFrom: primaryColor,
            joinButtonGradientTo: gradientEndColor,
            joinButtonText: '#FFFFFF'
          },
          endCard: {
            background: '#1F2937',
            backgroundOpacity: 0.6,
            playAgainBackground: '#374151',
            playAgainText: '#FFFFFF',
            shareBackground: '#374151',
            shareText: '#FFFFFF',
            ctaButtonBackground: primaryColor,
            ctaButtonText: '#FFFFFF',
            ctaButtonOpacity: 0.9
          },
          leaderboard: {
            background: '#1F2937',
            backgroundOpacity: 0.6,
            leaderboardTitleText: '#FFFFFF',
            rankText: '#E5E7EB',
            nameText: '#FFFFFF',
            scoreText: '#FFFFFF',
            userBackground: '#374151',
            tabPrimary: primaryColor,
            tabSecondary: 'rgba(255, 255, 255, 0.6)',
            joinButtonGradientFrom: primaryColor,
            joinButtonGradientTo: gradientEndColor,
            joinButtonText: '#FFFFFF'
          }
        },
        typography: {
          ...theme.typography,
          buttonFont: theme.typography.primaryFont,
          playAgainFont: theme.typography.primaryFont,
          shareFont: theme.typography.primaryFont,
          startButtonFont: theme.typography.primaryFont
        }
      };

      // Dark preset
      presets.dark = {
        colors: {
          background: {
            gradient: {
              start: '#18181B',
              end: '#27272A'
            }
          },
          cardBackground: '#111827',
          cardOpacity: 0.95,
          titleText: '#FFFFFF',
          subtitleText: '#9CA3AF',
          buttonBackground: '#374151',
          buttonText: '#FFFFFF',
          headlineText: '#FFFFFF',
          subheadlineText: '#9CA3AF',
          results: {
            cardBackground: '#111827',
            cardBackgroundOpacity: 0.95,
            scoreLabelText: '#9CA3AF',
            scoreValueText: '#FFFFFF',
            joinButtonGradientFrom: '#374151',
            joinButtonGradientTo: '#1F2937',
            joinButtonText: '#FFFFFF'
          },
          endCard: {
            background: '#111827',
            backgroundOpacity: 0.95,
            playAgainBackground: '#374151',
            playAgainText: '#FFFFFF',
            shareBackground: '#374151',
            shareText: '#FFFFFF',
            ctaButtonBackground: '#374151',
            ctaButtonText: '#FFFFFF',
            ctaButtonOpacity: 1
          },
          leaderboard: {
            background: '#111827',
            backgroundOpacity: 0.95,
            leaderboardTitleText: '#FFFFFF',
            rankText: '#9CA3AF',
            nameText: '#FFFFFF',
            scoreText: '#E5E7EB',
            userBackground: '#1F2937',
            tabPrimary: '#374151',
            tabSecondary: '#94A3B8',
            joinButtonGradientFrom: '#374151',
            joinButtonGradientTo: '#1F2937',
            joinButtonText: '#FFFFFF'
          }
        },
        typography: {
          ...theme.typography,
          buttonFont: theme.typography.primaryFont,
          playAgainFont: theme.typography.primaryFont,
          shareFont: theme.typography.primaryFont,
          startButtonFont: theme.typography.primaryFont
        }
      };

      // Add game-specific styles based on gameType
      if (gameType) {
        try {
          Object.keys(presets).forEach(style => {
            if (gameType === 'snake') {
              presets[style].colors.game = {
                canvasColor: presets[style].colors.cardBackground || '#FFFFFF',
                snakeColor: style === 'transparent' ? '#FFFFFF' : theme.colors?.primary || DEFAULT_COLORS.primary,
                foodColor: style === 'dark' ? '#FFFFFF' : theme.colors?.accent || DEFAULT_COLORS.accent,
                controlButtonColor: style === 'transparent' ? '#FFFFFF' : presets[style].colors.cardBackground || '#FFFFFF',
                controlButtonOpacity: style === 'transparent' ? 0.9 : presets[style].colors.cardOpacity || 1,
                controlIconColor: style === 'transparent' ? '#1F2937' : (style === 'dark' ? '#FFFFFF' : theme.colors?.primary || DEFAULT_COLORS.primary),
                scoreText: style === 'default' ? '#1F2937' : '#FFFFFF'
              };
            } else if (gameType === 'hint') {
              presets[style].colors.game = {
                // Default style
                ...(style === 'default' && {
                  hintBackground: '#FFFFFF',
                  hintBackgroundOpacity: 0.9,
                  hintText: '#1F2937',
                  inputBackground: '#FFFFFF',
                  inputBackgroundOpacity: 0.9,
                  inputText: '#1F2937',
                  inputBorder: '#E5E7EB',
                  inputPlaceholder: '#9CA3AF',
                  resultBackground: '#FFFFFF',
                  resultBackgroundOpacity: 0.9,
                  resultText: '#1F2937',
                  resultHoverBackground: '#F3F4F6',
                  keyboardBackground: '#F3F4F6',
                  keyboardOpacity: 0.9,
                  keyboardButtonBackground: '#FFFFFF',
                  keyboardButtonOpacity: 1,
                  keyboardText: '#1F2937',
                  keyboardBorder: '#E5E7EB',
                  skipButtonBackground: '#4B5563',
                  skipButtonBackgroundOpacity: 0.1,
                  skipButtonText: '#4B5563',
                  skipButtonHoverBackground: '#6B728020'
                }),

                // Brand style
                ...(style === 'brand' && {
                  hintBackground: '#FFFFFF',
                  hintBackgroundOpacity: 0.95,
                  hintText: '#1F2937',
                  inputBackground: '#FFFFFF',
                  inputBackgroundOpacity: 0.95,
                  inputText: '#1F2937',
                  inputBorder: theme.colors.primary,
                  inputPlaceholder: '#9CA3AF',
                  resultBackground: '#FFFFFF',
                  resultBackgroundOpacity: 0.95,
                  resultText: '#1F2937',
                  resultHoverBackground: `${theme.colors.primary}10`,
                  keyboardBackground: '#FFFFFF',
                  keyboardOpacity: 0.95,
                  keyboardButtonBackground: '#FFFFFF',
                  keyboardButtonOpacity: 1,
                  keyboardText: theme.colors.primary,
                  keyboardBorder: '#E5E7EB',
                  skipButtonBackground: theme.colors.primary,
                  skipButtonBackgroundOpacity: 0.1,
                  skipButtonText: theme.colors.primary,
                  skipButtonHoverBackground: `${theme.colors.primary}20`
                }),

                // Transparent style
                ...(style === 'transparent' && {
                  hintBackground: '#1F2937',
                  hintBackgroundOpacity: 0.6,
                  hintText: '#FFFFFF',
                  inputBackground: '#1F2937',
                  inputBackgroundOpacity: 0.6,
                  inputText: '#FFFFFF',
                  inputBorder: '#374151',
                  inputPlaceholder: '#F9FAFB',
                  resultBackground: '#1F2937',
                  resultBackgroundOpacity: 0.7,
                  resultText: '#FFFFFF',
                  resultHoverBackground: '#374151',
                  keyboardBackground: '#1F2937',
                  keyboardOpacity: 0.6,
                  keyboardButtonBackground: '#374151',
                  keyboardButtonOpacity: 0.9,
                  keyboardText: '#FFFFFF',
                  keyboardBorder: '#374151',
                  skipButtonBackground: '#FFFFFF',
                  skipButtonBackgroundOpacity: 0.1,
                  skipButtonText: '#FFFFFF',
                  skipButtonHoverBackground: 'rgba(255, 255, 255, 0.2)'
                }),

                // Dark style
                ...(style === 'dark' && {
                  hintBackground: '#1F2937',
                  hintBackgroundOpacity: 0.95,
                  hintText: '#FFFFFF',
                  inputBackground: '#1F2937',
                  inputBackgroundOpacity: 0.95,
                  inputText: '#FFFFFF',
                  inputBorder: '#374151',
                  inputPlaceholder: '#E5E7EB',
                  resultBackground: '#1F2937',
                  resultBackgroundOpacity: 0.95,
                  resultText: '#FFFFFF',
                  resultHoverBackground: '#374151',
                  keyboardBackground: '#111827',
                  keyboardOpacity: 0.95,
                  keyboardButtonBackground: '#1F2937',
                  keyboardButtonOpacity: 1,
                  keyboardText: '#FFFFFF',
                  keyboardBorder: '#374151',
                  skipButtonBackground: '#FFFFFF',
                  skipButtonBackgroundOpacity: 0.1,
                  skipButtonText: '#FFFFFF',
                  skipButtonHoverBackground: 'rgba(255, 255, 255, 0.2)'
                })
              };
            } else if (gameType === 'mapselect') {
              presets[style].colors.game = {
                // Default style
                ...(style === 'default' && {
                  inputBackground: '#FFFFFF',
                  inputBackgroundOpacity: 0.9,
                  searchButtonBackground: '#4F46E5',
                  searchButtonOpacity: 1,
                  searchButtonBorder: '#4F46E5',
                  searchButtonText: '#FFFFFF',
                  inputText: '#111827',
                  inputBorder: '#D1D5DB',
                  inputPlaceholder: '#6B7280',
                  resultsBackground: '#FFFFFF',
                  resultsBackgroundOpacity: 0.9,
                  resultsText: '#111827',
                  resultsHoverBackground: '#F3F4F6',
                  keyboard: {
                    background: '#FFFFFF',
                    opacity: 0.9,
                    buttonBackground: '#E5E7EB',
                    buttonOpacity: 1,
                    text: '#111827',
                    border: '#D1D5DB',
                    uppercase: true
                  }
                }),

                // Brand style
                ...(style === 'brand' && {
                  inputBackground: '#FFFFFF',
                  inputBackgroundOpacity: 0.9,
                  searchButtonBackground: theme.colors.primary,
                  searchButtonOpacity: 1,
                  searchButtonBorder: theme.colors.primary,
                  searchButtonText: '#FFFFFF',
                  inputText: '#111827',
                  inputBorder: theme.colors.primary,
                  inputPlaceholder: '#6B7280',
                  resultsBackground: '#FFFFFF',
                  resultsBackgroundOpacity: 0.9,
                  resultsText: '#111827',
                  resultsHoverBackground: '#F3F4F6',
                  keyboard: {
                    background: '#FFFFFF',
                    opacity: 0.9,
                    buttonBackground: '#E5E7EB',
                    buttonOpacity: 1,
                    text: theme.colors.primary,
                    border: '#D1D5DB',
                    uppercase: true
                  }
                }),

                // Transparent style
                ...(style === 'transparent' && {
                  inputBackground: '#1F2937',
                  inputBackgroundOpacity: 0.85,
                  searchButtonBackground: '#FFFFFF',
                  searchButtonOpacity: 0.2,
                  searchButtonBorder: '#FFFFFF',
                  searchButtonText: '#FFFFFF',
                  inputText: '#FFFFFF',
                  inputBorder: '#FFFFFF',
                  inputPlaceholder: '#FFFFFF',
                  resultsBackground: '#1F2937',
                  resultsBackgroundOpacity: 0.85,
                  resultsText: '#FFFFFF',
                  resultsHoverBackground: '#374151',
                  keyboardBackground: '#1F2937',
                  keyboardOpacity: 0.6,
                  keyboardButtonBackground: '#374151',
                  keyboardButtonOpacity: 0.9,
                  keyboardText: '#FFFFFF',
                  keyboardBorder: '#374151',
                  skipButtonBackground: '#FFFFFF',
                  skipButtonBackgroundOpacity: 0.1,
                  skipButtonText: '#FFFFFF',
                  skipButtonHoverBackground: 'rgba(255, 255, 255, 0.2)'
                }),

                // Dark style
                ...(style === 'dark' && {
                  inputBackground: '#1F2937',
                  inputBackgroundOpacity: 0.9,
                  searchButtonBackground: '#374151',
                  searchButtonOpacity: 1,
                  searchButtonBorder: '#374151',
                  searchButtonText: '#FFFFFF',
                  inputText: '#FFFFFF',
                  inputBorder: '#E5E7EB',
                  inputPlaceholder: '#9CA3AF',
                  resultsBackground: '#1F2937',
                  resultsBackgroundOpacity: 0.9,
                  resultsText: '#FFFFFF',
                  resultsHoverBackground: '#374151',
                  keyboardBackground: '#111827',
                  keyboardOpacity: 0.9,
                  keyboardButtonBackground: '#1F2937',
                  keyboardButtonOpacity: 1,
                  keyboardText: '#FFFFFF',
                  keyboardBorder: '#E5E7EB',
                  skipButtonBackground: '#FFFFFF',
                  skipButtonBackgroundOpacity: 0.1,
                  skipButtonText: '#FFFFFF',
                  skipButtonHoverBackground: 'rgba(255, 255, 255, 0.2)'
                })
              };
            } else if (gameType === 'quiz') {
              presets[style].colors.game = {
                // Default style
                ...(style === 'default' && {
                  surface: {
                    background: '#FFFFFF',
                    opacity: 0.9,
                    questionText: '#1F2937',
                    questionBadgeBackground: '#4B5563',
                    questionBadgeOpacity: 0.1,
                    questionBadgeText: '#4B5563',
                    answerBackground: '#FFFFFF',
                    answerOpacity: 0.9,
                    answerText: '#1F2937',
                    hintText: '#4B5563'
                  }
                }),

                // Brand style
                ...(style === 'brand' && {
                  surface: {
                    background: '#FFFFFF',
                    opacity: 0.98,
                    questionText: '#1F2937',
                    questionBadgeBackground: primaryColor,
                    questionBadgeOpacity: 0.1,
                    questionBadgeText: primaryColor,
                    answerBackground: '#FFFFFF',
                    answerOpacity: 1,
                    answerText: '#1F2937',
                    hintText: primaryColor
                  }
                }),

                // Transparent style
                ...(style === 'transparent' && {
                  surface: {
                    background: '#1F2937',
                    opacity: 0.6,
                    questionText: '#FFFFFF',
                    questionBadgeBackground: '#FFFFFF',
                    questionBadgeOpacity: 0.1,
                    questionBadgeText: '#FFFFFF',
                    answerBackground: '#374151',
                    answerOpacity: 0.6,
                    answerText: '#FFFFFF',
                    hintText: '#FFFFFF'
                  }
                }),

                // Dark style
                ...(style === 'dark' && {
                  surface: {
                    background: '#111827',
                    opacity: 0.95,
                    questionText: '#FFFFFF',
                    questionBadgeBackground: '#374151',
                    questionBadgeOpacity: 0.1,
                    questionBadgeText: '#9CA3AF',
                    answerBackground: '#1F2937',
                    answerOpacity: 0.95,
                    answerText: '#FFFFFF',
                    hintText: '#9CA3AF'
                  }
                })
              };
            } else if (gameType === 'pindrop') {
              presets[style].colors.game = {
                // Default style
                ...(style === 'default' && {
                  topSection: '#145203',
                  textColor: '#FFFFFF',
                  topSectionOpacity: 1
                }),

                // Brand style
                ...(style === 'brand' && {
                  topSection: primaryColor,
                  textColor: '#FFFFFF',
                  topSectionOpacity: 0.95
                }),

                // Transparent style
                ...(style === 'transparent' && {
                  topSection: '#1F2937',
                  textColor: '#FFFFFF',
                  topSectionOpacity: 0.6
                }),

                // Dark style
                ...(style === 'dark' && {
                  topSection: '#111827',
                  textColor: '#FFFFFF',
                  topSectionOpacity: 0.95
                })
              };
            } else if (gameType === 'cubefield') {
              presets[style].colors.game = {
                // Default style
                ...(style === 'default' && {
                  backgroundColor: '#FFFFFF',
                  playerColor: '#EF4444',
                  cubeColor: '#4F46E5',
                  gridColor: '#E5E7EB',
                  scoreBackground: '#FFFFFF',
                  scoreBackgroundOpacity: 0.9,
                  scoreText: '#1F2937',
                  controlButtonColor: '#1F2937',
                  controlButtonOpacity: 0.9,
                  controlIconColor: '#FFFFFF'
                }),

                // Brand style
                ...(style === 'brand' && {
                  backgroundColor: '#FFFFFF',
                  playerColor: '#EF4444',
                  cubeColor: primaryColor,
                  gridColor: '#E5E7EB',
                  scoreBackground: '#FFFFFF',
                  scoreBackgroundOpacity: 0.9,
                  scoreText: primaryColor,
                  controlButtonColor: primaryColor,
                  controlButtonOpacity: 0.9,
                  controlIconColor: '#FFFFFF'
                }),

                // Transparent style - keep dark background
                ...(style === 'transparent' && {
                  backgroundColor: '#111827',
                  playerColor: '#EF4444',
                  cubeColor: '#4F46E5',
                  gridColor: '#333333',
                  scoreBackground: '#1F2937',
                  scoreBackgroundOpacity: 0.6,
                  scoreText: '#FFFFFF',
                  controlButtonColor: '#1F2937',
                  controlButtonOpacity: 0.9,
                  controlIconColor: '#FFFFFF'
                }),

                // Dark style - keep dark background
                ...(style === 'dark' && {
                  backgroundColor: '#111827',
                  playerColor: '#EF4444',
                  cubeColor: '#4F46E5',
                  gridColor: '#333333',
                  scoreBackground: '#1F2937',
                  scoreBackgroundOpacity: 0.9,
                  scoreText: '#FFFFFF',
                  controlButtonColor: '#1F2937',
                  controlButtonOpacity: 0.9,
                  controlIconColor: '#FFFFFF'
                })
              };
            }
          });
        } catch (gameError) {
          console.error('Error adding game-specific styles:', gameError);
        }
      }
    }

    return presets;

  } catch (error) {
    return {
      default: DEFAULT_THEME
    };
  }
}; 