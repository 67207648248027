import React, { useState, useRef, useEffect } from 'react';
import { useUser } from '../userContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

export default function Profile() {
  const { user } = useUser();
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    if (user) {
      async function fetchUserProfile() {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setFirstName(data.firstName);
          setLastName(data.lastName);
        }
      }
      fetchUserProfile();
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      try {
        await updateDoc(userDocRef, { firstName, lastName });
        setUnsavedChanges(false);
        setSaveSuccess(true);
      } catch (error) {
        console.error("Error updating profile: ", error);
      } finally {
        setTimeout(() => setSaveSuccess(false), 3000); // Clear success badge after 3 seconds
      }
    }
  };

  return (
    <div className="w-full px-8 py-6">
      <form onSubmit={handleSubmit}>
        <div className="space-y-12 sm:space-y-16">
          <div>
            <div className="flex items-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-700">Account</h2>
              {unsavedChanges && (
                <span className="ml-2 inline-flex items-center rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">
                  Unsaved changes
                </span>
              )}
              {saveSuccess && (
                <span className="ml-2 inline-flex items-center rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                  Changes saved
                </span>
              )}
            </div>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Change your personal information.
            </p>
            <div className="mt-5 space-y-4 mb-5">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                <div>
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    First name
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        maxLength="20"
                        autoComplete="given-name"
                        className="block flex-1 border-0 bg-transparent ml-2 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-md sm:text-sm sm:leading-6"
                        value={firstName}
                        required
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          setUnsavedChanges(true);
                          setSaveSuccess(false);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Last name
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        maxLength="20"
                        autoComplete="family-name"
                        className="block flex-1 border-0 bg-transparent ml-2 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-md sm:text-sm sm:leading-6"
                        value={lastName}
                        required
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setUnsavedChanges(true);
                          setSaveSuccess(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2 bg-gray-100">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block flex-1 border-0 bg-transparent ml-2 py-1.5 pl-1 text-gray-500 focus:ring-0 text-md sm:text-sm sm:leading-6 cursor-default"
                      value={user.email}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-start">
              <button
                type="submit"
                disabled={!unsavedChanges}
                className={`inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full sm:w-auto ${
                  unsavedChanges ? 'bg-violet-500 hover:bg-violet-600 focus-visible:outline-violet-600' : 'bg-gray-400 cursor-not-allowed'
                }`}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
