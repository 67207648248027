import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalBlank from '../components/ModalBlank';
import PlanSelection from '../account/Plans';
import { useUser } from '../userContext';
import { ArrowRightIcon, RocketLaunchIcon } from '@heroicons/react/20/solid';
import { SquareLoader } from 'react-spinners';
import { auth } from '../firebase';
import { onAuthStateChanged, sendEmailVerification } from 'firebase/auth';

const SubscriptionModal = ({
  showModal,
  setShowModal,
  handleSignOut,
  onClose,
}) => {
  const [currentStep, setCurrentStep] = useState('welcome');
  const [planRedirectLoading, setPlanRedirectLoading] = useState(false);
  const { subscriptionStatus } = useUser();
  const navigate = useNavigate();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationTimer, setVerificationTimer] = useState(0);
  const [notice, setNotice] = useState('');

  useEffect(() => {
    if (showModal) {
      if (window.location.search.includes('session_id')) {
        if (['active', 'trialing'].includes(subscriptionStatus)) {
          setCurrentStep('subscriptionSuccess');
          setTimeout(() => {
            setCurrentStep('thankYou');
          }, 1000);
        } else {
          setCurrentStep('planSelection');
        }
      } else if (subscriptionStatus === 'expired') {
        setCurrentStep('planSelection');
      } else {
        setCurrentStep('welcome');
      }
    }
  }, [subscriptionStatus, showModal]);

  useEffect(() => {
    if (showModal && auth.currentUser) {
      // Initial check
      const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === 'smilesflowdev-1514f';
      setIsEmailVerified(isDev ? true : auth.currentUser.emailVerified);
      
      // Set up listener for auth state changes
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          if (isDev || user.emailVerified) {
            setIsEmailVerified(true);
            setCurrentStep('planSelection');
            setNotice(''); // Clear any existing notice
          } else {
            setIsEmailVerified(false);
          }
        }
      });

      // Clean up listener when modal closes or component unmounts
      return () => unsubscribe();
    }
  }, [showModal]);

  const handleGoToDashboard = () => {
    const currentUrl = window.location.href;
    const newUrl = currentUrl.split('/success')[0];
    window.history.replaceState({}, '', newUrl);
    setShowModal(false);
  };

  const handleResendVerification = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setVerificationSent(true);
      setVerificationTimer(60);
    } catch (error) {
      // Error handling without console.log
    }
  };

  useEffect(() => {
    let timer;
    if (verificationTimer > 0) {
      timer = setInterval(() => {
        setVerificationTimer(prev => prev - 1);
      }, 1000);
    } else {
      setVerificationSent(false);
    }
    return () => clearInterval(timer);
  }, [verificationTimer]);

  const checkVerification = async () => {
    if (auth.currentUser) {
      try {
        await auth.currentUser.reload();
        const isVerified = auth.currentUser.emailVerified;
        setIsEmailVerified(isVerified);
        
        if (isVerified) {
          setCurrentStep('planSelection');
        } else {
          setNotice('Email not yet verified. Please check your inbox and click the verification link.');
        }
      } catch (error) {
        // Error handling without console.log
      }
    }
  };

  const renderModalContent = () => {
    const hasNeverSubscribed = !['active', 'trialing'].includes(subscriptionStatus);

    if (planRedirectLoading) {
      return (
        <div className="flex flex-col items-center justify-center py-20">
          <div className='py-5'>
            <SquareLoader size={15} color="#4f46e5" />
          </div>
          <p className="text-sm text-gray-500 mt-2">Preparing your plan...</p>
        </div>
      );
    }

    switch (currentStep) {
      case 'welcome':
        return (
          <div className="relative overflow-hidden rounded-t-lg">
            <img className="w-full" src="/welcome.png" width="40" height="200" alt="Welcome to SmilesFlow" />
            <div className="text-center py-5 px-5">
              {!isEmailVerified ? (
                <>
                  <h3 className="text-lg font-semibold mb-2">Email Verification Required</h3>
                  <p className="mb-4">We've sent a verification link to your email. Click the link in your inbox to continue. We'll automatically detect when you've verified.</p>
                  {notice && (
                    <p className="text-sm text-red-500 mb-3">{notice}</p>
                  )}
                  <button
                    onClick={checkVerification}
                    className="btn py-2 bg-violet-500 rounded hover:bg-violet-600 text-white text-sm mb-3 w-full"
                  >
                    Check Verification Status
                  </button>
                  {!verificationSent ? (
                    <button
                      onClick={handleResendVerification}
                      className="text-sm text-violet-600 hover:text-violet-700"
                    >
                      Resend verification email
                    </button>
                  ) : (
                    <p className="text-sm text-gray-500">
                      Verification email sent. Please wait {verificationTimer}s to resend.
                    </p>
                  )}
                  <div className="mt-4 pt-4 border-t">
                    <button
                      className="text-xs text-blue-500 hover:text-blue-600 font-medium"
                      onClick={handleSignOut}
                    >
                      Sign in with a different account
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <p>We're excited to have you here. {hasNeverSubscribed && <span className="font-medium">Start with a 7-day free trial and</span>} select the best plan for your needs.</p>
                  <button
                    className="btn py-2 bg-gray-800 rounded hover:bg-gray-900 text-white text-sm mt-4 flex items-center justify-center"
                    onClick={() => setCurrentStep('planSelection')}
                  >
                    {hasNeverSubscribed ? 'Start Free Trial' : 'Get Started'}
                    <ArrowRightIcon className="w-4 h-4 ml-2" />
                  </button>
                </>
              )}
            </div>
          </div>
        );
      case 'subscriptionSuccess':
        return (
          <div className="flex flex-col items-center justify-center py-20">
            <div className='py-5'>
              <SquareLoader size={15} color="#4f46e5" />
            </div>
          </div>
        );
      case 'planSelection':
        return (
          <>
            <h2 className="text-lg px-5 mt-5 font-semibold">
              {subscriptionStatus === 'expired' ? 'Your plan has expired' : 'Choose your plan'}
            </h2>
            <p className="px-5">
              {subscriptionStatus === 'expired' 
                ? 'Please select a new plan to continue.'
                : hasNeverSubscribed 
                  ? 'Start with a 7-day free trial on any plan.'
                  : 'Please choose a plan to continue.'}
            </p>
            <PlanSelection isModal={true} showTrial={hasNeverSubscribed} />
          </>
        );
      case 'thankYou':
        return (
          <div className="text-center py-10 px-5">
            <div className="w-16 h-16 mx-auto mb-4 flex items-center justify-center bg-violet-500 rounded-full">
              <RocketLaunchIcon className="h-8 w-8 text-white" />
            </div>
            <p className="text-gray-700">We're thrilled to have you on board. Enjoy using SmilesFlow, and don't hesitate to reach out if you have any questions.</p>
            <button
              className="btn py-2 bg-gray-800 rounded hover:bg-gray-900 text-white text-sm mt-4"
              onClick={handleGoToDashboard}
            >
              Go to Dashboard
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const modalMaxWidth = currentStep === 'planSelection' ? 'max-w-3xl' : 'max-w-md';

  return (
    <ModalBlank
      id="subscription-issue-modal"
      modalOpen={showModal}
      setModalOpen={setShowModal}
      maxWidth={modalMaxWidth}
    >
      {renderModalContent()}
      {currentStep === 'planSelection' && !planRedirectLoading && (
        <div className="px-5 py-4 mt-5 border-t text-center">
          <button
            className="text-xs text-blue-500 hover:text-blue-600 font-medium"
            onClick={handleSignOut}
          >
            Sign in with a different account
          </button>
        </div>
      )}
    </ModalBlank>
  );
};

export default SubscriptionModal;
