import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import DashboardCard08 from '../partials/dashboard/DashboardCard03';
import BeatLoader from 'react-spinners/BeatLoader';
import { ArrowUpIcon } from '@heroicons/react/24/solid';
import { motion, AnimatePresence } from 'framer-motion';

function Tooltip({ children, text }) {
  return (
    <div className="group relative inline-block">
      {children}
      <div className="pointer-events-none absolute left-1/2 -translate-x-1/2 -top-9 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        <div className="bg-gray-900 text-white text-sm px-2 py-1 rounded whitespace-nowrap">
          {text}
          <div className="absolute left-1/2 -translate-x-1/2 top-full w-0 h-0 border-l-[6px] border-l-transparent border-r-[6px] border-r-transparent border-t-[6px] border-t-gray-900"></div>
        </div>
      </div>
    </div>
  );
}

function StatsSkeleton() {
  return (
    <>
      <div className="rounded-xl overflow-hidden">
        <div className="grid grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-2 pt-5">
          {[0, 1, 2].map((index) => (
            <div 
              className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4 sm:p-4" 
              key={index}
            >
              {/* Title skeleton */}
              <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-2/3 mb-3 animate-pulse" />
              
              {/* Value skeleton */}
              <div className="flex items-center">
                <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/2 animate-pulse" />
                <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded-full w-12 ml-2 animate-pulse" />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Chart Skeleton */}
      <div className="mt-6 bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4">
        <div className="h-[300px] relative">
          {/* Y-axis line */}
          <div className="absolute left-0 top-0 bottom-0 w-px bg-gray-200 dark:bg-gray-700" />
          
          {/* X-axis line */}
          <div className="absolute left-0 bottom-0 right-0 h-px bg-gray-200 dark:bg-gray-700" />
          
          {/* Fake data points */}
          <div className="absolute inset-0 flex items-end justify-between px-4">
            {[...Array(7)].map((_, i) => (
              <motion.div
                key={i}
                className="w-1 bg-gray-200 dark:bg-gray-700 rounded-t animate-pulse"
                initial={{ height: "20%" }}
                animate={{ height: `${20 + Math.random() * 50}%` }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut"
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function EmptyState({ onSetLive }) {
  return (
    <div className="mt-4">
      {/* Preview Grid */}
      <div className="grid grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-2">
        {[
          { title: 'Total Plays', description: 'Number of game plays' },
          { title: 'Shares', description: 'Times shared by players' },
          { title: 'Call to Actions', description: 'Call to action click count' },
        ].map((metric, index) => (
          <div 
            key={index}
            className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4 sm:p-4"
          >
            <div className="flex items-center justify-between mb-2">
              <h4 className="text-sm font-medium text-gray-900">
                {metric.title}
              </h4>
              <div className="h-7 w-7 rounded-full bg-violet-50 flex items-center justify-center">
                <svg 
                  className="w-4 h-4 text-violet-600" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                </svg>
              </div>
            </div>
            <p className="text-xs text-gray-500">
              {metric.description}
            </p>
          </div>
        ))}
      </div>

      {/* Chart Preview */}
      <div className="mt-6">
        <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4">
          <div className="h-[300px] relative">
            {/* Grid lines */}
            <div className="absolute inset-0 grid grid-cols-6 gap-x-4">
              {[...Array(7)].map((_, i) => (
                <div key={i} className="border-l border-gray-100 dark:border-gray-700/50 h-full" />
              ))}
            </div>
            <div className="absolute inset-0 grid grid-rows-4 gap-y-4">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="border-b border-gray-100 dark:border-gray-700/50 w-full" />
              ))}
            </div>

            {/* Example data points */}
            <div className="absolute inset-x-0 bottom-8 h-3/4">
              <svg className="w-full h-full" preserveAspectRatio="none">
                <path
                  d="M 0 160 C 100 120, 200 180, 300 60"
                  className="stroke-gray-200 dark:stroke-gray-700/50"
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>

            {/* Overlay with button */}
            <div className="absolute inset-0 flex items-center justify-center bg-white/60 dark:bg-gray-800/60">
              <div className="text-center space-y-3">
                <button
                  onClick={() => onSetLive()}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-white bg-violet-500 hover:bg-violet-600 transition-colors duration-200"
                >
                  <svg 
                    className="w-4 h-4 mr-2" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Set Campaign Live
                </button>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  Campaign statistics will display here when live
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ChartContent({ chartData }) {
  return (
    <div className="mt-6">
      <DashboardCard08 chartData={chartData} />
    </div>
  );
}

export { StatsSkeleton };

export default function Stats({ campaignIds, campaign, onUpdateStatus }) {
  const [stats, setStats] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isChartUpdating, setIsChartUpdating] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const chartTimeoutRef = useRef(null);
  const hasFetchedData = useRef(false);

  const normalizeDate = (date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

  useEffect(() => {
    // Reset loading states when campaign status changes
    if (!hasFetchedData.current) {
      setIsLoading(true);
      setIsChartLoading(true);
    }

    if (campaign.status === 'live' && !campaign.goLiveDate) {
      // Handle initial live state
      const emptyStats = [
        { name: 'Total Play Count', shortName: 'Plays', overall: 0, today: 0, change: 0 },
        { name: 'Total Shares', shortName: 'Shares', overall: 0, today: 0, change: 0 },
        { name: 'Call to Action Clicks', shortName: 'CTA', overall: 0, today: 0, change: 0 },
      ];
      setStats(emptyStats);

      // Create dates for initial chart data
      const today = new Date();
      const goLiveDate = new Date(today);
      const dayBeforeGoLive = new Date(today);
      dayBeforeGoLive.setDate(dayBeforeGoLive.getDate() - 1);

      setChartData({
        labels: [dayBeforeGoLive.toISOString().split('T')[0], goLiveDate.toISOString().split('T')[0]],
        datasets: [
          {
            label: 'Play Count',
            data: [0, 0],
            borderColor: '#4F46E5',
            backgroundColor: '#4F46E533',
            fill: true,
            borderWidth: 2,
            pointRadius: 0,
            tension: 0,
          },
        ],
      });

      setIsLoading(false);
      setIsChartLoading(false);
      hasFetchedData.current = true;
      return;
    }

    const CACHE_DURATION = 30000; // 30 seconds
    const statsCache = new Map();

    async function fetchData() {
      const cacheKey = `${campaignIds.join('_')}_${campaign.status}`;
      const cached = statsCache.get(cacheKey);
      
      if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
        setStats(cached.stats);
        setChartData(cached.chartData);
        setIsLoading(false);
        setIsChartLoading(false);
        return;
      }

      try {
        let totalPlayCountOverall = 0;
        let totalSharedOverall = 0;
        let totalCtaOverall = 0;
        let totalPlayCountToday = 0;
        let totalSharedToday = 0;
        let totalCtaToday = 0;

        const today = normalizeDate(new Date());
        const todayString = today.toISOString().split('T')[0];
        
        // Get earliest date we need to query
        const earliestDate = campaign.goLiveDate 
          ? normalizeDate(new Date(campaign.goLiveDate))
          : new Date(today);
        earliestDate.setDate(earliestDate.getDate() - 1); // One day before go-live
        const earliestDateString = earliestDate.toISOString().split('T')[0];

    

        // Determine the last date based on campaign status
        const lastDate = campaign.status === 'complete' && campaign.completeDate 
          ? normalizeDate(new Date(campaign.completeDate))
          : campaign.status === 'live' 
            ? today
            : campaign.completeDate 
              ? normalizeDate(new Date(campaign.completeDate))
              : today;


        const lastDateString = lastDate.toISOString().split('T')[0];
      

        const allStats = new Map();
        let goLiveDate = campaign.goLiveDate ? normalizeDate(new Date(campaign.goLiveDate)) : null;

        // Initialize today's data for all campaigns
        allStats.set(todayString, {
          date: today,
          playCount: 0,
          shared: 0,
          cta: 0,
        });

        const fetchCampaignStats = async (gameId) => {
          const dailyStatsRef = collection(db, `gameSummary/${gameId}/dailyStats`);
          const q = query(
            dailyStatsRef,
            where('__name__', '>=', earliestDateString),
            where('__name__', '<=', lastDateString)
          );
          return getDocs(q);
        };

        // Fetch all campaigns in parallel
        const statsPromises = campaignIds.map(fetchCampaignStats);
        const statsResults = await Promise.all(statsPromises);

        // Process results
        statsResults.forEach(statsDocs => {
          statsDocs.forEach((doc) => {
            const data = doc.data();
            const statDate = normalizeDate(new Date(doc.id));
            const dateString = statDate.toISOString().split('T')[0];

            if (statDate >= goLiveDate && statDate <= lastDate) {
              const existingStats = allStats.get(dateString) || { playCount: 0, shared: 0, cta: 0 };
              allStats.set(dateString, {
                date: statDate,
                playCount: (existingStats.playCount || 0) + (data.playCount || 0),
                shared: (existingStats.shared || 0) + (data.totalShared || 0),
                cta: (existingStats.cta || 0) + (data.totalCta || 0),
              });

              // Update totals
              totalPlayCountOverall += data.playCount || 0;
              totalSharedOverall += data.totalShared || 0;
              totalCtaOverall += data.totalCta || 0;

              // Only update today's totals if campaign is not complete
              if (dateString === todayString && campaign.status !== 'complete') {
                totalPlayCountToday += data.playCount || 0;
                totalSharedToday += data.totalShared || 0;
                totalCtaToday += data.totalCta || 0;
              }
            }
          });
        });

        // Update today's values only if campaign is not complete
        if (campaign.status !== 'complete' && allStats.has(todayString)) {
          allStats.set(todayString, {
            date: today,
            playCount: totalPlayCountToday,
            shared: totalSharedToday,
            cta: totalCtaToday,
          });
        }

        // Convert Map to sorted array
        let sortedStats = Array.from(allStats.values()).sort((a, b) => a.date - b.date);

        // Add day before go-live date with zero values if needed
        if (goLiveDate && sortedStats.length > 0 ) {
          const dayBeforeGoLive = new Date(goLiveDate);
          dayBeforeGoLive.setUTCDate(dayBeforeGoLive.getUTCDate() - 1);
          // add the day before go-live date with zero values
          sortedStats.unshift({
            date: dayBeforeGoLive,
            playCount: 0,
            shared: 0,
            cta: 0,
          });
        }

        // Fill in missing dates, but only up to lastDate
        let filledStats = [];
        for (let i = 0; i < sortedStats.length - 1; i++) {
          filledStats.push(sortedStats[i]);
          const currentDate = new Date(sortedStats[i].date);
          const nextDate = new Date(sortedStats[i + 1].date);
          
          while (currentDate.getTime() + 86400000 < nextDate.getTime() && currentDate < lastDate) {
            currentDate.setDate(currentDate.getDate() + 1);
            if (currentDate <= lastDate) {
              filledStats.push({
                date: new Date(currentDate),
                playCount: 0,
                shared: 0,
                cta: 0,
              });
            }
          }
        }

        // Only add the last stat if it's before or equal to lastDate
        if (sortedStats.length > 0) {
          const lastStat = sortedStats[sortedStats.length - 1];
          if (new Date(lastStat.date) <= lastDate) {
            filledStats.push(lastStat);
          }
        }

        // Filter out any dates after lastDate
        filledStats = filledStats.filter(stat => new Date(stat.date) <= lastDate);

        // Update stats comparison with appropriate today values
        const statComparison = [
          { 
            name: 'Total Play Count', 
            shortName: 'Plays', 
            overall: totalPlayCountOverall, 
            today: campaign.status !== 'complete' ? totalPlayCountToday : 0, 
            change: campaign.status !== 'complete' ? totalPlayCountToday : 0 
          },
          { 
            name: 'Total Shares', 
            shortName: 'Shares', 
            overall: totalSharedOverall, 
            today: campaign.status !== 'complete' ? totalSharedToday : 0, 
            change: campaign.status !== 'complete' ? totalSharedToday : 0 
          },
          { 
            name: 'Call to Action Clicks', 
            shortName: 'CTA', 
            overall: totalCtaOverall, 
            today: campaign.status !== 'complete' ? totalCtaToday : 0, 
            change: campaign.status !== 'complete' ? totalCtaToday : 0 
          },
        ];

        // Cache the results
        statsCache.set(cacheKey, {
          timestamp: Date.now(),
          stats: statComparison,
          chartData: {
            labels: filledStats.map(stat => stat.date.toISOString().split('T')[0]),
            datasets: [
              {
                label: 'Play Count',
                data: filledStats.map(stat => stat.playCount),
                borderColor: '#4F46E5',
                backgroundColor: '#4F46E533',
                fill: true,
                borderWidth: 2,
                pointRadius: 0,
                tension: 0.4,
              },
            ],
          }
        });

        setStats(statComparison);
        setChartData({
          labels: filledStats.map(stat => stat.date.toISOString().split('T')[0]),
          datasets: [
            {
              label: 'Play Count',
              data: filledStats.map(stat => stat.playCount),
              borderColor: '#4F46E5',
              backgroundColor: '#4F46E533',
              fill: true,
              borderWidth: 2,
              pointRadius: 0,
              tension: 0.4,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
        setIsChartLoading(false);
        hasFetchedData.current = true;
      }
    }

    if (campaignIds && campaignIds.length > 0) {
      fetchData();
    } else {
      setIsLoading(false);
      setIsChartLoading(false);
      hasFetchedData.current = true;
    }

    return () => {
      if (chartTimeoutRef.current) {
        clearTimeout(chartTimeoutRef.current);
      }
    };
  }, [campaignIds, campaign.status]);

  const renderComparisonBadge = (change, statName) => {
    if (change > 0) {
      return (
        <Tooltip text={`${change.toLocaleString()} ${statName} today`}>
          <div className="ml-2 text-xs font-medium text-green-700 bg-green-100 px-2 py-0.5 rounded-full flex items-center">
            <ArrowUpIcon className="w-3 h-3 mr-0.5 text-green-700" /> 
            {Math.abs(change).toLocaleString()}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip text={`0 ${statName} today`}>
          <div className="ml-2 text-xs font-medium text-gray-700 bg-gray-100 px-2 py-0.5 rounded-full">
            -
          </div>
        </Tooltip>
      );
    }
  };

  if ((isLoading || isChartLoading) && !hasFetchedData.current) {
    return <StatsSkeleton />;
  }

  if (campaign.status === 'draft' || campaign.status === 'ready') {
    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={campaign.status}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <EmptyState onSetLive={() => onUpdateStatus('live')} />
        </motion.div>
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={campaign.status}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <div className="rounded-xl overflow-hidden">
          <div className="grid grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-2 pt-5">
            {stats.map((stat) => (
              <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4 sm:p-4" key={stat.name}>
                <p className="text-xs sm:text-sm font-semibold text-gray-400 dark:text-gray-500 uppercase text-center sm:text-left">
                  <span className="block sm:hidden">{stat.shortName}</span>
                  <span className="hidden sm:block">{stat.name}</span>
                </p>
                <div className="flex justify-center sm:justify-start items-center mt-1 sm:mt-2">
                  <p className="text-xl sm:text-3xl font-bold text-gray-800 dark:text-gray-100 leading-none">
                    {stat.overall.toLocaleString()}
                  </p>
                  {renderComparisonBadge(stat.change, stat.shortName)}
                </div>
              </div>
            ))}
          </div>
        </div>

        <AnimatePresence mode="wait">
          {campaign.status === 'draft' || campaign.status === 'ready' ? (
            <EmptyState onSetLive={() => onUpdateStatus('live')} />
          ) : isChartUpdating || isTransitioning ? (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="bg-white w-full h-56 shadow-sm rounded-xl p-4 flex justify-center items-center mt-6"
            >
              <div className="text-center">
                <BeatLoader size={15} color={"#D1D5DB"} />
              </div>
            </motion.div>
          ) : chartData ? (
            <ChartContent chartData={chartData} />
          ) : null}
        </AnimatePresence>
      </motion.div>
    </AnimatePresence>
  );
}
