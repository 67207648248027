import { Transition } from '@headlessui/react';
import React, { useState, useEffect, useRef } from 'react';
import Play from "./Play";
import { useParams, useNavigate } from "react-router";
import { doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';
import useAuth from '../dashboard/useAuth';
import Loading from "../common/DefaultLoading";
import AlertComponent from '../dashboard/DialogConfirm';
import AlertError from '../dashboard/PermissionDenied';
import { updateCampaignStep } from '../common/Database';
import FormComponent from './Form'; // Directly import FormComponent
import { useUser } from '../userContext';
import { 
  ArrowTopRightOnSquareIcon,
  DevicePhoneMobileIcon,
  ComputerDesktopIcon,
  XMarkIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  CogIcon,
  ShareIcon,
  ChevronRightIcon
} from '@heroicons/react/24/solid';
import ModalBasic from '../components/ModalBasic'; // Add this import
import { encode } from 'js-base64'; // Add this import (you'll need to install js-base64)

export default function Editor() {
  const params = useParams();
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const [isLoading, setIsLoading] = useState(true); 
  const [gameData, setGameData] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(null); 
  const [errors, setErrors] = useState({}); 
  const formRef = useRef(null); 
  const { businessProfile } = useUser();
  const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);
  const desktopIframeRef = useRef(null);
  const mobileIframeRef = useRef(null);
  const [isQuickPreviewOpen, setIsQuickPreviewOpen] = useState(false);

  useEffect(() => {
    if (!user || !params.id) return; // Ensure that user and params.id are available
  
    fetchCampaign();
  }, [params.id, user, navigate]);


  useEffect(() => {
    
    if (!businessProfile?.id) {
      return;
    }

    const companyId = businessProfile.id;

    try {
      // First verify the document exists
      const docRef = doc(db, "company", companyId);
      getDoc(docRef).then(doc => {
      });

      // Set up the listener
      const unsubscribe = onSnapshot(
        docRef,
        {
          includeMetadataChanges: true // This might help catch all changes
        },
        (docSnapshot) => {
          
          if (docSnapshot.exists()) {
            if (!docSnapshot.metadata.hasPendingWrites) {
              setIframeKey(prev => prev + 1);
              
              desktopIframeRef.current?.contentWindow?.postMessage({
                type: 'COMPANY_UPDATE',
                companyData: docSnapshot.data()
              }, '*');
              
              mobileIframeRef.current?.contentWindow?.postMessage({
                type: 'COMPANY_UPDATE',
                companyData: docSnapshot.data()
              }, '*');
            } else {
            }
          }
        },
        (error) => {
          console.error("Error listening to company changes:", error);
        }
      );

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error('Error setting up listener:', error);
    }
  }, [businessProfile?.id, db]);

  const fetchCampaign = async () => {
    setIsLoading(true);
    try {

      if (params.id) {
        const docRef = doc(db, "campaign", params.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const campaignData = docSnap.data();
      

          if (campaignData.companyId !== businessProfile.id) {
            console.error('User does not have permission to edit this campaign');
            setError("You do not have permission to edit this campaign.");
            setIsLoading(false);
            return;
          }
          setGameData(campaignData);
        } else {
          console.error('Campaign not found');
          setError("Campaign not found.");
        }
      } else {
        console.error("params.id is missing");
        setError("Campaign not found.");
      }
    } catch (error) {
      console.error('Error fetching campaign:', error);
      setError("An error occurred while fetching the campaign.");
    } finally {
      setIsLoading(false); // Always stop loading, even in case of error
    }
  };

  const handleOptionsUpdate = (updatedOptions) => {
    setUnsavedChanges(true);
    const newGameData = {
      ...gameData,
      options: { ...gameData.options, ...updatedOptions }
    };
    setGameData(newGameData);
    
    // Send the complete game data to both iframes
    const previewData = {
      ...newGameData,
      id: params.id || 'preview'
    };
    
    desktopIframeRef.current?.contentWindow?.postMessage({
      type: 'PREVIEW_UPDATE',
      gameData: previewData
    }, '*');
    
    mobileIframeRef.current?.contentWindow?.postMessage({
      type: 'PREVIEW_UPDATE',
      gameData: previewData
    }, '*');
  };

  const validateForm = () => {
    const form = formRef.current;
    if (!form.reportValidity()) {
      console.error('Validation failed, not saving');
      return false;
    }
    return true;
  };

  const saveOptionsToDB = async () => {
    if (!validateForm()) return;
    setUnsavedChanges(false);
    try {
      await updateCampaignStep(params.id, 3);
      const docRef = doc(db, "campaign", params.id);
      await setDoc(docRef, gameData, { merge: true });
      setIframeKey(prev => prev + 1);
    } catch (error) {
      console.error('Error saving options to database:', error);
    }
  };

  const closeEditor = () => {
    if (unsavedChanges) {
      setTimeout(() => {
        setShowAlert(true);
      }, 300);
    } else {
      navigate(`/campaign/${params.id}`, { state: { from: '/campaigns' } });
    }
  };

  const handleModalConfirm = (isOpen) => {
    if (!isOpen) {
      if (unsavedChanges) {
        setTimeout(() => {
          navigate(`/campaign/${params.id}`, { state: { from: '/campaigns' } });
        }, 300);
      }
    }
    setShowAlert(isOpen);
  };

  const getPreviewUrl = () => {
    const previewData = encode(JSON.stringify(gameData));
    return `/play/${params.id}?preview=${previewData}`;
  };

  const handleIframeLoad = (iframeRef) => {
    // Send the current game data to the iframe after it loads
    const currentGameData = {
      ...gameData,
      id: params.id || 'preview'
    };
    
    iframeRef.current?.contentWindow?.postMessage({
      type: 'PREVIEW_UPDATE',
      gameData: currentGameData
    }, '*');
  };

  const refreshPreview = () => {
    
    // Increment the iframe key to force refresh
    setIframeKey(prev => {
      return prev + 1;
    });

    // Send the current game data to both iframes after a delay
    setTimeout(() => {
      handleOptionsUpdate(gameData.options);
    }, 1500);
  };

  // Get the current preview URL
  const previewUrl = getPreviewUrl();

  if (loading || isLoading) return <Loading />;

  if (error) {
    return <AlertError text={error} />;
  }

  return (
    <div className="h-[100dvh] flex flex-col overflow-hidden">
      {/* Main Content */}
      <div className="flex-1 bg-white dark:bg-gray-800 rounded-lg shadow-sm flex flex-col overflow-hidden">
        {/* Header - Make it sticky on mobile */}
        <div className="sticky top-0 z-10 border-b border-gray-200 dark:border-gray-700 flex-shrink-0 bg-white dark:bg-gray-800">
          <div className="p-3 sm:p-3">
            {/* Mobile Layout */}
            <div className="flex sm:hidden items-center justify-between">
              <div className="flex items-center gap-3 min-w-0 flex-1">
                <button
                  onClick={closeEditor}
                  className="flex-shrink-0 p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
                <h1 className="text-lg font-medium text-gray-900 dark:text-gray-100 truncate">
                  {gameData.name || 'New Game'}
                </h1>
              </div>
              <Transition
                show={unsavedChanges}
                enter="transition-opacity duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <button
                  onClick={saveOptionsToDB}
                  className="ml-3 px-4 py-1.5 rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
                >
                  Save Changes
                </button>
              </Transition>
            </div>

            {/* Desktop Layout */}
            <div className="hidden sm:flex items-center gap-4">
              <div className="flex items-center gap-3 min-w-0 flex-1">
                <button
                  onClick={closeEditor}
                  className="flex-shrink-0 p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
                <h1 className="text-lg font-medium text-gray-900 dark:text-gray-100 truncate">
                  {gameData.name || 'New Game'}
                </h1>
              </div>

              <div className="flex items-center gap-2">
                {/* Save button */}
                <Transition
                  show={unsavedChanges}
                  enter="transition-opacity duration-150"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <button
                    onClick={saveOptionsToDB}
                    className="px-4 py-1.5 rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
                  >
                    Save Changes
                  </button>
                </Transition>

                <div className="flex bg-gray-100 dark:bg-gray-700 rounded-md p-1">
                  <button
                    onClick={() => setIsPreviewExpanded(true)}
                    className={`p-1.5 rounded-md transition-colors ${
                      isPreviewExpanded
                        ? 'bg-white dark:bg-gray-600 text-gray-700 dark:text-gray-200 shadow-sm'
                        : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
                    }`}
                    title="Desktop view"
                  >
                    <ComputerDesktopIcon className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => setIsPreviewExpanded(false)}
                    className={`p-1.5 rounded-md transition-colors ${
                      !isPreviewExpanded
                        ? 'bg-white dark:bg-gray-600 text-gray-700 dark:text-gray-200 shadow-sm'
                        : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
                    }`}
                    title="Mobile view"
                  >
                    <DevicePhoneMobileIcon className="w-5 h-5" />
                  </button>
                </div>
                <button
                  onClick={refreshPreview}
                  className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  title="Refresh preview"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                </button>
                <a
                  href={`/play/${params.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  title="Open in new tab"
                >
                  <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Content Area */}
        <div className="flex-1 flex flex-col sm:flex-row overflow-hidden">
          {/* Left Panel - Form */}
          <div className={`${isPreviewExpanded ? 'w-full sm:w-2/5' : 'w-full sm:w-3/5'} border-r border-gray-200 dark:border-gray-700 flex flex-col transition-all duration-300 overflow-y-auto`}>
            {/* Preview Button - Mobile Only */}
            <div className="sticky top-0 z-10 block sm:hidden p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
              <button
                onClick={() => setIsQuickPreviewOpen(true)}
                className="flex items-center gap-2 py-2 px-3 
                  bg-indigo-600 dark:bg-indigo-500
                  text-white hover:bg-indigo-700 dark:hover:bg-indigo-600 
                  transition-colors shadow-lg rounded-lg w-full justify-center"
                title="Live Preview"
              >
                <span className="text-sm font-medium">Live Preview</span>
              </button>
            </div>

            {Object.entries(errors).map(([field, errorMessage]) => (
              <div key={field} className="px-4 py-2 flex-shrink-0">
                <AlertError text={errorMessage} dashboardButton={false} />
              </div>
            ))}
            
            <div className="flex-1 overflow-y-auto"> 
              <form ref={formRef} className="h-full">
                <FormComponent 
                  options={gameData.options} 
                  game={gameData} 
                  onOptionsUpdate={handleOptionsUpdate} 
                  params={params} 
                  validateForm={validateForm} 
                  errors={errors} 
                />
              </form>
            </div>
          </div>

          {/* Desktop Preview Panel */}
          <div className={`
            ${isPreviewExpanded ? 'sm:w-3/5' : 'sm:w-2/5'}
            hidden sm:flex flex-shrink-0 bg-gray-50 dark:bg-gray-900 flex-col transition-all duration-300
          `}>
            {/* Preview container */}
            <div className="h-full overflow-hidden relative">
              <div className="h-full flex items-center justify-center w-full">
                <div className={`${
                  !isPreviewExpanded 
                    ? 'w-[375px] h-[667px]' // Mobile view
                    : 'w-full h-full'   // Desktop view
                } transition-all duration-300 flex-shrink-0 relative`}>
                  <iframe
                    key={iframeKey}
                    ref={desktopIframeRef}
                    src={`/play/${params.id}`}
                    className={`w-full h-full bg-white ${!isPreviewExpanded ? 'rounded-2xl shadow-lg' : ''}`}
                    title="Game Preview"
                    allowFullScreen
                    onLoad={() => handleIframeLoad(desktopIframeRef)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Mobile Quick Preview Tab & Panel */}
          <div className="block sm:hidden">
            {/* Backdrop */}
            <div 
              className={`fixed inset-0 bg-gray-900/50 transition-opacity duration-300 z-50 ${
                isQuickPreviewOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
              }`} 
              onClick={() => setIsQuickPreviewOpen(false)} 
            />
            
            {/* Preview Panel - Changed transform to slide from top */}
            <div className={`fixed inset-0 bg-white dark:bg-gray-900 transform transition-transform duration-300 z-50 ${
              isQuickPreviewOpen ? 'translate-y-0' : '-translate-y-full'
            }`}>
              {/* Full-height iframe */}
              <div className="relative w-full h-full">
                <iframe
                  key={iframeKey}
                  ref={mobileIframeRef}
                  src={`/play/${params.id}`}
                  className="w-full h-full bg-white dark:bg-gray-900"
                  title="Game Preview"
                  allowFullScreen
                  onLoad={() => handleIframeLoad(mobileIframeRef)}
                />
              </div>
              
              {/* Control buttons */}
              <div className="absolute top-4 right-4 z-50">
                <button
                  onClick={refreshPreview}
                  className="p-2 rounded-full bg-indigo-500/60 dark:bg-indigo-400/60 backdrop-blur-md text-white hover:bg-indigo-600/60 dark:hover:bg-indigo-500/60 transition-colors shadow-lg"
                  title="Refresh preview"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                </button>
              </div>

              {/* Back button */}
              <div className="absolute top-4 left-4 z-50">
                <button
                  onClick={() => setIsQuickPreviewOpen(false)}
                  className="p-2 rounded-full bg-indigo-500/60 dark:bg-indigo-400/60 backdrop-blur-md text-white hover:bg-indigo-600/60 dark:hover:bg-indigo-500/60 transition-colors shadow-lg"
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalBasic
        id="unsaved-changes-modal"
        title="Unsaved Changes"
        modalOpen={showAlert}
        setModalOpen={handleModalConfirm}
      >
        <div className="p-6">
          <div className="space-y-6">
            <p className="text-sm text-gray-600">
              You have unsaved changes. Are you sure you want to leave?
            </p>
            <div className="flex justify-end gap-3">
              <button
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
                onClick={() => setShowAlert(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-sm font-medium text-white bg-violet-600 rounded-md hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
                onClick={() => handleModalConfirm(false)}
              >
                Leave Page
              </button>
            </div>
          </div>
        </div>
      </ModalBasic>
    </div>
  );
}