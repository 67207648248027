import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { statuses } from '../../data/StatusColours'; // Adjust the import path
import { gameTypes } from '../../data/GameTypes'; // Adjust the import path
import { ArrowRightIcon, ArrowUpIcon } from '@heroicons/react/24/solid'; // Import new icons


// Helper function to render the comparison badge
function renderComparisonBadge(change) {
  if (change > 0) {
    return (
      <div className="ml-2 text-xs font-medium text-green-700 bg-green-100 px-2 py-0.5 rounded-full flex items-center">
        <ArrowUpIcon className="w-3 h-3 mr-0.5 text-green-700" /> 
        {Math.abs(change).toLocaleString()}
      </div>
    );
  } else {
    return (
      <div className="ml-2 text-xs font-medium text-gray-700 bg-gray-100 px-2 py-0.5 rounded-full">
        -
      </div>
    );
  }
}

// Helper function to format the created date
function formatDate(date) {
  const options = { month: 'short', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

function CampaignsCard({ campaign }) {
  const [playCount, setPlayCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  
  // Normalize date function from Stats.jsx
  const normalizeDate = (date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

  useEffect(() => {
    // Only fetch stats if campaign is live or complete
    if (campaign.status !== 'live' && campaign.status !== 'complete') {
      setIsLoading(false);
      return;
    }

    async function fetchStats() {
      try {
        let totalPlayCountOverall = 0;
        let totalPlayCountToday = 0;
        
        const today = normalizeDate(new Date());
        const todayString = today.toISOString().split('T')[0];
        
        // Get earliest date we need to query
        const earliestDate = campaign.goLiveDate 
          ? normalizeDate(new Date(campaign.goLiveDate))
          : new Date(today);
        earliestDate.setDate(earliestDate.getDate() - 1); // One day before go-live
        const earliestDateString = earliestDate.toISOString().split('T')[0];

        // Determine the last date based on campaign status
        const lastDate = campaign.status === 'complete' && campaign.completeDate 
          ? normalizeDate(new Date(campaign.completeDate))
          : today;
        const lastDateString = lastDate.toISOString().split('T')[0];
        
        // Get campaign IDs (same logic as in Stats.jsx)
        const campaignIds = campaign.gameIds || [campaign.id];
        
        // Fetch stats for each game ID
        for (const gameId of campaignIds) {
          const dailyStatsRef = collection(db, `gameSummary/${gameId}/dailyStats`);
          const q = query(
            dailyStatsRef,
            where('__name__', '>=', earliestDateString),
            where('__name__', '<=', lastDateString)
          );
          
          const statsDocs = await getDocs(q);
          
          statsDocs.forEach((doc) => {
            const data = doc.data();
            const statDate = normalizeDate(new Date(doc.id));
            const dateString = statDate.toISOString().split('T')[0];
            
            // Update totals
            totalPlayCountOverall += data.playCount || 0;
            
            // Only update today's totals if the date matches today
            if (dateString === todayString && campaign.status !== 'complete') {
              totalPlayCountToday += data.playCount || 0;
            }
          });
        }
        
        setPlayCount(totalPlayCountOverall);
        setTodayCount(totalPlayCountToday);
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setIsLoading(false);
      }
    }
    
    fetchStats();
  }, [campaign]);

  // Assign status class from statuses based on the state of the campaign
  const getStatusClass = (state) => {
    return statuses[state] || 'text-gray-500 bg-gray-200'; // Default to draft-like style if state is unknown
  };

  const getGameTypeColors = (gameTypeId) => {
    const colorMap = {
      "pindrop": {
        bg: "bg-red-100 dark:bg-red-900/60",
        icon: "text-red-500 dark:text-red-400",
      },
      "hint": {
        bg: "bg-amber-100 dark:bg-amber-900/60",
        icon: "text-amber-500 dark:text-amber-400",
      },
      "quiz": {
        bg: "bg-blue-100 dark:bg-blue-900/60",
        icon: "text-blue-500 dark:text-blue-400",
      },
      "mapselect": {
        bg: "bg-emerald-100 dark:bg-emerald-900/60",
        icon: "text-emerald-500 dark:text-emerald-400",
      },
      "snake": {
        bg: "bg-purple-100 dark:bg-purple-900/60",
        icon: "text-purple-500 dark:text-purple-400",
      }
    };
    return colorMap[gameTypeId] || colorMap["quiz"]; // Default to quiz colors
  };

  // Get the gameType data and colors
  const gameTypeData = gameTypes.find((type) => type.id === campaign.gameType);
  const colors = getGameTypeColors(campaign.gameType);

  // Determine if we should show stats
  const showStats = (campaign.status === 'live' || campaign.status === 'complete') && !isLoading;

  return (
    <Link
      to={`/campaign/${campaign.id}`}
      className="block col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-sm rounded-xl hover:shadow-md transition-shadow duration-200"
    >
      <div className="flex flex-col h-full p-5">
        {/* Header */}
        <header className="flex items-center justify-between mb-3">
          {/* Left: Game Type Icon */}
          <div className={`p-2.5 rounded-lg ${colors.bg}`}>
            {gameTypeData?.icon && (
              <gameTypeData.icon className={`w-5 h-5 ${colors.icon}`} />
            )}
          </div>

          {/* Right: Formatted Created Date */}
          <div className="text-xs text-gray-400">
            {formatDate(campaign.createdAt.toDate())}
          </div>
        </header>

        {/* Campaign Title and Description */}
        <div className="grow mt-2">
          <h2 className="text-xl leading-snug font-semibold text-gray-800 mb-1">
            {campaign.name}
          </h2>
          <div className="text-sm text-gray-500">{campaign.options.title}</div>
        </div>

        {/* Footer: Show Play Count with comparison badge if stats are available, otherwise show status */}
        <footer className="mt-5">
          <div className="flex justify-between items-center">
            {showStats ? (
              <div className="flex items-center">
                <p className="ml-1 text-xs text-gray-500 uppercase font-semibold">
                  Plays: {playCount.toLocaleString()}
                </p>
                {renderComparisonBadge(todayCount)}
              </div>
            ) : (
              <span
                className={`text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 ${getStatusClass(
                  campaign.status
                )}`}
              >
                {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
              </span>
            )}

            <div className="flex items-center">
              <span className="text-sm font-medium text-violet-500 hover:text-violet-600">View</span>
              <ArrowRightIcon className="w-4 h-4 text-violet-500 ml-1" />
            </div>
          </div>
        </footer>
      </div>
    </Link>
  );
}

export default CampaignsCard;