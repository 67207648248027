import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useUser } from '../../userContext';

function SettingsSidebar() {

  const location = useLocation();
  const { pathname } = location;
  const { subscriptionType } = useUser();

  const isAgencyPlan = subscriptionType === 'agency';

  return (
    <div className="flex flex-col md:block px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 dark:border-gray-700/60 min-w-[15rem] md:space-y-3 overflow-visible">
      {/* Fixed Settings text */}
      <div className="px-2.5 mb-3">
        <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase">Settings</div>
      </div>
      
      {/* Scrollable navigation */}
      <div className="overflow-x-auto overflow-visible no-scrollbar">
        <ul className="flex md:block mr-3 md:mr-0 min-w-max">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/settings/account" className={`flex items-center px-2.5 py-2 rounded-lg whitespace-nowrap ${pathname.includes('/settings/account') && 'bg-[linear-gradient(135deg,var(--tw-gradient-stops))] from-violet-500/[0.12] dark:from-violet-500/[0.24] to-violet-500/[0.04]'}`}>
              <svg className={`shrink-0 fill-current mr-2 ${pathname.includes('/settings/account') ? 'text-violet-500 dark:text-violet-400' : 'text-gray-400 dark:text-gray-500'}`} width="16" height="16" viewBox="0 0 16 16">
                <path d="M8 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm-5.143 7.91a1 1 0 1 1-1.714-1.033A7.996 7.996 0 0 1 8 10a7.996 7.996 0 0 1 6.857 3.877 1 1 0 1 1-1.714 1.032A5.996 5.996 0 0 0 8 12a5.996 5.996 0 0 0-5.143 2.91Z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/account') ? 'text-violet-500 dark:text-violet-400' : 'text-gray-600 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-200'}`}>Account</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/settings/company" className={`flex items-center px-2.5 py-2 rounded-lg whitespace-nowrap ${pathname.includes('/settings/company') && 'bg-[linear-gradient(135deg,var(--tw-gradient-stops))] from-violet-500/[0.12] dark:from-violet-500/[0.24] to-violet-500/[0.04]'}`}>
              <svg 
                className={`shrink-0 fill-current mr-2 ${pathname.includes('/settings/company') ? 'text-violet-500 dark:text-violet-400' : 'text-gray-400 dark:text-gray-500'}`} 
                width="16" 
                height="16" 
                viewBox="0 0 24 24"
              >
                <path d="M3 21h18v-2H3v2zm4-4h10v-2H7v2zm0-4h10v-2H7v2zm0-4h10V7H7v2zm-4 8h18v-2H3v2zm0-16v2h18V3H3z"/>
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/company') ? 'text-violet-500 dark:text-violet-400' : 'text-gray-600 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-200'}`}>Company</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/settings/plans" className={`flex items-center px-2.5 py-2 rounded-lg whitespace-nowrap ${pathname.includes('/settings/plans') && 'bg-[linear-gradient(135deg,var(--tw-gradient-stops))] from-violet-500/[0.12] dark:from-violet-500/[0.24] to-violet-500/[0.04]'}`}>
              <svg className={`shrink-0 fill-current mr-2 ${pathname.includes('/settings/plans') ? 'text-violet-500 dark:text-violet-400' : 'text-gray-400 dark:text-gray-500'}`} width="16" height="16" viewBox="0 0 16 16">
                <path d="M5 9a1 1 0 1 1 0-2h6a1 1 0 0 1 0 2H5ZM1 4a1 1 0 1 1 0-2h14a1 1 0 0 1 0 2H1Zm0 10a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2H1Z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/plans') ? 'text-violet-500 dark:text-violet-400' : 'text-gray-600 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-200'}`}>Plans</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            {isAgencyPlan && (
              <NavLink 
                end 
                to="/settings/users" 
                className={`flex items-center px-2.5 py-2 rounded-lg whitespace-nowrap ${pathname.includes('/settings/users') && 'bg-[linear-gradient(135deg,var(--tw-gradient-stops))] from-violet-500/[0.12] dark:from-violet-500/[0.24] to-violet-500/[0.04]'}`}
              >
                <svg 
                  className={`shrink-0 fill-current mr-2 ${pathname.includes('/settings/users') ? 'text-violet-500 dark:text-violet-400' : 'text-gray-400 dark:text-gray-500'}`} 
                  width="16" 
                  height="16" 
                  viewBox="0 0 24 24"
                >
                  <path d="M12 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm6 10a1 1 0 0 0 1-1 7 7 0 0 0-14 0 1 1 0 0 0 1 1zm-6-3a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm8-4a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm4.5 6a.5.5 0 0 0 .5-.5 5.5 5.5 0 0 0-11 0 .5.5 0 0 0 .5.5z"/>
                </svg>
                <span className={`text-sm font-medium ${pathname.includes('/settings/users') ? 'text-violet-500 dark:text-violet-400' : 'text-gray-600 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-200'}`}>Users</span>
              </NavLink>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SettingsSidebar;
