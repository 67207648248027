import React, { useState, useEffect, Suspense, useRef, lazy } from 'react';
import { PlayIcon, CogIcon, AdjustmentsVerticalIcon, ShareIcon, ArrowLeftIcon, ChevronRightIcon, NewspaperIcon } from '@heroicons/react/20/solid'; // Import meaningful icons
import { InformationCircleIcon, TableCellsIcon, MegaphoneIcon, PaintBrushIcon, SwatchIcon } from '@heroicons/react/24/outline';  // Add this import
import Help from '../components/DropdownHelp';  // Add this import
import DefaultLoading from '../common/DefaultLoading';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';

// Lazy load all section components
const StartScreenSection = lazy(() => import('./StartScreenSection'));
const LeaderboardSection = lazy(() => import('./LeaderboardSection'));
const CTASection = lazy(() => import('./CTASection'));
const AppearanceSection = lazy(() => import('./AppearanceSection'));
const HeadlineSetup = lazy(() => import('./HeadlineSetup'));
const ShareSection = lazy(() => import('./ShareSection'));

const Form = ({ options, onOptionsUpdate, params, game, businessProfile }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [showText, setShowText] = useState(true); // To control text visibility during transition
  const [showContent, setShowContent] = useState(false); // To control content visibility after delay
  const [selectedFont, setSelectedFont] = useState(options.font || 'Default');
  const [GameSetupComponent, setGameSetupComponent] = useState(null);
  const contentRefs = useRef({}); // Store refs to each section's content
  const [contentHeights, setContentHeights] = useState({}); // Store heights of each section

  // Load GameSetup component only once when game type changes
  useEffect(() => {
    if (game?.gameType) {
      import(`../games/${game.gameType}/GameSetup`)
        .then(module => setGameSetupComponent(() => module.default))
        .catch(error => console.error('Error loading GameSetup component', error));
    }
  }, [game?.gameType]);

  // Handle font change
  const handleFontChange = (nextFont) => {
    if (nextFont === null) {
      setSelectedFont('Default');
      onOptionsUpdate({ ...options, font: '' });
    } else {
      setSelectedFont(nextFont.family);
      onOptionsUpdate({ ...options, font: nextFont.family });
    }
  };

  // Handle color change complete (for color picker)
  const handleColorChangeComplete = (color) => {
    onOptionsUpdate({ ...options, colorButton: color.hex });
  };

  const handlePreview = () => {
    window.open(`/play/${params.id}`, '_blank');
  };

  // Toggle the active section
  const toggleSection = (id) => {
    if (id !== activeSection) {
      setActiveSection(id);
    }
  };

  // Function to update heights of content when activeSection changes or content changes dynamically
  const updateHeights = () => {
    const newHeights = {};
    Object.keys(contentRefs.current).forEach((key) => {
      const element = contentRefs.current[key];
      if (element) {
        newHeights[key] = element.scrollHeight + 'px';
      }
    });
    setContentHeights(newHeights);
  };

  // Recalculate heights when activeSection changes
  useEffect(() => {
    updateHeights();
  }, [activeSection]);

  // Form sections definition - Memoize to prevent recreation on every render
  const formSections = React.useMemo(() => [
    {
      id: 'content',
      title: 'Content',
      description: 'Configure your game content and media',
      icon: <PaintBrushIcon className="w-5 h-5" />,
      component: StartScreenSection,
      props: { options, onOptionsUpdate, params }
    },
    {
      id: 'gameSetup',  
      title: 'Game Setup',
      description: 'Configure game mechanics, difficulty settings, and gameplay options',
      icon: <CogIcon className="w-5 h-5" />,
      component: GameSetupComponent,
      props: { options, onOptionsUpdate }
    },
    {
      id: 'appearance',
      title: 'Appearance',
      description: 'Customize colors, fonts, and visual styles',
      icon: <SwatchIcon className="w-5 h-5" />,
      component: AppearanceSection,
      props: { options, onOptionsUpdate, game }
    },
    {
      id: 'header',
      title: 'Header',
      description: 'Configure your header visibility and appearance',
      icon: <NewspaperIcon className="w-5 h-5" />,
      component: HeadlineSetup,
      props: { options, onOptionsUpdate },
      badge: !options?.hideHeadline ? (
        <span className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium rounded-full bg-green-50 text-green-700 dark:bg-green-900/30 dark:text-green-400">
          <CheckCircleIcon className="w-3.5 h-3.5" />
          Enabled
        </span>
      ) : (
        <span className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium rounded-full bg-gray-50 text-gray-600 dark:bg-gray-700 dark:text-gray-400">
          <XCircleIcon className="w-3.5 h-3.5" />
          Disabled
        </span>
      )
    },
    {
      id: 'leaderboard',
      title: 'Leaderboard',
      description: 'Configure leaderboard settings and appearance',
      icon: <TableCellsIcon className="w-5 h-5" />,
      component: LeaderboardSection,
      props: { options, onOptionsUpdate },
      badge: options?.leaderboard?.enabled ? (
        <span className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium rounded-full bg-green-50 text-green-700 dark:bg-green-900/30 dark:text-green-400">
          <CheckCircleIcon className="w-3.5 h-3.5" />
          Enabled
        </span>
      ) : (
        <span className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium rounded-full bg-gray-50 text-gray-600 dark:bg-gray-700 dark:text-gray-400">
          <XCircleIcon className="w-3.5 h-3.5" />
          Disabled
        </span>
      )
    },
    {
      id: 'callToAction',
      title: 'Call to Action',
      description: 'Configure primary action button and settings',
      icon: <MegaphoneIcon className="w-5 h-5" />,
      component: CTASection,
      props: { options, onOptionsUpdate },
      badge: options?.ctaEnabled ? (
        <span className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium rounded-full bg-green-50 text-green-700 dark:bg-green-900/30 dark:text-green-400">
          <CheckCircleIcon className="w-3.5 h-3.5" />
          Enabled
        </span>
      ) : (
        <span className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium rounded-full bg-gray-50 text-gray-600 dark:bg-gray-700 dark:text-gray-400">
          <XCircleIcon className="w-3.5 h-3.5" />
          Disabled
        </span>
      )
    },
    {
      id: 'sharing',
      title: 'Sharing',
      description: 'Configure social sharing options and appearance',
      icon: <ShareIcon className="w-5 h-5" />,
      component: ShareSection,
      props: { options, onOptionsUpdate, params }
    }
  ], [options, onOptionsUpdate, params, GameSetupComponent, game]);

  // Render the active section's content
  const renderSectionContent = (section) => {
    if (!section.component) return null;
    
    const Component = section.component;
    return (
      <Suspense fallback={<DefaultLoading />}>
        <Component {...section.props} />
      </Suspense>
    );
  };

  return (
    <div className="h-full relative flex flex-col">
      {!activeSection ? (
        // Full width list of options when no section is selected
        <div className="w-full h-full flex flex-col overflow-y-auto">
          {/* Subtle intro message */}
          <div className="p-6 pb-4 text-sm text-gray-600 dark:text-gray-400 border-l-4 border-indigo-200 dark:border-indigo-800 pl-4 py-2">
            Configure your game by selecting one of the sections below. Each section allows you to customize different aspects of the player experience.
          </div>

          {/* Section Grid - Full width, with flex-1 to take available space */}
          <div className="px-6 space-y-4 flex-1 mb-10">
            {formSections.map((section) => (
              <button
                key={section.id}
                onClick={() => toggleSection(section.id)}
                className="w-full group bg-white dark:bg-gray-800 p-5 rounded-xl transition-all duration-300
                  hover:shadow-lg hover:-translate-x-1
                  border border-gray-100 dark:border-gray-700
                  hover:border-indigo-200 dark:hover:border-indigo-800
                  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2
                  flex items-center gap-4"
              >
                {/* Icon */}
                <div className="flex-shrink-0">
                  <div className="w-12 h-12 rounded-xl bg-indigo-50 dark:bg-indigo-900/30 flex items-center justify-center
                    group-hover:bg-indigo-100 dark:group-hover:bg-indigo-900/50 transition-colors">
                    <div className="text-indigo-600 dark:text-indigo-400">
                      {section.icon}
                    </div>
                  </div>
                </div>

                {/* Content */}
                <div className="flex-1 text-left min-w-0">
                  <div className="flex items-center gap-3">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white group-hover:text-indigo-600 dark:group-hover:text-indigo-400">
                      {section.title}
                    </h3>
                    {section.badge && (
                      <div className="flex-shrink-0">
                        {section.badge}
                      </div>
                    )}
                  </div>
                  <p className="text-sm text-gray-600 dark:text-gray-400 leading-relaxed">
                    {section.description}
                  </p>
                </div>

                {/* Arrow */}
                <div className="flex-shrink-0 text-gray-400 dark:text-gray-500 group-hover:text-indigo-600 dark:group-hover:text-indigo-400">
                  <ChevronRightIcon className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </div>
              </button>
            ))}
          </div>

          {/* Footer with help text and icon - Make it sticky on mobile */}
          <div className="sticky bottom-0 left-0 right-0 border-t border-gray-100 dark:border-gray-700 flex items-center justify-end gap-2 bg-white dark:bg-gray-800 z-[1] shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] dark:shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.3)] px-6 py-4">
            <span className="text-sm text-gray-500 dark:text-gray-400">
              Help us improve, provide feedback or submit your own ideas
            </span>
            <div className="relative">
              <button 
                type="button" 
                className="flex-shrink-0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Help align="right" position="above" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        // Content Area when section is selected
        <div className="h-full flex flex-col overflow-y-auto">
          {/* Title and Back Button - Make it sticky */}
          <div className="sticky top-0 z-20 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
            <div className="p-4 sm:p-6">
              <div className="flex items-center">
                <button
                  onClick={() => setActiveSection(null)}
                  className="flex items-center gap-1.5 px-2.5 py-1.5 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors"
                >
                  <ArrowLeftIcon className="w-5 h-5" />
                  <span className="text-sm font-medium">Back</span>
                </button>
                <div className="ml-4 pl-4 border-l border-gray-200 dark:border-gray-700">
                  <h2 className="font-semibold text-xl text-gray-900 dark:text-white">
                    {formSections.find(s => s.id === activeSection)?.title}
                  </h2>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {formSections.find(s => s.id === activeSection)?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Content of the selected section */}
          <div className="flex-1">
            <div className="p-4 sm:p-6">
              {renderSectionContent(formSections.find(s => s.id === activeSection))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;