// NewCampaign.js
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { useUser } from '../userContext';
import SelectGameType from './SelectType'; 
import InitialSetup from './InitialSetup';
import RegenerateButton from './RegenerateButton';
import { PulseLoader } from 'react-spinners';
import { gameTypes } from '../data/GameTypes';  // Import the game types
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SparklesIcon, RocketLaunchIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import QuickStyleSelector from './QuickStyleSelector';
import { getStylePresets } from './stylePresets';

export const getGameTypeColor = (gameType) => {
  const colorMap = {
    "pindrop": "#EF4444", // red-500
    "hint": "#F59E0B",    // amber-500
    "quiz": "#3B82F6",    // blue-500
    "mapselect": "#10B981", // emerald-500
    "snake": "#8B5CF6"    // purple-500
  };
  return colorMap[gameType] || "#4F46E5"; // default to indigo-600
};

const safeStringify = (obj) => {
  const seen = new WeakSet();
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return '[Circular]';
      }
      seen.add(value);
    }
    return value;
  }, 2);
};

function NewCampaign() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, businessProfile } = useUser();
  const stepStackRef = useRef([1]);
  const [isBackward, setIsBackward] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isGenerating, setIsGenerating] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isRegenerating, setIsRegenerating] = useState({});
  const [selectedStyle, setSelectedStyle] = useState('default');
  
  // Move formData state after hooks
  const [formData, setFormData] = useState(() => {
    // Get URL parameters
    const params = new URLSearchParams(location.search);
    const gameType = params.get('gameType');
    const title = params.get('title');

    // If we have URL parameters, start at step 2
    if (gameType) {
      const selectedGameType = gameTypes.find(type => type.id === gameType);
      if (selectedGameType) {
        // Set initial step to 2
        setTimeout(() => {
          setCurrentStep(2);
          stepStackRef.current = [1, 2];
        }, 0);

        return {
          gameType: selectedGameType,
          shortDescription: title || '',
          additionalSettings: {}
        };
      }
    }

    // Default initial state
    return {
      shortDescription: '',
      gameType: null,
      additionalSettings: {}
    };
  });

  const loadingMessages = [
    "Analyzing your requirements...",
    "Crafting engaging content...",
    "Generating creative elements...",
    "Fine-tuning the experience...",
    "Almost there...",
    "Adding finishing touches..."
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const title = params.get('title');
    const description = params.get('description');
    const gameType = params.get('gameType');
    const exampleRounds = params.get('exampleRounds')?.split(',');

    if (title && description && gameType) {
      const selectedGameType = gameTypes.find(type => type.id === gameType);

      if (selectedGameType) {
        setFormData({
          gameType: selectedGameType,
          description,
          shortDescription: title
        });
        setCurrentStep(2);
        stepStackRef.current = [1, 2];
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (isGenerating) {
      let currentIndex = 0;
      const interval = setInterval(() => {
        currentIndex = (currentIndex + 1) % loadingMessages.length;
        setLoadingMessage(loadingMessages[currentIndex]);
      }, 4000);

      return () => clearInterval(interval);
    }
  }, [isGenerating]);

  // Check for pre-selected game type from location state
  useEffect(() => {
    const locationState = location.state;
    if (locationState?.gameType) {
      // If we have a pre-selected game type, set it and skip to step 2
      setFormData(prevData => ({
        ...prevData,
        gameType: locationState.gameType
      }));
      setCurrentStep(2);
      stepStackRef.current = [1, 2]; // Maintain stack but start at step 2
      navigate(location.pathname, { state: { step: 2 } });
    }
  }, []);

  const handleNext = async (data) => {
    setIsBackward(false);
    
    setFormData(prevData => {
      const newData = {
        ...prevData,
        ...data
      };
      return newData;
    });

    const nextStep = currentStep + 1;
    
    if (currentStep === 2 && !data.gameType?.requiresAIGeneration) {
      const updatedData = {
        ...formData,
        ...data,
        aiGeneratedContent: {
          options: data.gameType.options
        }
      };
      setFormData(updatedData);
      
      if (!data.gameType?.setupOptions?.length) {
        stepStackRef.current = [1, 3];
      } else {
        stepStackRef.current.push(3);
      }
      setCurrentStep(3);
      navigate(location.pathname, { state: { step: 3 } });
      return;
    }
    
    if (currentStep === 2 && data.gameType?.requiresAIGeneration) {
      const updatedFormData = {
        ...formData,
        ...data
      };
      setFormData(updatedFormData);
      
      setIsGenerating(true);
      setLoadingMessage(loadingMessages[0]); // Set initial message immediately when starting generation
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/app/generate-game`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            gameTypeId: data.gameType.id,
            shortDescription: data.shortDescription,
            additionalSettings: data.additionalSettings
          }),
        });

        const responseData = await response.json();

        if (!response.ok) {
          throw new Error(responseData.error || 'Failed to generate game content');
        }

        const updatedData = {
          ...data,
          aiGeneratedContent: {
            options: responseData.message.options
          }
        };
        
        setFormData(updatedData);
        setIsGenerating(false);
        setCurrentStep(3);
        stepStackRef.current.push(3);
        navigate(location.pathname, { state: { step: 3 } });
        
      } catch (error) {
        console.error('Generation error:', error);
        setIsGenerating(false);
        // Handle error (maybe show an error message to user)
      }
    } else {
      setFormData(prevData => ({
        ...prevData,
        ...data
      }));
      setCurrentStep(nextStep);
      stepStackRef.current.push(nextStep);
      navigate(location.pathname, { state: { step: nextStep } });
    }
  };

  const handleBack = () => {
   
    
    if (stepStackRef.current.length > 1) {
      setIsBackward(true);
      stepStackRef.current.pop();
      const prevStep = stepStackRef.current[stepStackRef.current.length - 1];
      setCurrentStep(prevStep);
      navigate(location.pathname, { state: { step: prevStep } });
    }
  };


  const handleRegenerate = useCallback(async (elementType, currentData, index, comment) => {
    
    const regenerationKey = index !== undefined ? `${elementType}_${index}` : elementType;
    setIsRegenerating(prev => ({ ...prev, [regenerationKey]: true }));

    try {
      const requestBody = {
        elementType,
        currentData,
        gameTypeId: formData.gameType?.id,
        description: formData.description,
        shortDescription: formData.shortDescription,
        additionalSettings: formData.additionalSettings,
        gameData: formData.aiGeneratedContent,
        comment: comment || '' // Ensure comment is never undefined
      };


      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/app/regenerate-element`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });


      const data = await response.json();


      if (!response.ok) {
        throw new Error(data.error || 'Failed to regenerate content');
      }

      setFormData(prevData => {

        const updatedData = { ...prevData };
        if (index !== undefined) {
          // Update array item
          const newData = [...updatedData.aiGeneratedContent.options.data];
          newData[index] = data.generatedContent;
          updatedData.aiGeneratedContent = {
            ...updatedData.aiGeneratedContent,
            options: {
              ...updatedData.aiGeneratedContent.options,
              data: newData
            }
          };
        } else {
          // Update title or intro
          updatedData.aiGeneratedContent = {
            ...updatedData.aiGeneratedContent,
            options: {
              ...updatedData.aiGeneratedContent.options,
              [elementType]: data.generatedContent
            }
          };
        }

        return updatedData;
      });
    } catch (error) {
      console.error('Error regenerating content:', error);
    } finally {
      setIsRegenerating(prev => ({ ...prev, [regenerationKey]: false }));
    }
  }, [formData]);


  const renderDynamicSummary = () => {
    const { aiGeneratedContent } = formData;
    
    const renderIcon = (IconComponent) => {
      if (!IconComponent) return null;
      
      const colorClass = formData.gameType?.id === 'pindrop' ? 'text-red-600' :
                        formData.gameType?.id === 'hint' ? 'text-amber-600' :
                        formData.gameType?.id === 'quiz' ? 'text-blue-600' :
                        formData.gameType?.id === 'mapselect' ? 'text-emerald-600' :
                        formData.gameType?.id === 'snake' ? 'text-purple-600' :
                        'text-indigo-600';

      return <IconComponent className={`w-6 h-6 ${colorClass}`} />;
    };

    return (
      <div className="space-y-2">
        {/* Game Type Summary */}
        <div className="bg-gray-50 dark:bg-gray-900 rounded-xl p-6 mb-6">
          <div className="flex items-center space-x-4">
            <div className={`p-3 rounded-lg ${
              formData.gameType?.id === 'pindrop' ? 'bg-red-50 dark:bg-red-900/50' :
              formData.gameType?.id === 'hint' ? 'bg-amber-50 dark:bg-amber-900/50' :
              formData.gameType?.id === 'quiz' ? 'bg-blue-50 dark:bg-blue-900/50' :
              formData.gameType?.id === 'mapselect' ? 'bg-emerald-50 dark:bg-emerald-900/50' :
              formData.gameType?.id === 'snake' ? 'bg-purple-50 dark:bg-purple-900/50' :
              'bg-indigo-50 dark:bg-indigo-900/50'
            }`}>
              {renderIcon(formData.gameType?.icon)}
            </div>
            <div>
              <h2 className="text-xl font-semibold">{formData.gameType?.name}</h2>
              <p className="text-sm text-gray-500">{formData.gameType?.description}</p>
            </div>
          </div>
        </div>



        {/* Title Section */}
        <div className="bg-white dark:bg-gray-800 rounded-lg p-3">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Title</h3>
            <RegenerateButton 
              onClick={(comment) => handleRegenerate('title', aiGeneratedContent?.options?.title, undefined, comment)}
              isRegenerating={isRegenerating.title}
            />
          </div>
          {isRegenerating.title ? (
            <div className="flex items-center justify-center h-8 mt-2">
              <PulseLoader color="#4B5563" size={8} />
            </div>
          ) : (
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1.5">
              {aiGeneratedContent?.options?.title || 'No title generated'}
            </p>
          )}
        </div>
        
        {/* Introduction Section */}
        <div className="bg-white dark:bg-gray-800 rounded-lg p-3">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Introduction</h3>
            <RegenerateButton 
              onClick={(comment) => handleRegenerate('intro', aiGeneratedContent?.options?.intro, undefined, comment)}
              isRegenerating={isRegenerating.intro}
            />
          </div>
          {isRegenerating.intro ? (
            <div className="flex items-center justify-center h-8 mt-2">
              <PulseLoader color="#4B5563" size={8} />
            </div>
          ) : (
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1.5">
              {aiGeneratedContent?.options?.intro || 'No intro generated'}
            </p>
          )}
        </div>
        
             {/* Game Content Section - Only show if AI generation is required */}
             {formData.gameType?.requiresAIGeneration && (
        <div className="bg-white dark:bg-gray-800 rounded-lg p-4">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-base font-medium text-gray-900 dark:text-gray-100">Game Content</h3>
            <span className="text-sm text-gray-500 dark:text-gray-400">
              {aiGeneratedContent?.options?.data?.length || 0} rounds
            </span>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
            {aiGeneratedContent?.options?.data?.map((item, index) => (
              <div
                key={index}
                className="group flex items-center bg-gray-50 dark:bg-gray-700 rounded-lg px-4 py-3 border border-gray-100 dark:border-gray-600 hover:shadow-sm transition-shadow duration-200"
              >
                <span className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 dark:bg-gray-600 text-xs font-medium mr-3">
                  {index + 1}
                </span>
                
                <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100 flex-grow truncate">
                  {item.name || item.title || item.question || item.answer}
                </h4>

                <div className="ml-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  <RegenerateButton 
                    onClick={(comment) => handleRegenerate('item', item, index, comment)}
                    isRegenerating={isRegenerating[`item_${index}`]}
                  />
                </div>
              </div>
            ))}
            
            {/* Add New Card */}
            <div
              className="flex items-center justify-center bg-gray-50 dark:bg-gray-700 rounded-lg px-4 py-3 border-2 border-dashed border-gray-200 dark:border-gray-600 hover:border-gray-300 dark:hover:border-gray-500 hover:bg-gray-100 dark:hover:bg-gray-600/50 transition-all duration-200"
            >
              <RegenerateButton 
                onClick={(comment) => handleRegenerate('item', null, aiGeneratedContent?.options?.data?.length, comment)}
                isRegenerating={isRegenerating[`item_${aiGeneratedContent?.options?.data?.length}`]}
                label="Add new round"
                className="text-gray-600 dark:text-blue-400 hover:text-gray-700 dark:hover:text-blue-300"
              />
            </div>
          </div>
        </div>
        )}
      </div>
    );
  };

  const handleCreate = async () => {
    try {
      const { gameType } = formData;
      let gameOptions;

      // Get game type color first
      const gameTypeColor = getGameTypeColor(gameType.id);

      let stylePresets;
      try {
        stylePresets = getStylePresets(gameTypeColor, businessProfile, gameType.id);
      } catch (styleError) {
        throw styleError;
      }

      // Use the selected style or fall back to default
      let theme = stylePresets[selectedStyle] || stylePresets.default;
      
      // Initialize base theme colors if they don't exist
      theme = {
        ...theme,
        colors: {
          ...theme.colors,
          titleText: theme.colors.titleText || '#1F2937',
          subtitleText: theme.colors.subtitleText || '#4B5563',
          cardBackground: theme.colors.cardBackground || '#FFFFFF',
          cardOpacity: theme.colors.cardOpacity || 0.9,
          buttonBackground: theme.colors.buttonBackground || '#4F46E5',
          buttonText: theme.colors.buttonText || '#FFFFFF',
          results: {
            ...theme.colors?.results,
            cardBackground: theme.colors?.results?.cardBackground || '#FFFFFF',
            cardBackgroundOpacity: theme.colors?.results?.cardBackgroundOpacity || 0.9,
            scoreLabelText: theme.colors?.results?.scoreLabelText || '#4B5563',
            scoreValueText: theme.colors?.results?.scoreValueText || '#1F2937'
          }
        }
      };

      // For Snake game, ensure game-specific colors are properly initialized
      if (gameType.id === 'snake') {
        theme.colors.game = {
          canvasColor: theme.colors.cardBackground || '#FFFFFF',
          snakeColor: theme.colors.primary || '#00FF00',
          foodColor: theme.colors.accent || '#FF0000',
          controlButtonColor: theme.colors.cardBackground || '#FFFFFF',
          controlButtonOpacity: theme.colors.cardOpacity || 0.9,
          controlIconColor: theme.colors.primary || '#9CA3AF',
          scoreText: theme.colors.titleText || '#4B5563'
        };
      }

      if (gameType.requiresAIGeneration) {
        gameOptions = {
          ...gameType.options,
          ...(formData.aiGeneratedContent?.options || {}),
          theme,
          hideHeadline: true
        };
      } else {
        gameOptions = {
          ...gameType.options,
          theme,
          hideHeadline: true,
          ...(formData.additionalSettings && {
            gameSettings: {
              ...gameType.options.gameSettings,
              ...formData.additionalSettings
            }
          })
        };
      }

      const dataToInsert = {
        gameType: gameType.id,
        gameTypeDisplayValue: gameType.name,
        name: `${gameType.name}`,
        createdBy: user.uid,
        companyId: businessProfile.id,
        status: 'draft',
        options: gameOptions,
        createdAt: new Date()
      };

      const docRef = await addDoc(collection(db, 'campaign'), dataToInsert);
      navigate(`/campaign/${docRef.id}`);
    } catch (error) {
      // Handle error silently or show user feedback through UI
    }
  };

  const styles = `
    .slide-enter {
      transform: translateX(100%);
      opacity: 0;
    }
    .slide-enter-active {
      transform: translateX(0%);
      opacity: 1;
      transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    }
    .slide-exit {
      transform: translateX(0%);
      opacity: 1;
    }
    .slide-exit-active {
      transform: translateX(-100%);
      opacity: 0;
      transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    }

    /* Custom classes for back navigation */
    .slide-back-enter {
      transform: translateX(-100%);
      opacity: 0;
    }
    .slide-back-enter-active {
      transform: translateX(0%);
      opacity: 1;
      transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    }
    .slide-back-exit {
      transform: translateX(0%);
      opacity: 1;
    }
    .slide-back-exit-active {
      transform: translateX(100%);
      opacity: 0;
      transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    }
  `;

  // Add this helper function near the top of the component
  const isAnyRegenerating = () => {
    return Object.values(isRegenerating).some(value => value === true);
  };

  const LoadingSkeleton = () => (
    <div className="animate-pulse space-y-4">
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
      <div className="h-4 bg-gray-200 rounded"></div>
      <div className="h-4 bg-gray-200 rounded w-5/6"></div>
    </div>
  );

  const pageTransition = {
    initial: { opacity: 0, x: 20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -20 },
    transition: { duration: 0.3, ease: "easeInOut" }
  };

  return (
    <>
      <style>{styles}</style>
      <div>
        <div className="mb-8">
          <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold">
            New Campaign
          </h1>
        </div>

        <div className="bg-white dark:bg-gray-800 px-5 py-5 rounded-xl mb-8">
          <div className="relative overflow-hidden">
            {isGenerating ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className="flex flex-col items-center justify-center min-h-[400px] space-y-6"
              >
                <div className="relative">
                  <motion.div 
                    animate={{ rotate: 360 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                    className="w-16 h-16 border-4 rounded-full"
                    style={{
                      borderColor: `${getGameTypeColor(formData.gameType.id)}33`,
                      borderTopColor: getGameTypeColor(formData.gameType.id)
                    }}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <RocketLaunchIcon 
                      className="w-8 h-8"
                      style={{ color: getGameTypeColor(formData.gameType.id) }}
                    />
                  </div>
                </div>
                <AnimatePresence initial={true} mode="wait">
                  <motion.div
                    key={loadingMessage}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{
                      duration: 0.5,
                      ease: [0.4, 0.0, 0.2, 1]
                    }}
                    className="text-center"
                  >
                    <p className="text-sm font-semibold text-gray-600 dark:text-gray-400">
                      {loadingMessage}
                    </p>
                  </motion.div>
                </AnimatePresence>
                <motion.div
                  animate={{ opacity: [0.5, 1, 0.5] }}
                  transition={{ duration: 2, repeat: Infinity }}
                  className="text-xs text-gray-400 dark:text-gray-500"
                >
                  This may take a few moments
                </motion.div>
              </motion.div>
            ) : (
     // Inside the AnimatePresence component, after the step content
<AnimatePresence mode="wait">
  <motion.div
    key={currentStep}
    initial={{ opacity: 0, x: isBackward ? -20 : 20 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: isBackward ? 20 : -20 }}
    transition={{ duration: 0.3, ease: "easeInOut" }}
    className="w-full px-4 md:px-8 py-4"
  >
    {/* Step content remains the same */}
    {currentStep === 1 && (
      <SelectGameType onNext={handleNext} formData={formData} />
    )}
    {currentStep === 2 && (
      <InitialSetup
        formData={formData}
        onNext={handleNext}
        onBack={handleBack}
      />
    )}
    {currentStep === 3 && (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              Review your game
            </h2>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              Preview your game content and make any final adjustments before creating
            </p>
          </div>

          {renderDynamicSummary()}
          
          {/* Add back QuickStyleSelector with error boundary */}
          <div className="relative">
            {(() => {
              try {
                return (
                  <QuickStyleSelector
                    selectedStyle={selectedStyle}
                    onStyleSelect={(style) => {
                      setSelectedStyle(style);
                    }}
                    businessProfile={businessProfile}
                    formData={formData}
                    title={formData.aiGeneratedContent?.options?.title || 'Preview Title'}
                    description={formData.aiGeneratedContent?.options?.intro || 'Sample description'}
                  />
                );
              } catch (error) {
                console.error('Error rendering QuickStyleSelector:', error);
                return (
                  <div className="p-4 bg-red-50 text-red-600 rounded-md">
                    Error loading style selector
                  </div>
                );
              }
            })()}
          </div>
        </motion.div>
        
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="mt-6"
        >
          <div className="flex justify-between">
            <button
              type="button"
              onClick={handleBack}
              disabled={isAnyRegenerating()}
              className={`px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50
                ${isAnyRegenerating() ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              Back
            </button>
            
            <button
              onClick={handleCreate}
              disabled={isAnyRegenerating()}
              className={`px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm
                ${isAnyRegenerating() ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90'}`}
              style={{
                backgroundColor: getGameTypeColor(formData.gameType.id)
              }}
            >
              Create Campaign
            </button>
          </div>
        </motion.div>
      </>
    )}
  </motion.div>
</AnimatePresence>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewCampaign;
