import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default function PasswordResetModal({ show, onClose, auth }) {
    const [resetEmail, setResetEmail] = useState("");
    const [resetNotice, setResetNotice] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // Clear states when modal is opened
    useEffect(() => {
        if (show) {
            // Check local storage for cooldown
            const cooldownEnd = localStorage.getItem('passwordResetCooldown');
            if (cooldownEnd && Date.now() < parseInt(cooldownEnd)) {
                setIsButtonDisabled(true);
                setResetNotice("Please wait before requesting another reset email.");
                setIsSuccess(false);
            } else {
                setIsButtonDisabled(false);
                setResetNotice("");
                setIsSuccess(false);
            }
        }
    }, [show]);

    const handlePasswordReset = async () => {
        if (!resetEmail) {
            setResetNotice("Please enter your email address to reset your password.");
            setIsSuccess(false);
            return;
        }

        try {
            setIsButtonDisabled(true);
            const functions = getFunctions();
            const sendCustomEmail = httpsCallable(functions, 'sendPasswordResetEmail');
            
            await sendCustomEmail({
                email: resetEmail
            });

            // Set cooldown in localStorage (2 minutes from now)
            localStorage.setItem('passwordResetCooldown', (Date.now() + 120000).toString());

            setResetNotice("Password reset email sent. Please check your inbox, it may end in your junk folder.");
            setIsSuccess(true);
            setIsButtonDisabled(true);

        } catch (error) {
            console.error("Detailed error:", error);
            
            let errorMessage = "Error sending password reset email. Please try again.";
            if (error.message) {
                errorMessage = error.message || error.data?.message || errorMessage;
            }
            
            setResetNotice(errorMessage);
            setIsSuccess(false);
            setIsButtonDisabled(false);
        }
    };

    return (
        <Transition appear show={show} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={React.Fragment}
                            enter="transition-all duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="transition-all duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div className="flex justify-between items-center">
                                    <Dialog.Title as="h3" className="text-md font-medium leading-6 text-gray-900">
                                         Password reset
                                    </Dialog.Title>
                                    <button
                                        type="button"
                                        className="text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={onClose}
                                    >
                                        <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <p className="mt-2 mb-5 text-sm text-gray-500">
                                    Please note: it may take up to 5 minutes for the email to arrive.
                                </p>
                                <div className="mt-2">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        value={resetEmail}
                                        onChange={(e) => setResetEmail(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    {resetNotice && 
                                        <div className={`text-sm mb-5 ${isSuccess ? 'text-green-600' : 'text-red-600'}`}>
                                            <div className="flex items-center">
                                                {isSuccess ? 
                                                    <CheckCircleIcon className="h-5 w-5 text-green-600 mr-2" /> : 
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-600 mr-2" />
                                                }
                                                <span>{resetNotice}</span>
                                            </div>
                                        </div>
                                    }
                                    {!isButtonDisabled && (
                                        <button
                                            type="button"
                                            onClick={handlePasswordReset}
                                            className="inline-flex w-full justify-center rounded-md bg-violet-500 transition-colors duration-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                                        >
                                            Send reset email
                                        </button>
                                    )}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}