import React, { useState, useEffect, useRef } from 'react';
import WebFont from 'webfontloader';
import HeadlineCard from '../dashboard/HeadlineCard';
import SmilesFlowLink from '../dashboard/LinkToSmilesFlow';
import { fetchHeadline, createCampaignEvent } from '../common/Database';
import HeadlineDisplay from '../dashboard/HeadlineDisplay';
import { motion, AnimatePresence } from 'framer-motion';

const hexToRgb = (hex) => {
  // Remove # if present
  hex = hex.replace('#', '');
  
  // Parse the hex values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  
  return `${r}, ${g}, ${b}`;
};

const StartCard = ({ game = {}, onStart = () => {}, overrideOptions = {}, preview = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [headline, setHeadline] = useState({
    headline_url: '',
    headlinePhoto: '',
    headline: '',
    subheadline: ''
  });
  const [backgroundReady, setBackgroundReady] = useState(false);
  const [cardReady, setCardReady] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  
  // Merge options with any overrides
  const options = { ...game.options, ...overrideOptions };
  
  // Create a merged headline object that prioritizes override values
  const effectiveHeadline = preview ? {
    ...headline, // Base layer: business profile settings
    // Override with preview options
    headline: overrideOptions.headline || '',
    subheadline: overrideOptions.subheadline || '',
    headline_url: overrideOptions.headline_url || '',
    headlinePhoto: overrideOptions.headlinePhoto || '',
    showIcon: overrideOptions.showIcon,
    theme: {
      colors: {
        headline: {
          headlineColor: options.theme?.colors?.headline?.headlineColor || headline?.headlineColor || "#FFFFFF",
          subheadlineColor: options.theme?.colors?.headline?.subheadlineColor || headline?.subheadlineColor || "#FFFFFF",
          iconColor: options.theme?.colors?.headline?.iconColor || headline?.iconColor || "#FFFFFF"
        }
      }
    }
  } : {
    ...headline,
    theme: {
      colors: {
        headline: {
          headlineColor: options.theme?.colors?.headline?.headlineColor || headline?.headlineColor,
          subheadlineColor: options.theme?.colors?.headline?.subheadlineColor || headline?.subheadlineColor,
          iconColor: options.theme?.colors?.headline?.iconColor || headline?.iconColor
        }
      }
    }
  };

  const gameType = overrideOptions.gameType || game.gameType;
  const startButtonText = options?.startButtonText || "Start";
  const eventDocRef = useRef(null);
  const showBranding = !options?.hideBranding;
  const showHeadline = !options?.hideHeadline;

  // Theme setup
  const theme = {
    fonts: {
      title: `"${options?.theme?.typography?.titleFont || 'Inter'}"`,
      subtitle: `"${options?.theme?.typography?.subtitleFont || 'Inter'}"`,
      button: `"${options?.theme?.typography?.buttonFont || 'Inter'}"`,
    },
    colors: {
      outerBackground: options?.theme?.colors?.outerBackground || '#4B5563',
      outerBorder: options?.theme?.colors?.outerBorder || '#4B5563',
      outerOpacity: options?.theme?.colors?.outerOpacity || 0.2,
      cardBackground: options?.theme?.colors?.cardBackground || '#ffffff',
      cardOpacity: options?.theme?.colors?.cardOpacity ?? 1,
      titleText: options?.theme?.colors?.titleText || '#4B5563',
      subtitleText: options?.theme?.colors?.subtitleText || '#4B5563',
      buttonBackground: options?.theme?.colors?.buttonBackground || '#7C3AED',
      buttonOpacity: options?.theme?.colors?.buttonOpacity ?? 1,
      buttonText: options?.theme?.colors?.buttonText || '#FFFFFF'
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 800);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const titleFont = options?.theme?.typography?.titleFont;
    const subtitleFont = options?.theme?.typography?.subtitleFont;
    const buttonFont = options?.theme?.typography?.buttonFont;

    const fontsToLoad = [...new Set([titleFont, subtitleFont, buttonFont])]
      .filter(font => font && font !== 'Inter');

    if (fontsToLoad.length > 0) {
      WebFont.load({
        google: {
          families: fontsToLoad
        }
      });
    }
  }, [options?.theme?.typography]);

  useEffect(() => {
    const fetchHeadlineData = async () => {
      if (game.companyId && !preview) {  // Don't fetch if in preview mode
        try {
          const headlineData = await fetchHeadline(game.companyId);
          if (headlineData) {
            setHeadline(prev => ({
              ...prev,
              ...headlineData,
              theme: options?.theme ? {
                colors: {
                  headline: {
                    headlineColor: options.theme?.colors?.headline?.headlineColor || headlineData?.headlineColor,
                    subheadlineColor: options.theme?.colors?.headline?.subheadlineColor || headlineData?.subheadlineColor,
                    iconColor: options.theme?.colors?.headline?.iconColor || headlineData?.iconColor
                  }
                }
              } : headlineData?.theme,
              headlineColor: options.theme?.colors?.headline?.headlineColor || headlineData?.headlineColor,
              subheadlineColor: options.theme?.colors?.headline?.subheadlineColor || headlineData?.subheadlineColor,
              iconColor: options.theme?.colors?.headline?.iconColor || headlineData?.iconColor
            }));
          }
        } catch (error) {
          console.error("Error fetching headline data:", error);
        }
      }
    };

    fetchHeadlineData();
  }, [game.companyId, preview, options?.theme]);

  useEffect(() => {

    const bg = document.createElement('div');
    bg.style.backgroundColor = 'red'; // Debug color
    
    setTimeout(() => {
      setBackgroundReady(true);
    }, 50);
  }, []);

  useEffect(() => {
    // Small delay to ensure card styles are ready before showing
    setTimeout(() => setCardReady(true), 50);
  }, []);

  const handleStart = async () => {
    // Prevent multiple clicks
    if (isStarting) return;
    
    // Set starting state to true to prevent additional clicks
    setIsStarting(true);
    
    setIsVisible(false); // Trigger exit animation

    // Wait for animation to complete before proceeding
    await new Promise(resolve => setTimeout(resolve, 400)); // Match exit animation duration

    if (game.status === "live") {
      try {
        // Add retry logic for high-volume scenarios
        const maxRetries = 3;
        let retryCount = 0;
        let eventRef = null;

        while (retryCount < maxRetries && !eventRef) {
          try {
            eventRef = await createCampaignEvent(game.id);
            eventDocRef.current = eventRef;
            break;
          } catch (error) {
            retryCount++;
            if (error.code === 'resource-exhausted' && retryCount < maxRetries) {
              // Exponential backoff
              await new Promise(resolve => setTimeout(resolve, Math.pow(2, retryCount) * 100));
              continue;
            }
            throw error;
          }
        }
      } catch (error) {
        console.error("Error creating event:", error);
        // Reset starting state if there's an error
        setIsStarting(false);
      }
    }
    onStart(eventDocRef.current);
  };

  return (
    <div 
      className="relative flex items-center justify-center h-full"
      style={{
        backgroundColor: theme.colors.outerBackground ? 
          `rgba(${hexToRgb(theme.colors.outerBackground)}, ${theme.colors.outerOpacity || 0.1})` : 
          'rgba(75, 85, 99, 0.1)'
      }}
    >
      {cardReady && (
        <motion.div 
          className="w-full max-w-xl px-4 py-5 sm:px-6 lg:px-8 relative z-10"
          initial={{ opacity: 0, y: 30, scale: 0.95 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95, y: 30 }}
          transition={{ duration: 0.4, ease: [0.4, 0.0, 0.2, 1] }}
        >
          <div 
            className={`rounded-xl p-2 sm:min-w-[500px] min-w-[250px] ${theme.colors.cardOpacity !== 0 ? 'ring-1 ring-inset' : ''}`}
            style={{
              '--tw-ring-color': theme.colors.cardBackground ? 
                `rgba(${hexToRgb(theme.colors.cardBackground)}, 0.05)` : 
                'rgba(255, 255, 255, 0.05)'
            }}
          >
            <div 
              className="rounded-lg overflow-hidden backdrop-blur-sm shadow-lg"
              style={{
                backgroundColor: theme.colors.cardBackground ? 
                  `rgba(${hexToRgb(theme.colors.cardBackground)}, ${theme.colors.cardOpacity})` :
                  'rgba(255, 255, 255, 1)',
                transform: 'translateZ(0)'
              }}
            >
              <div className={`p-6 ${!showHeadline ? 'pt-2' : 'pt-4'}`}>
                {/* Headline - Animate First */}
                {showHeadline && (
                  <motion.div
                    initial={{ opacity: 1, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ 
                      duration: 0.4,
                      delay: 0.1,
                      ease: [0.4, 0.0, 0.2, 1]
                    }}
                  >
                    <HeadlineDisplay customization={effectiveHeadline} />
                  </motion.div>
                )}

                {/* Title - Second */}
                <motion.h3 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.4,
                    delay: 0.2, // Slightly longer delay
                    ease: [0.4, 0.0, 0.2, 1]
                  }}
                  className="text-sm sm:text-md font-semibold leading-6 break-words mt-4"
                  style={{ 
                    fontFamily: theme.fonts.title,
                    color: theme.colors.titleText
                  }}
                >
                  {options.title}
                </motion.h3>

                {/* Description - Third */}
                <motion.div 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.4,
                    delay: 0.3, // Even longer delay
                    ease: [0.4, 0.0, 0.2, 1]
                  }}
                  className="mt-2 text-sm sm:text-md"
                >
                  <p className="break-words" style={{ 
                    fontFamily: theme.fonts.subtitle,
                    color: theme.colors.subtitleText
                  }}>
                    {options.intro}
                  </p>
                </motion.div>

                {/* Cover Photo - Fourth */}
                {options.coverPhoto && (
                  <motion.div 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ 
                      duration: 0.4,
                      delay: 0.4,
                      ease: [0.4, 0.0, 0.2, 1]
                    }}
                    className="mt-3 relative w-full"
                    style={{ aspectRatio: '1.91/1' }}
                  >
                    <img
                      src={options.coverPhoto}
                      alt="Cover"
                      className={`absolute inset-0 w-full h-full ${
                        options?.coverPhotoSettings?.rounded !== false ? 'rounded-lg' : ''
                      }`}
                      style={{
                        objectFit: 'cover',
                        objectPosition: '50% 50%'
                      }}
                    />
                  </motion.div>
                )}

                {/* Start Button - Fifth */}
                <motion.div 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.4,
                    delay: 0.5,
                    ease: [0.4, 0.0, 0.2, 1]
                  }}
                  className="mt-4 w-full"
                >
                  <button
                    onClick={() => !preview && handleStart()}
                    // Disable the button when starting or in preview mode
                    disabled={preview || isStarting}
                    className={`w-full rounded-md px-3 py-2 text-sm sm:text-md font-semibold shadow-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                      isStarting ? 'opacity-70 cursor-not-allowed' : 'hover:opacity-90 hover:transform hover:scale-[1.02] active:scale-[0.98]'
                    }`}
                    style={{ 
                      backgroundColor: theme.colors.buttonBackground,
                      color: theme.colors.buttonText,
                      fontFamily: theme.fonts.button
                    }}
                  >
                    {startButtonText}
                  </button>
                </motion.div>

                {/* Branding - Last */}
                {showBranding && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ 
                      duration: 0.4,
                      delay: 0.6,
                      ease: [0.4, 0.0, 0.2, 1]
                    }}
                  >
                    <SmilesFlowLink />
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default StartCard;