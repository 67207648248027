import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useUser } from '../userContext';

import SettingsSidebar from '../partials/settings/SettingsSidebar';
import Profile from './Profile';
import Company from './Company';
import Plans from './Plans'; // Import the Plans component
import Users from './Users';


function Settings() {
  const [planRedirectLoading, setPlanRedirectLoading] = useState(false);
  const { subscriptionType } = useUser();

  return (
    <div className="sm:px-4 pb-5 max-w-7xl mx-auto">
      <div className="mb-8">
        <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold">
        Settings
        </h1>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <SettingsSidebar />
          <Routes>
            <Route path="account" element={<Profile />} />
            <Route path="company" element={<Company />} />
            <Route path="plans" element={   <Plans
              onNext={() =>{}}
              setPlanRedirectLoading={setPlanRedirectLoading}
            />} /> 
            <Route 
              path="users" 
              element={
                subscriptionType === 'agency' 
                  ? <Users /> 
                  : <Navigate to="/settings/plans" replace />
              } 
            />

          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Settings;
