import React, { useState, useEffect } from 'react';
import Plans from '../account/Plans';
import { getAuth, signOut } from 'firebase/auth'; // Import Firebase auth
import useAuth from './useAuth';

function PlanModal() {
  const [basicModal, setBasicModal] = useState(false); // Modal is closed by default
  const { user, loading, currentPlan, isFreeUser } = useAuth();



  useEffect(() => {
    const timer = setTimeout(() => {
      setBasicModal(true); // Open modal after 2 seconds
    }, 500);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const handleStripeFailure = () => {
    console.error('Stripe setup failed');
  };

  const auth = getAuth(); // Initialize auth (for Firebase)

  const handleSignOut = () => {
    setTimeout(() => {
      signOut(auth).then(() => {
        console.log('User signed out');
      }).catch((error) => {
        console.error('Signout error', error);
      });
    }, 300); // 500 milliseconds delay
  };

  return (
<div>
      <Plans onNext={() => {}} onStripeFailure={handleStripeFailure} />
      <div className='mb-5 text-center text-xs'>
        <button onClick={handleSignOut} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}>
          Sign in with different account
        </button>
      </div>
</div>
  );
}

export default PlanModal;
