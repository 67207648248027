// add boilder plate for me

import React, { useState, useEffect } from 'react';
import { addDoc, collection, query, where, getDocs, deleteDoc, doc, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';
import { useUser } from '../userContext';
import { Link, useNavigate } from 'react-router-dom';
import { UserCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useClient } from '../clientContext';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const ClientHome = () => {
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { clients, refreshClients } = useClient();
  const { user, businessProfile, userProfile } = useUser();
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const isImpersonating = businessProfile?.id !== userProfile?.companyId;
  
  if (isImpersonating) {
    return (
      <div className="p-6 space-y-6 w-full">
        <div className="bg-yellow-100 w-full border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <p className="font-bold">Not Applicable</p>
          <p>Client management is not available while impersonating a client.</p>
        </div>
      </div>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const newCompany = {
        name: companyName,
        parentCompany: businessProfile.id,
        type: 'client',
        createdAt: new Date(),
      };

      await addDoc(collection(db, 'company'), newCompany);
      setCompanyName('');
      setSuccess(true);
      await refreshClients();
      setTimeout(() => setSuccess(false), 3000);
    } catch (err) {
      setError('Failed to create company. Please try again.');
      console.error('Error creating company:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      return timestamp.toDate().toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    }
    return 'N/A';
  };

  const handleDeleteClient = async (client) => {
    setClientToDelete(client);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!clientToDelete) return;
    
    setIsDeleting(true);
    try {
      const batch = writeBatch(db);
      
      // Delete all campaigns associated with this client
      const campaignsQuery = query(
        collection(db, 'campaigns'),
        where('companyId', '==', clientToDelete.id)
      );
      const campaignsSnapshot = await getDocs(campaignsQuery);
      campaignsSnapshot.docs.forEach((campaignDoc) => {
        batch.delete(doc(db, 'campaigns', campaignDoc.id));
      });
      
      // Delete the client
      batch.delete(doc(db, 'company', clientToDelete.id));
      
      // Commit the batch
      await batch.commit();
      
      // Refresh the clients list
      await refreshClients();
      setDeleteModalOpen(false);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (err) {
      console.error('Error deleting client:', err);
      setError('Failed to delete client. Please try again.');
    } finally {
      setIsDeleting(false);
      setClientToDelete(null);
    }
  };

  const renderClientListItem = (client) => (
    <li key={client.id}>
      <div className="px-4 py-3 flex items-center justify-between">
        <div className="flex items-center min-w-0 flex-1">
          <div className="flex-shrink-0">
            {client.headlinePhoto ? (
              <img
                src={client.headlinePhoto}
                alt={`${client.name} logo`}
                className="h-8 w-8 rounded-lg object-contain bg-gray-100 dark:bg-gray-700"
              />
            ) : (
              <div className="h-8 w-8 rounded-lg bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center">
                <UserCircleIcon className="h-5 w-5 text-violet-600 dark:text-violet-400" />
              </div>
            )}
          </div>
          <div className="ml-3 min-w-0 flex-1">
            <h2 className="text-sm font-medium text-gray-900 dark:text-white truncate">
              {client.name}
            </h2>
            <div className="flex items-center text-xs text-gray-500 dark:text-gray-400">
              <span>Created {formatDate(client.createdAt)}</span>
            </div>
          </div>
        </div>
        <div className="ml-4 flex items-center space-x-2">
          <button
            onClick={() => navigate(`/edit-client/${client.id}`)}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 dark:border-gray-600 rounded-md text-xs font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 transition-colors"
          >
            Manage
          </button>
          <button
            onClick={() => handleDeleteClient(client)}
            className="inline-flex items-center px-3 py-1.5 border border-red-300 dark:border-red-600 rounded-md text-xs font-medium text-red-700 dark:text-red-300 bg-white dark:bg-gray-800 hover:bg-red-50 dark:hover:bg-red-900/30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>
    </li>
  );

  return (
    <>
      <div className="sm:px-4 pb-5 max-w-7xl mx-auto">
        {/* Header */}
        <div className="mb-5 pb-3 flex justify-between items-center">
          <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-white font-bold">Clients</h1>
        </div>

        <div className="grid gap-6 md:grid-cols-3">
          {/* New Client Form - Now in a side panel */}
          <div className="md:col-span-1">
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 sticky top-6">
              <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Create New Client</h2>
              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="mt-1 block w-full rounded-lg border border-gray-300 dark:border-gray-600 py-2.5 px-3 shadow-sm focus:border-violet-500 focus:outline-none focus:ring-violet-500 dark:bg-gray-700 dark:text-white sm:text-sm"
                      placeholder="Enter company name"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full inline-flex justify-center items-center px-4 py-2.5 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-violet-600 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isLoading ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                        Creating...
                      </>
                    ) : (
                      'Create Client'
                    )}
                  </button>
                </div>
              </form>
              {error && (
                <div className="mt-4 p-3 bg-red-50 dark:bg-red-900/30 rounded-lg">
                  <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
                </div>
              )}
              {success && (
                <div className="mt-4 p-3 bg-green-50 dark:bg-green-900/30 rounded-lg">
                  <p className="text-sm text-green-600 dark:text-green-400">Client created successfully!</p>
                </div>
              )}
            </div>
          </div>

          {/* Clients List - Now in main content area */}
          <div className="md:col-span-2">
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
              {clients.length > 0 ? (
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                  {clients.map(renderClientListItem)}
                </ul>
              ) : (
                <div className="text-center py-12">
                  <div className="h-16 w-16 rounded-full bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center mx-auto">
                    <UserCircleIcon className="h-10 w-10 text-violet-600 dark:text-violet-400" />
                  </div>
                  <h3 className="mt-4 text-base font-medium text-gray-900 dark:text-white">No clients yet</h3>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Create your first client using the form
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Transition.Root show={deleteModalOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setDeleteModalOpen}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 dark:bg-red-900/30 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600 dark:text-red-400" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                        Delete Client
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Are you sure you want to delete {clientToDelete?.name}? This action cannot be undone and will delete all associated campaigns.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={confirmDelete}
                      disabled={isDeleting}
                    >
                      {isDeleting ? 'Deleting...' : 'Delete'}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-gray-700 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 sm:mt-0 sm:w-auto"
                      onClick={() => setDeleteModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ClientHome;
