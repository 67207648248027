import React, { useState, useEffect, useRef } from 'react';
import { storage } from '../firebase'; // Adjust the import path as needed
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { PencilIcon, TrashIcon, PhotoIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ArrowUpTrayIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import { processImage } from '../utils/imageProcessor'; // Add this import
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ImageCropper = ({ imageUrl, onCropComplete, onCancel }) => {
  // Define a fixed aspect ratio constant - exact ratio for 1200×630
  const ASPECT_RATIO = 1200 / 630;
  const DEBUG = true; // Set to true to enable console logging

  // Helper for debug logging
  const log = (message, data) => {
    if (DEBUG) {
      console.log(`🔍 ${message}`, data || '');
    }
  };

  // Start with null crop to avoid initial flash of incorrect crop
  const [crop, setCrop] = useState(null);
  const [completedFirstCrop, setCompletedFirstCrop] = useState(false);
  const [imageRef, setImageRef] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [initializingCrop, setInitializingCrop] = useState(false);
  const cropAreaRef = useRef(null);
  const timerRef = useRef(null);

  // Log when state changes
  useEffect(() => {
    log('Crop state changed:', crop);
  }, [crop]);

  useEffect(() => {
    log('Image ref changed:', imageRef ? 'Image loaded' : 'No image');
  }, [imageRef]);

  useEffect(() => {
    log('Preview URL changed:', previewUrl ? 'Preview available' : 'No preview');
  }, [previewUrl]);

  // Clear any pending timers when component unmounts
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  // Force crop update when component is mounted
  useEffect(() => {
    // If the image is already loaded but crop hasn't been properly applied
    if (imageRef && !initializingCrop && !completedFirstCrop) {
      log('Initializing crop with delay', { imageRef: !!imageRef, initializingCrop, completedFirstCrop });
      setInitializingCrop(true);
      
      // Delay the crop application to ensure the DOM is ready
      timerRef.current = setTimeout(() => {
        log('Timer executed, applying initial crop');
        updateCropForImage(imageRef);
        setInitializingCrop(false);
        setCompletedFirstCrop(true);
      }, 500); // Increased delay to 500ms
    }
  }, [imageRef, initializingCrop, completedFirstCrop]);

  const updateCropForImage = (img) => {
    log('updateCropForImage called', { 
      naturalWidth: img.naturalWidth, 
      naturalHeight: img.naturalHeight 
    });
    
    // Get the dimensions of the image
    const { naturalWidth, naturalHeight } = img;
    
    // Set initial crop based on the ASPECT_RATIO
    const imageAspect = naturalWidth / naturalHeight;
    log('Image aspect ratio:', imageAspect);
    log('Target aspect ratio:', ASPECT_RATIO);
    
    // Calculate crop dimensions dynamically based on image's aspect ratio
    let newCrop;
    if (imageAspect > ASPECT_RATIO) {
      // Image is wider than needed - use full width and calculate height
      const cropWidth = 100;
      const cropHeight = (cropWidth / ASPECT_RATIO) * imageAspect;
      
      // Position the crop in the middle vertically
      newCrop = {
        unit: '%',
        width: cropWidth,
        height: Math.min(cropHeight, 100),
        x: 0,
        y: Math.max(0, (100 - Math.min(cropHeight, 100)) / 2)
      };
      log('Created crop for wider image:', newCrop);
    } else {
      // Image is taller than needed - use appropriate height and full width
      const cropWidth = 100;
      // Calculate the correct height to maintain aspect ratio
      const cropHeight = (cropWidth / ASPECT_RATIO) * imageAspect;
      
      newCrop = {
        unit: '%',
        width: cropWidth,
        height: cropHeight,
        x: 0,
        y: 0
      };
      log('Created crop for taller image:', newCrop);
    }
    
    log('Setting new initial crop:', newCrop);
    setCrop(newCrop);
    
    // Generate the preview
    makePreview(img, newCrop);
    setImageLoaded(true);
  };

  const onImageLoad = (e) => {
    const img = e.currentTarget;
    log('Image loaded', { 
      width: img.width, 
      height: img.height,
      naturalWidth: img.naturalWidth,
      naturalHeight: img.naturalHeight
    });
    setImageRef(img);
    // Don't immediately update crop here - let the effect trigger it
  };

  const makePreview = (image, percentCrop) => {
    log('makePreview called with crop:', percentCrop);
    if (!image || !percentCrop?.width || !percentCrop?.height) {
      log('Missing image or crop dimensions, skipping preview');
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Use higher resolution for the preview to avoid blurriness
    // Double the actual display size for sharper rendering
    canvas.width = 400;  // Double size from 200
    canvas.height = 210; // Double size from 105

    // Disable smoothing to retain sharpness
    ctx.imageSmoothingEnabled = false;

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate source coordinates
    const scaleX = image.naturalWidth / 100;
    const scaleY = image.naturalHeight / 100;
    const sourceX = percentCrop.x * scaleX;
    const sourceY = percentCrop.y * scaleY;
    const sourceWidth = percentCrop.width * scaleX;
    const sourceHeight = percentCrop.height * scaleY;

    // Draw image directly to canvas
    ctx.drawImage(
      image,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Use PNG format to preserve quality
    setPreviewUrl(canvas.toDataURL('image/png'));
  };

  const handleCropChange = (_, percentCrop) => {
    log('handleCropChange called with crop:', percentCrop);
    setCrop(percentCrop);
    if (imageRef) {
      makePreview(imageRef, percentCrop);
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    if (!imageRef || !crop?.width || !crop?.height) return;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Use exact output dimensions
    canvas.width = 1200;
    canvas.height = 630;

    // Disable smoothing to retain sharpness
    ctx.imageSmoothingEnabled = false;

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate source coordinates
    const scaleX = imageRef.naturalWidth / 100;
    const scaleY = imageRef.naturalHeight / 100;
    const sourceX = crop.x * scaleX;
    const sourceY = crop.y * scaleY;
    const sourceWidth = crop.width * scaleX;
    const sourceHeight = crop.height * scaleY;

    // Draw image directly to canvas
    ctx.drawImage(
      imageRef,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Use PNG format for the final image 
    onCropComplete(canvas.toDataURL('image/png'));
  };

  // Add a special method to simulate first click
  const simulateClick = () => {
    if (imageRef && crop) {
      log('Simulating first click to trigger proper crop');
      // Use the initial crop parameters but with values that match what ReactCrop
      // would set when clicked
      const clickSimCrop = {
        unit: '%',
        width: crop.width,
        height: 77.6, // The height ReactCrop sets
        x: crop.x,
        y: crop.y
      };
      setCrop(clickSimCrop);
      makePreview(imageRef, clickSimCrop);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="relative bg-white rounded-lg p-4 w-full max-w-[800px] max-h-[90vh] overflow-auto">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-base font-medium">Crop Cover Photo</h3>
          <button
            type="button"
            onClick={onCancel}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full md:w-[500px]">
            <div className="bg-gray-50 rounded-lg overflow-hidden" style={{ minHeight: '200px', maxHeight: '60vh' }}>
              {crop && (
                <ReactCrop
                  ref={cropAreaRef}
                  crop={crop}
                  onChange={handleCropChange}
                  aspect={ASPECT_RATIO}
                  locked={false}
                  minWidth={10}
                  minHeight={10}
                  keepSelection={true}
                  ruleOfThirds
                >
                  <img
                    src={imageUrl}
                    onLoad={onImageLoad}
                    alt="Upload preview"
                    className="max-w-full"
                    style={{ maxHeight: '60vh' }}
                  />
                </ReactCrop>
              )}
              {!crop && (
                <div className="flex items-center justify-center h-full w-full">
                  <img
                    src={imageUrl}
                    onLoad={onImageLoad}
                    alt="Upload preview"
                    className="max-w-full"
                    style={{ maxHeight: '60vh' }}
                  />
                </div>
              )}
            </div>
            <p className="mt-2 text-xs text-gray-500 text-center">
              Drag to reposition, drag corners to resize - aspect ratio will be maintained
            </p>
          </div>

          {/* Preview area - hidden on mobile, visible on md screens and up */}
          <div className="hidden md:block md:w-[200px] md:flex-shrink-0">
            <h4 className="text-sm font-medium text-gray-700 mb-2">Preview</h4>
            <div className="w-full md:w-[200px] h-[105px] rounded-lg overflow-hidden bg-gray-50">
              {previewUrl ? (
                <img 
                  src={previewUrl} 
                  alt="Crop preview" 
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    imageRendering: '-webkit-optimize-contrast'
                  }}
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center text-gray-400">
                  <PhotoIcon className="h-5 w-5" />
                </div>
              )}
            </div>
            <p className="mt-2 text-xs text-gray-500">
              Final size: 1200×630px
            </p>
          </div>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <button
            type="button"
            onClick={onCancel}
            className="px-3 py-1.5 text-gray-700 bg-gray-100 rounded hover:bg-gray-200 text-sm"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleConfirm}
            className="px-3 py-1.5 bg-violet-600 text-white rounded hover:bg-violet-700 text-sm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const FileUpload = ({ 
  onSuccess, 
  onDelete, 
  field, 
  options, 
  displayText, 
  displayPreview, 
  subtitle = null, 
  recommended = false, 
  maxDimensions,
  acceptedFileTypes = "image/jpeg, image/png, image/svg+xml" // Default to include SVG
}) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [currentFileUrl, setCurrentFileUrl] = useState(options?.[field] || '');
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef(null);
  const [fileType, setFileType] = useState('');
  const [showCropper, setShowCropper] = useState(false);
  const [tempImageUrl, setTempImageUrl] = useState(null);

  useEffect(() => {
    if (options?.[field]) {
      setCurrentFileUrl(options[field]);
      // Try to determine if the current file is an SVG
      if (options[field]?.includes('data:image/svg+xml') || options[field]?.endsWith('.svg')) {
        setFileType('svg');
      }
    }
  }, [options, field]);

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  const handleChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    // File type restriction - now including SVG
    const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
    if (!allowedTypes.includes(selectedFile.type)) {
      setErrorMessage('Invalid file type. Only JPG, PNG, and SVG are allowed.');
      return;
    }

    // File size restriction - 10MB for all images
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeInBytes) {
      setErrorMessage('File size exceeds the 10MB limit.');
      return;
    }

    try {
      setErrorMessage('');
      setUploading(true);
      
      // Check if this is an SVG file
      const isSvg = selectedFile.type === 'image/svg+xml';
      setFileType(isSvg ? 'svg' : 'raster');
      
      if (isSvg) {
        // For SVGs, we can use them directly without processing
        const reader = new FileReader();
        reader.onload = (event) => {
          setFile(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        // For cover photos, show the cropper
        if (field === 'coverPhoto') {
          const reader = new FileReader();
          reader.onload = (event) => {
            setTempImageUrl(event.target.result);
            setShowCropper(true);
          };
          reader.readAsDataURL(selectedFile);
          return;
        }

        // For other raster images, process as before
        const isGameAsset = field === 'cubeImage' || field === 'playerImage';
        const dimensions = isGameAsset 
          ? (maxDimensions || { width: 256, height: 256 })
          : { width: 1200, height: 1200 };
        
        const processedFile = await processImage(selectedFile, dimensions);
        setFile(processedFile);
      }
    } catch (error) {
      console.error('Error processing image:', error);
      setErrorMessage('Error processing image. Please try again.');
      setUploading(false);
    }
  };

  const handleUpload = () => {
    if (!file) return;

    setUploading(true);
    // Use a specific folder for game assets to keep them organized
    const folderPath = field === 'cubeImage' ? 'cubeImage' : field;
    
    // Determine file extension based on file type
    const fileExtension = fileType === 'svg' ? 'svg' : 'jpg';
    const storageReference = storageRef(storage, `${folderPath}/${Date.now()}_${file.name || `image.${fileExtension}`}`);
    
    // Convert data URL to blob for upload
    const blob = dataURLtoBlob(file);
    const uploadTask = uploadBytesResumable(storageReference, blob);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(prog);
      },
      (error) => {

        setUploading(false);
        setErrorMessage('Upload failed. Please try again.');
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        setCurrentFileUrl(url);
        handleFileUploadSuccess(url);
        setUploading(false);
      }
    );
  };

  // Helper function to convert data URL to Blob
  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleFileUploadSuccess = (url) => {
    const updatedOptions = { ...options, [field]: url };
    onSuccess(updatedOptions);
  };

  const handleDelete = () => {
    setCurrentFileUrl(null);
    setProgress(0);
    setFileType('');
    fileInputRef.current.value = null; // Reset the input field
    const updatedOptions = { ...options, [field]: null };
    onDelete(updatedOptions);
  };

  const handleCropComplete = (croppedImageUrl) => {
    setFile(croppedImageUrl);
    setShowCropper(false);
    setTempImageUrl(null);
  };

  return (
    <div className="flex flex-col items-start">
      <div className="flex items-center justify-between w-full mb-2">
        <span className="flex flex-grow flex-col">
          <span className="flex items-center gap-2">
            <span className="text-sm font-medium leading-6 text-gray-900">
              {displayText}
            </span>
            {recommended && (
              <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Recommended
              </span>
            )}
          </span>
          {subtitle && (
            <span className="text-sm text-gray-500">
              {subtitle}
            </span>
          )}
        </span>
      </div>

      {currentFileUrl && displayPreview && (
        <div className="relative mb-6 w-full">
          <div className={`relative rounded-lg overflow-hidden ${
            field === 'cubeImage' || field === 'playerImage' 
              ? 'w-32 h-32' 
              : field === 'coverPhoto'
                ? 'w-full aspect-[1.91/1] bg-gray-50'
                : 'w-full'
          }`}>
            <img
              src={currentFileUrl}
              alt="Uploaded file"
              className={`${
                field === 'cubeImage' || field === 'playerImage' 
                  ? 'w-full h-full object-contain bg-gray-100' 
                  : field === 'coverPhoto'
                    ? 'w-full h-full object-cover'
                    : 'w-full h-full object-cover'
              } rounded-lg`}
            />
            <div className="absolute top-2 right-2 flex gap-2 z-10">
              <button
                type="button"
                className="p-2 bg-white/90 backdrop-blur-sm rounded-lg shadow-sm text-gray-600 hover:text-gray-900 transition-colors"
                onClick={() => fileInputRef.current.click()}
              >
                <PencilIcon className="h-4 w-4" />
              </button>
              <button
                type="button"
                className="p-2 bg-white/90 backdrop-blur-sm rounded-lg shadow-sm text-red-500 hover:text-red-700 transition-colors"
                onClick={handleDelete}
              >
                <TrashIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      )}

      {(!currentFileUrl || !displayPreview) && (
        <div 
          className={`relative z-0 w-full mt-2 flex justify-center rounded-lg border-2 border-dashed border-gray-300 px-6 py-10 cursor-pointer hover:border-gray-400 transition-colors ${
            field === 'cubeImage' || field === 'playerImage' ? 'max-w-xs' : ''
          }`}
          onClick={() => fileInputRef.current.click()}
        >
          <div className="text-center">
            <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
            <div className="mt-4 flex flex-col items-center">
              <button
                type="button"
                className="inline-flex items-center gap-1.5 rounded-md bg-gray-50 text-xs px-3.5 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 transition-colors"
                onClick={(e) => {
                  e.stopPropagation();
                  fileInputRef.current.click();
                }}
              >
                <ArrowUpTrayIcon className="h-4 w-4 text-gray-600" />
                Upload Image
              </button>
              <p className="mt-1 text-xs text-gray-500">
                {field === 'coverPhoto' 
                  ? 'Recommended: 1200×630px (1.91:1 ratio)'
                  : field === 'playerImage'
                    ? `SVG or PNG with transparency works best (${maxDimensions?.width || 240}x${maxDimensions?.height || 240}px)`
                    : field === 'cubeImage'
                      ? `PNG with transparency works best (${maxDimensions?.width || 256}x${maxDimensions?.height || 256}px)`
                      : 'JPG, PNG, SVG up to 10MB'}
              </p>
            </div>
          </div>
        </div>
      )}

      <input
        id={`${field}-upload`}
        name={`${field}-upload`}
        type="file"
        className="sr-only"
        ref={fileInputRef}
        onChange={handleChange}
        accept={acceptedFileTypes}
      />
      
      {uploading && (
        <div className="w-full bg-gray-200 rounded-full h-1.5 mt-2">
          <div
            className="bg-violet-500 h-1.5 rounded-full transition-all duration-500 ease-in-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}
      
      {errorMessage && (
        <div className="mt-2 text-sm text-red-600">
          {errorMessage}
        </div>
      )}

      {showCropper && tempImageUrl && (
        <ImageCropper
          imageUrl={tempImageUrl}
          onCropComplete={handleCropComplete}
          onCancel={() => {
            setShowCropper(false);
            setTempImageUrl(null);
            fileInputRef.current.value = null;
          }}
        />
      )}
    </div>
  );
};

export default FileUpload;