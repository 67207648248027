import React from 'react';
import StartCard from '../games/StartCard';
import { getGameTypeColor } from './CreateCampaign';
import { getStylePresets } from './stylePresets';
import { SparklesIcon } from '@heroicons/react/24/outline';

// Move hexToRgb outside component and add error handling
const hexToRgb = (hex) => {
  try {
    if (!hex || typeof hex !== 'string') return '0, 0, 0';
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return isNaN(r) || isNaN(g) || isNaN(b) ? '0, 0, 0' : `${r}, ${g}, ${b}`;
  } catch (error) {
    console.error('Error in hexToRgb:', error);
    return '0, 0, 0';
  }
};

const DEFAULT_THEME = {
  colors: {
    primary: '#7C3AED',
    accent: '#4B5563',
  },
  typography: {
    primaryFont: 'Inter',
    secondaryFont: 'Inter'
  }
};

const QuickStyleSelector = ({ 
  selectedStyle, 
  onStyleSelect, 
  businessProfile, 
  formData,
  title,
  description
}) => {
   

    // Get theme with fallbacks
    const theme = businessProfile?.theme || DEFAULT_THEME;

    let gameTypeColor;
    try {
      if (formData?.gameType?.id) {
        gameTypeColor = getGameTypeColor(formData.gameType.id);
      } else {
        gameTypeColor = theme.colors.primary;
      }
    } catch (error) {
      console.error('Error getting gameTypeColor:', error);
      gameTypeColor = theme.colors.primary;
    }

    const isMapGame = ['pindrop', 'mapselect'].includes(formData?.gameType?.id);
  
    const stylePresets = React.useMemo(() => {
      try {
        const primaryColor = theme.colors.primary;
        const accentColor = theme.colors.accent;
        
        return {
          default: {
            colors: {
              background: { gradient: { start: '#F3F4F6', end: '#E5E7EB' } },
              cardBackground: '#FFFFFF',
              cardOpacity: 0.9,
              titleText: '#1F2937',
              subtitleText: '#4B5563',
              buttonBackground: primaryColor,
              buttonOpacity: 1,
              buttonText: '#FFFFFF'
            }
          },
          brand: {
            colors: {
              background: { 
                gradient: { 
                  start: primaryColor, 
                  end: primaryColor 
                } 
              },
              cardBackground: '#FFFFFF',
              cardOpacity: 0.95,
              titleText: '#1F2937',
              subtitleText: '#4B5563',
              buttonBackground: primaryColor,
              buttonOpacity: 1,
              buttonText: '#FFFFFF'
            }
          },
          transparent: {
            colors: {
              background: { gradient: { start: '#1F2937', end: '#111827' } },
              cardBackground: '#1F2937',
              cardOpacity: 0.6,
              titleText: '#FFFFFF',
              subtitleText: '#F3F4F6',
              buttonBackground: '#FFFFFF',
              buttonOpacity: 0.9,
              buttonText: '#1F2937'
            }
          },
          dark: {
            colors: {
              background: { gradient: { start: '#111827', end: '#030712' } },
              cardBackground: '#1F2937',
              cardOpacity: 0.95,
              titleText: '#FFFFFF',
              subtitleText: '#E5E7EB',
              buttonBackground: '#374151',
              buttonOpacity: 1,
              buttonText: '#FFFFFF'
            }
          }
        };
      } catch (error) {
        console.error('Error creating stylePresets:', error);
        return {
          default: {
            colors: {
              background: { gradient: { start: '#F3F4F6', end: '#E5E7EB' } },
              cardBackground: '#FFFFFF',
              cardOpacity: 0.9,
              titleText: '#1F2937',
              subtitleText: '#4B5563',
              buttonBackground: gameTypeColor,
              buttonOpacity: 1,
              buttonText: '#FFFFFF'
            }
          }
        };
      }
    }, [gameTypeColor, theme]);

    // Ensure we have a valid selected style
    const validStyle = stylePresets[selectedStyle] ? selectedStyle : 'default';

    const getBackgroundStyle = (style, theme) => {
      if (isMapGame) {
        return {
          background: 'linear-gradient(to bottom, #F3F4F6, #E5E7EB)',
          backgroundImage: `url(/GameTypeImages/mapbg.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        };
      }
      
      const colors = theme?.colors || {};
      const gradientStart = colors?.background?.gradient?.start || '#F3F4F6';
      const gradientEnd = colors?.background?.gradient?.end || '#E5E7EB';
      
      return {
        background: `linear-gradient(to bottom, ${gradientStart}, ${gradientEnd})`
      };
    };

    const renderStylePreview = (style, colors) => {
      const backgroundStyle = getBackgroundStyle(style, { colors });

      return (
        <div 
          key={style}
          className={`relative rounded-lg overflow-hidden cursor-pointer transition-all duration-200
            ${selectedStyle === style ? 'ring-2 ring-indigo-500 scale-[1.02]' : 'hover:scale-[1.02]'}`}
          onClick={() => onStyleSelect(style)}
        >
          <div className="aspect-[3/4] relative" style={backgroundStyle}>
            <div className="absolute inset-0 flex items-center justify-center">
              <div 
                className="w-[85%] max-w-[200px] rounded shadow-xl transform transition-transform duration-200" 
                style={{ 
                  backgroundColor: colors.cardBackground ? 
                    `rgba(${hexToRgb(colors.cardBackground)}, ${colors.cardOpacity || 1})` : 
                    'rgba(255, 255, 255, 0.9)',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                }}
              >
                <div className="relative p-3">
                  <div className="max-w-[200px]">
                    <h3 
                      style={{ color: colors.titleText }} 
                      className="text-[10px] sm:text-xs font-semibold tracking-tight truncate"
                    >
                      {title || 'Example Title'}
                    </h3>
                    <p 
                      style={{ color: colors.subtitleText }} 
                      className="text-[8px] sm:text-[10px] mt-0.5 font-medium opacity-90 truncate"
                    >
                      {description || 'Example description text'}
                    </p>
                    <button
                      className="w-full mt-1.5 sm:mt-2 px-2 py-1 rounded text-[8px] sm:text-[10px] font-semibold shadow-sm transition-all duration-200"
                      style={{ 
                        backgroundColor: colors.buttonBackground,
                        color: colors.buttonText,
                        opacity: colors.buttonOpacity || 1,
                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
                      }}
                    >
                      Start
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute bottom-2 left-2">
            <span className="inline-flex items-center rounded-md bg-black/40 px-2 py-1">
              <p className="text-white text-xs sm:text-sm font-medium capitalize">{style}</p>
            </span>
          </div>
        </div>
      );
    };

    return (
      <div className="mt-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg p-3">
          <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Choose Style</h3>
          <div className="mt-2 dark:bg-gray-900 rounded-lg pb-3 flex items-center space-x-2">
            <SparklesIcon className="h-4 w-4 text-gray-500 dark:text-gray-400 hidden lg:block" />
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Don't worry! You can customize all content, colors and settings in the editor after creating your campaign.
            </p>
          </div>

          <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4">
            {Object.entries(stylePresets).map(([style, theme]) => {
              const colors = theme?.colors || {};
              return renderStylePreview(style, colors);
            })}
          </div>
        </div>
      </div>
    );
};

export default QuickStyleSelector; 