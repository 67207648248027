import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Determine if the app is in development mode
const isDevelopment = process.env.NODE_ENV === 'development';

// Add error handling for chunk loading
window.addEventListener('error', (event) => {
  if (event.message.includes('Loading chunk') && event.message.includes('failed')) {
    // Force a hard refresh to clear cache and reload chunks
    window.location.reload(true);
  }
});

// Add error boundary for chunk loading errors
const handleChunkError = (error) => {
  if (error.name === 'ChunkLoadError') {
    window.location.reload(true);
  }
};

window.addEventListener('unhandledrejection', (event) => {
  if (event.reason && event.reason.name === 'ChunkLoadError') {
    window.location.reload(true);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  isDevelopment ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  )
);