import React from 'react';
import Logo from "../images/logo.png";
import TransparentLogo from "../images/Transparent Logo.png";

const SmilesFlowLink = ({ isVisible, gameName = "game", theme }) => {
  if (!isVisible) {
    return null;
  }

  // Create a clean game name for the URL (lowercase, no spaces)
  const gameNameParam = gameName?.toLowerCase().replace(/\s+/g, '-') || 'game';

  // Determine if we should use light or dark text based on background color
  const isDarkBackground = theme?.colors?.cardBackground ? 
    isColorDark(theme.colors.cardBackground) : false;
  
  const textColorClass = isDarkBackground ? "text-gray-300" : "text-gray-500";
  // Use the transparent white logo for dark backgrounds
  const logoSrc = isDarkBackground ? TransparentLogo : Logo;

  return (
    <div className={`mt-6 text-center ${textColorClass} flex items-center justify-center`} style={{fontSize: '0.5rem'}}>
      <a 
        href={`https://smilesflow.com?utm_source=game&game=${gameNameParam}`}
        className="flex items-center" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <span>Created on</span>
        <img src={logoSrc} alt="Logo" className="inline-block w-16 ml-1" />
      </a>
    </div>
  );
};

// Helper function to determine if a color is dark
const isColorDark = (hexColor) => {
  // Remove the # if it exists
  hexColor = hexColor.replace('#', '');
  
  // Parse the hex values to RGB
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);
  
  // Calculate the brightness (using the formula for relative luminance)
  // https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  
  // Return true if the color is dark (brightness less than 128)
  return brightness < 128;
};

export default SmilesFlowLink;