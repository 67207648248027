import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Import Firestore functions

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPlan, setCurrentPlan] = useState(null); // Initialize as null
  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore(); // Initialize Firestore

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        try {
          await currentUser.getIdToken(true);
          const decodedToken = await currentUser.getIdTokenResult();
          let plan = decodedToken.claims.stripeRole || 'free';

          if (plan === 'free') {
            // Check user document for trial end date
            const userDocRef = doc(db, 'users', currentUser.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
              const trialEndDate = userDoc.data().trialEndDate;
              if (trialEndDate && new Date(trialEndDate) < new Date()) {
                plan = 'expired'; // Set plan to 'expired' if trial has ended
              }
            }
          }


          setCurrentPlan(plan);
        } catch (error) {
          console.error("Error checking plan status:", error);
        }
      } else {
        if (location.pathname !== '/games') {
          navigate("/login");
        }
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [navigate, location.pathname, db]); // Add db to dependencies

  const isFreeUser = currentPlan === 'free';

  return { user, loading, currentPlan, isFreeUser };
};

export default useAuth;
