import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUser } from './userContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';

const ClientContext = createContext(null);

export function ClientProvider({ children }) {
  const { businessProfile } = useUser();
  const [activeClient, setActiveClient] = useState(null);
  const [clients, setClients] = useState([]);

  const fetchClients = async () => {
    if (businessProfile?.id) {
      const clientsQuery = query(
        collection(db, 'company'),
        where('parentCompany', '==', businessProfile.id),
        where('type', '==', 'client')
      );
      const querySnapshot = await getDocs(clientsQuery);
      const clientsList = querySnapshot.docs.map(doc => ({ 
        id: doc.id, 
        ...doc.data() 
      }));
      setClients(clientsList);
    }
  };

  useEffect(() => {
    fetchClients();
  }, [businessProfile]);

  const value = {
    activeClient,
    setActiveClient,
    clients,
    refreshClients: fetchClients
  };

  return (
    <ClientContext.Provider value={value}>
      {children}
    </ClientContext.Provider>
  );
}

export function useClient() {
  const context = useContext(ClientContext);
  if (context === undefined || context === null) {
    throw new Error('useClient must be used within a ClientProvider');
  }
  return context;
} 