import React, { useState, useEffect, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import DefaultLoading from '../common/DefaultLoading';
import WebFont from 'webfontloader';
import { decode } from 'js-base64';

const Play = ({ previewData, isPreview }) => {
  const { id } = useParams();
  const [gameData, setGameData] = useState(previewData);
  const [GameComponent, setGameComponent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadGameComponent = async () => {
      try {
        let data = previewData;
        if (!isPreview) {
          // Check URL for preview data
          const urlParams = new URLSearchParams(window.location.search);
          const previewParam = urlParams.get('preview');
          
          if (previewParam) {
            // Decode preview data from URL
            data = JSON.parse(decode(previewParam));
          } else {
            // Load from database if no preview data
            const docRef = doc(db, 'campaign', id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              data = docSnap.data();
            } else {
          
              setLoading(false);
              return;
            }
          }
        }

        // Add the ID to the game data
        const gameDataWithId = {
          ...data,
          id: id || previewData?.id || 'preview'
        };

        // Set game data with ID
        setGameData(gameDataWithId);

        // Load game component
        if (data.gameType) {
          const importedComponent = React.lazy(() =>
            import(`../games/${data.gameType}/Main`)
          );
          setGameComponent(importedComponent);
        }
      } catch (error) {
        console.error('Error loading game:', error);
      } finally {
        setLoading(false);
      }
    };

    loadGameComponent();
  }, [previewData, isPreview, id]);

  // Add message listener for preview updates
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'PREVIEW_UPDATE') {
        // Add ID to preview updates as well
        setGameData({
          ...event.data.gameData,
          id: id || 'preview'
        });
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [id]);

  if (loading) {
    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <DefaultLoading />
      </div>
    );
  }

  if (!gameData || !GameComponent) {
    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <div>Game not found.</div>
      </div>
    );
  }

  return (
    <Suspense 
      fallback={
        <div className="absolute inset-0 flex items-center justify-center">
          <DefaultLoading />
        </div>
      }
    >
      <div 
        className="absolute inset-0 flex items-center justify-center"
        style={{
          fontFamily: gameData?.options?.font || 'inherit',
          overflow: 'hidden',
        }}
      >
        <div className="relative w-full h-full">
          <GameComponent gameData={gameData} />
        </div>
      </div>
    </Suspense>
  );
};

export default Play;