import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth, db, googleProvider } from "../firebase";
import { createUserWithEmailAndPassword, sendEmailVerification, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { doc, setDoc, addDoc, collection, getDoc, query, where, getDocs } from "firebase/firestore";
import Logo from "../images/logo.png";
import AlertError from "./PermissionDenied";
import { useUser } from "../userContext"; // Import the useUser hook
import { Transition } from "@headlessui/react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

const Signup = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [notice, setNotice] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const { loading: userLoading, userProfile, businessProfile } = useUser(); // Access the loading state from userContext
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const [showPersonalFields, setShowPersonalFields] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordFeedback, setPasswordFeedback] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [showEmailForm, setShowEmailForm] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && !isProcessing && !userLoading && userProfile && businessProfile) {
                // If the user is logged in and user data is fully loaded, navigate
                navigate("/");
            }
        });

        return () => unsubscribe();
    }, [navigate, isProcessing, userLoading, userProfile, businessProfile]);

    useEffect(() => {
        setNotice("");
    }, [email, password, confirmPassword, firstName, lastName, companyName]);

    useEffect(() => {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setIsEmailValid(isValidEmail);
    }, [email]);

    useEffect(() => {
        if (confirmPassword) {
            setPasswordsMatch(password === confirmPassword);
        }
    }, [password, confirmPassword]);

    const isFormValid = email && password && confirmPassword && firstName && lastName;

    const getErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 'auth/email-already-in-use':
                return 'An account already exists with this email. Please sign in instead.';
            case 'auth/invalid-email':
                return 'The email address is not valid.';
            case 'auth/operation-not-allowed':
                return 'Email/password accounts are not enabled.';
            case 'auth/weak-password':
                return 'The password is too weak.';
            case 'auth/user-disabled':
                return 'The user account has been disabled by an administrator.';
            case 'auth/user-not-found':
                return 'No user found with this email.';
            case 'auth/wrong-password':
                return 'The password is invalid.';
            case 'auth/account-exists-with-different-credential':
                return 'An account already exists with this email. Please sign in with email and password instead.';
            case 'auth/popup-closed-by-user':
                return 'Sign up was cancelled. Please try again.';
            default:
                return 'Sorry, something went wrong. Please try again.';
        }
    };

    const signupWithUsernameAndPassword = async (e) => {
        e.preventDefault();

        if (password === confirmPassword) {
            setIsProcessing(true);

            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;


                const businessRef = await addDoc(collection(db, "company"), {
                    ownerId: user.uid,
                    name: companyName || "",
                    createdAt: new Date(),
                });
   

                // Create user document BEFORE sending welcome email
                await setDoc(doc(db, "users", user.uid), {
                    uid: user.uid,
                    email: user.email,
                    firstName,
                    lastName,
                    companyId: businessRef.id,
                    createdAt: new Date(),
                });
               

                // Add a small delay to ensure Firestore is consistent
                await new Promise(resolve => setTimeout(resolve, 1000));

                // Send welcome email
                try {
                    const sendWelcomeEmail = httpsCallable(
                        functions, 
                        'sendWelcomeEmail',
                        { region: 'us-central1' } // Add this options object
                    );
                    const result = await sendWelcomeEmail({ userId: user.uid });
                 
                } catch (emailError) {
                    console.error('Welcome email error:', emailError);
                    // Don't throw here - we still want to complete signup
                }

                setTimeout(() => {
                    setIsProcessing(false);
                }, 2000);

            } catch (error) {
                console.error('Signup error:', error);
                const errorMessage = getErrorMessage(error.code);
                setNotice(errorMessage);
                setIsProcessing(false);
            }
        } else {
            setNotice("Passwords don't match. Please try again.");
            setIsProcessing(false);
        }
    };

    const handleContinueToPassword = () => {
        if (firstName && lastName) {
            setShowPersonalFields(false);
            setTimeout(() => {
                setShowPasswordFields(true);
            }, 300);
        }
    };

    const checkPasswordStrength = (password) => {
        let strength = 0;
        let feedback = [];

        if (password.length >= 8) {
            strength += 1;
        } else {
            feedback.push('At least 8 characters');
        }
        if (/[A-Z]/.test(password)) {
            strength += 1;
        } else {
            feedback.push('One uppercase letter');
        }
        if (/[a-z]/.test(password)) {
            strength += 1;
        } else {
            feedback.push('One lowercase letter');
        }
        if (/[0-9]/.test(password)) {
            strength += 1;
        } else {
            feedback.push('One number');
        }
        if (/[^A-Za-z0-9]/.test(password)) {
            strength += 1;
        } else {
            feedback.push('One special character');
        }

        setPasswordStrength(strength);
        setPasswordFeedback(feedback.join(' • '));
    };

    useEffect(() => {
        if (password) {
            checkPasswordStrength(password);
        } else {
            setPasswordStrength(0);
            setPasswordFeedback('');
        }
    }, [password]);

    const handleGoogleSignup = async () => {
        setIsProcessing(true);
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            
            // Check if user document exists
            const userDoc = await getDoc(doc(db, "users", user.uid));
            
            if (!userDoc.exists()) {
                try {
                    // Extract name from Google profile
                    const nameParts = user.displayName ? user.displayName.split(' ') : ['', ''];
                    const googleFirstName = nameParts[0] || '';
                    const googleLastName = nameParts.slice(1).join(' ') || '';

                    // Create business document
                    const businessRef = await addDoc(collection(db, "company"), {
                        ownerId: user.uid,
                        name: "",
                        createdAt: new Date(),
                    });

                    // Create user document
                    await setDoc(doc(db, "users", user.uid), {
                        uid: user.uid,
                        email: user.email,
                        firstName: googleFirstName,
                        lastName: googleLastName,
                        companyId: businessRef.id,
                        createdAt: new Date(),
                        authProvider: "google",
                        emailVerified: true  // Google emails are pre-verified
                    });

                    // Ensure Firebase Auth user is marked as verified
                    if (!auth.currentUser.emailVerified) {
                        await auth.currentUser.reload();
                    }

                    // Add a small delay to ensure Firestore is consistent
                    await new Promise(resolve => setTimeout(resolve, 1000));

                    // Send welcome email
                    try {
                        const sendWelcomeEmail = httpsCallable(
                            functions,
                            'sendWelcomeEmail',
                            { region: 'us-central1' }
                        );
                        await sendWelcomeEmail({ userId: user.uid });
                    } catch (emailError) {
                        console.error('Welcome email error:', emailError);
                        // Continue with signup even if welcome email fails
                    }
                } catch (dbError) {
                    console.error("Database error:", dbError);
                    await auth.signOut();
                    setNotice("Failed to create account. Please try again or contact support.");
                    setIsProcessing(false);
                    return;
                }
            } else {
                // User exists, ensure emailVerified is set to true
                const userData = userDoc.data();
                if (!userData.emailVerified) {
                    await setDoc(doc(db, "users", user.uid), {
                        ...userData,
                        emailVerified: true
                    }, { merge: true });
                }
                // Ensure Firebase Auth user is marked as verified
                if (!auth.currentUser.emailVerified) {
                    await auth.currentUser.reload();
                }
            }
            // If user exists or was created successfully, the auth state listener will handle navigation
            setIsProcessing(false);
        } catch (error) {
            console.error('Google signup error:', error);
            let errorMessage;
            
            // Immediately stop processing for popup-related errors
            if (error.code === 'auth/popup-closed-by-user' || 
                error.code === 'auth/cancelled-popup-request' || 
                error.code === 'auth/popup-blocked') {
                setIsProcessing(false);
                switch (error.code) {
                    case 'auth/popup-closed-by-user':
                        errorMessage = "Sign-up cancelled. Please try again.";
                        break;
                    case 'auth/cancelled-popup-request':
                        errorMessage = "Only one popup can be open at a time. Please try again.";
                        break;
                    case 'auth/popup-blocked':
                        errorMessage = "Popup was blocked by your browser. Please enable popups for this site.";
                        break;
                }
                setNotice(errorMessage);
                return;
            }
            
            // Handle other errors
            switch (error.code) {
                case 'auth/account-exists-with-different-credential':
                    // With account linking enabled, this error shouldn't occur often
                    errorMessage = "An account with this email already exists. Please sign in with your email and password.";
                    if (error.email) {
                        setTimeout(() => {
                            navigate(`/login?email=${encodeURIComponent(error.email)}`);
                        }, 2000);
                    }
                    break;
                case 'auth/email-already-in-use':
                    // This might occur if the email is already in use but not yet linked
                    errorMessage = "An account with this email already exists. Please sign in with your email and password.";
                    if (error.email) {
                        setTimeout(() => {
                            navigate(`/login?email=${encodeURIComponent(error.email)}`);
                        }, 2000);
                    }
                    break;
                default:
                    errorMessage = "An error occurred during sign up. Please try again.";
            }
            
            setNotice(errorMessage);
            // Ensure we're signed out in case of error
            try {
                await auth.signOut();
            } catch (signOutError) {
                console.error("Error signing out:", signOutError);
            }
            setIsProcessing(false);
        }
    };

    return (
        <main className="dark:bg-gray-900">
            <div className="relative flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/2">
                    <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
                        <div className="flex-1">
                            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                                <a href="https://www.smilesflow.com" target="_blank" rel="noopener noreferrer">
                                    <img alt="SmilesFlow" src={Logo} className="h-8" />
                                </a>
                                <div className="text-sm">
                                    Already got an account?{' '}
                                    <Link to="/login" className="font-medium text-violet-500 hover:text-violet-600">
                                        Sign in
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="px-8 py-8 sm:px-6 sm:py-10">
                            <div className="max-w-md mx-auto">
                                <h2 className="text-2xl font-bold mb-2">Try Free for 7 Days</h2>
                                <p className="text-gray-600 mb-8">Set up in minutes. Cancel anytime.</p>

                                {notice && <div className="mb-6"><AlertError text={notice} dashboardButton={false} /></div>}

                                {!showEmailForm ? (
                                    <div className="space-y-6">
                                        <div>
                                            <button
                                                onClick={handleGoogleSignup}
                                                disabled={isProcessing}
                                                className="w-full flex items-center justify-center gap-3 px-4 py-2.5 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
                                            >
                                                {isProcessing ? (
                                                    <span className="flex items-center">
                                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        Processing...
                                                    </span>
                                                ) : (
                                                    <>
                                                        <svg className="h-5 w-5" viewBox="0 0 24 24">
                                                            <path
                                                                fill="#4285F4"
                                                                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                                            />
                                                            <path
                                                                fill="#34A853"
                                                                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                                            />
                                                            <path
                                                                fill="#FBBC05"
                                                                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                                            />
                                                            <path
                                                                fill="#EA4335"
                                                                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                                            />
                                                        </svg>
                                                        Continue with Google
                                                    </>
                                                )}
                                            </button>
                                        </div>

                                        <div className="relative">
                                            <div className="absolute inset-0 flex items-center">
                                                <div className="w-full border-t border-gray-300"></div>
                                            </div>
                                            <div className="relative flex justify-center text-sm">
                                                <span className="px-2 bg-white text-gray-500">Or</span>
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                onClick={() => setShowEmailForm(true)}
                                                className="w-full flex items-center justify-center px-4 py-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
                                            >
                                                Continue with Email
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <form onSubmit={signupWithUsernameAndPassword} method="POST" className="space-y-6">
                                        <div className="space-y-6">
                                            <div>
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                                    Email address
                                                </label>
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    required
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className={`w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1 ring-inset 
                                                        ${email && !isEmailValid 
                                                            ? 'ring-red-300 focus:ring-red-500' 
                                                            : 'ring-gray-300 focus:ring-violet-500'} 
                                                        transition-all duration-200`}
                                                    placeholder="name@company.com"
                                                />
                                                {email && !isEmailValid && (
                                                    <p className="mt-1 text-xs text-red-500">
                                                        Please enter a valid email address
                                                    </p>
                                                )}
                                            </div>

                                            <div className="flex space-x-4">
                                                <div className="w-1/2">
                                                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                                                        First Name
                                                    </label>
                                                    <input
                                                        id="firstName"
                                                        name="firstName"
                                                        type="text"
                                                        value={firstName}
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        required
                                                        className="w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-violet-500 transition-all duration-200"
                                                    />
                                                </div>
                                                <div className="w-1/2">
                                                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                                                        Last Name
                                                    </label>
                                                    <input
                                                        id="lastName"
                                                        name="lastName"
                                                        type="text"
                                                        value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)}
                                                        required
                                                        className="w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-violet-500 transition-all duration-200"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-1">
                                                    Company Name (Optional)
                                                </label>
                                                <input
                                                    id="companyName"
                                                    name="companyName"
                                                    type="text"
                                                    value={companyName}
                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                    className="w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-violet-500 transition-all duration-200"
                                                    placeholder="Your company name"
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                                                    Password
                                                </label>
                                                <div className="relative">
                                                    <input
                                                        id="password"
                                                        name="password"
                                                        type="password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        value={password}
                                                        required
                                                        className="w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-violet-500 transition-all duration-200"
                                                        placeholder="Create a secure password"
                                                    />
                                                    {password && (
                                                        <div className="mt-2">
                                                            <div className="flex space-x-1 mb-1">
                                                                {[...Array(5)].map((_, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className={`h-1 flex-1 rounded-full transition-all duration-200 ${
                                                                            index < passwordStrength 
                                                                                ? passwordStrength <= 2 
                                                                                    ? 'bg-red-500' 
                                                                                    : passwordStrength <= 3 
                                                                                        ? 'bg-yellow-500' 
                                                                                        : 'bg-green-500'
                                                                                : 'bg-gray-200'
                                                                        }`}
                                                                    />
                                                                ))}
                                                            </div>
                                                            <p className={`text-xs ${
                                                                passwordStrength <= 2 
                                                                    ? 'text-red-500' 
                                                                    : passwordStrength <= 3 
                                                                        ? 'text-yellow-500' 
                                                                        : 'text-green-500'
                                                            }`}>
                                                                {passwordFeedback || 'Strong password'}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div>
                                                <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700 mb-1">
                                                    Confirm password
                                                </label>
                                                <div className="relative">
                                                    <input
                                                        id="confirm-password"
                                                        name="confirm-password"
                                                        type="password"
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        required
                                                        className={`w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1 ring-inset 
                                                            ${confirmPassword && !passwordsMatch 
                                                                ? 'ring-red-300 focus:ring-red-500' 
                                                                : confirmPassword && passwordsMatch
                                                                    ? 'ring-green-300 focus:ring-green-500'
                                                                    : 'ring-gray-300 focus:ring-violet-500'} 
                                                            transition-all duration-200`}
                                                        placeholder="Confirm your password"
                                                    />
                                                    {confirmPassword && (
                                                        <div className="absolute right-3 top-2.5">
                                                            {passwordsMatch ? (
                                                                <svg className="h-5 w-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                                </svg>
                                                            ) : (
                                                                <svg className="h-5 w-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                                </svg>
                                                            )}
                                                        </div>
                                                    )}
                                                    {confirmPassword && !passwordsMatch && (
                                                        <p className="mt-1 text-xs text-red-500">
                                                            Passwords don't match
                                                        </p>
                                                    )}
                                                    {confirmPassword && passwordsMatch && (
                                                        <p className="mt-1 text-xs text-green-500">
                                                            Passwords match
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            <button
                                                type="submit"
                                                disabled={!isFormValid || isProcessing}
                                                className="flex w-full justify-center rounded-md bg-violet-500 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-600 focus:ring-2 focus:ring-violet-500 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                                            >
                                                {isProcessing ? 'Creating account...' : 'Create Account'}
                                            </button>

                                            <div className="flex items-center justify-center">
                                                <button
                                                    type="button"
                                                    onClick={() => setShowEmailForm(false)}
                                                    className="text-sm text-violet-600 hover:text-violet-500"
                                                >
                                                    Back to Google sign-up
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-1/2 bg-gradient-to-br from-violet-400 via-violet-500 to-indigo-600 text-white">
                    <div className="h-full flex flex-col justify-center p-6 lg:p-12">
                        <div className="max-w-lg mx-auto">
                            <h3 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6">Why businesses trust SmilesFlow</h3>
                            
                            <div className="space-y-4">
                                <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
                                    <p className="text-sm lg:text-base italic mb-3">
                                        "SmilesFlow allowed us to create daily golf-based content for our socials. We have done all sorts from name the golf course from above, to guessing the golfer based on hints."
                                    </p>
                                    <div className="flex items-center">
                                        <div className="w-8 h-8 bg-violet-200 rounded-full flex items-center justify-center">
                                            <span className="text-violet-700 font-semibold text-sm">R</span>
                                        </div>
                                        <div className="ml-3">
                                            <p className="font-semibold text-sm">Rob</p>
                                            <p className="text-xs text-violet-200">Founder at Roballs</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
                                    <p className="text-sm lg:text-base italic mb-3">
                                        "Our business is small and local. The ability to create a game based on the local area that we can email out to drive our known customers to our store is unbelievable."
                                    </p>
                                    <div className="flex items-center">
                                        <div className="w-8 h-8 bg-violet-200 rounded-full flex items-center justify-center">
                                            <span className="text-violet-700 font-semibold text-sm">AH</span>
                                        </div>
                                        <div className="ml-3">
                                            <p className="font-semibold text-sm">Amy Hahn</p>
                                            <p className="text-xs text-violet-200">Founder at Smith Deli</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
                                    <p className="text-sm lg:text-base italic mb-3">
                                        "The ability to generate custom word games and quizzes tailored to our audience has been invaluable. Our followers love the new content, and we've seen a significant boost in both social media engagement and blog traffic."
                                    </p>
                                    <div className="flex items-center">
                                        <div className="w-8 h-8 bg-violet-200 rounded-full flex items-center justify-center">
                                            <span className="text-violet-700 font-semibold text-sm">SL</span>
                                        </div>
                                        <div className="ml-3">
                                            <p className="font-semibold text-sm">Sarah L.</p>
                                            <p className="text-xs text-violet-200">Marketing Director at WordPlay Media</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6 text-center">
                                <p className="text-xs text-violet-200">
                                    Join hundreds of businesses already using SmilesFlow to improve their social engagement
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Signup;