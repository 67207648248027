import React, { useState, useEffect } from "react";
import { auth, db, googleProvider } from "../firebase";
import { signInWithEmailAndPassword, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc, addDoc, collection, query, where, getDocs } from "firebase/firestore";
import Logo from "../images/logo.png";
import AlertError from "./PermissionDenied";
import PasswordResetModal from "./PasswordReset";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notice, setNotice] = useState("");
  const [showResetModal, setShowResetModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);

  // Check if the user is already logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    setNotice("");
  }, [email, password]);

  useEffect(() => {
    // Check for email parameter in URL
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
      // Clean up the URL
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "auth/user-not-found":
        return "No user found with this email.";
      case "auth/wrong-password":
        return "The password is invalid.";
      case "auth/user-disabled":
        return "The user account has been disabled by an administrator.";
      case "auth/account-exists-with-different-credential":
        return "An account already exists with this email. Please sign in with email and password instead.";
      case "auth/email-already-in-use":
        return "An account already exists with this email. Please sign in with email and password instead.";
      case "auth/popup-closed-by-user":
        return "Sign in was cancelled. Please try again.";
      default:
        return "Invalid email or password. Please try again.";
    }
  };

  const handleGoogleLogin = async () => {
    setIsProcessing(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Check if user document exists
      const userDoc = await getDoc(doc(db, "users", user.uid));
      
      if (!userDoc.exists()) {
        // Create new user document
        try {
          const nameParts = user.displayName ? user.displayName.split(' ') : ['', ''];
          const googleFirstName = nameParts[0] || '';
          const googleLastName = nameParts.slice(1).join(' ') || '';

          // Create business document
          const businessRef = await addDoc(collection(db, "company"), {
            ownerId: user.uid,
            name: "",
            createdAt: new Date(),
          });

          // Create user document
          await setDoc(doc(db, "users", user.uid), {
            uid: user.uid,
            email: user.email,
            firstName: googleFirstName,
            lastName: googleLastName,
            companyId: businessRef.id,
            createdAt: new Date(),
            authProvider: "google",
            emailVerified: true  // Google emails are pre-verified
          });

          // Ensure Firebase Auth user is marked as verified
          if (!auth.currentUser.emailVerified) {
            await auth.currentUser.reload();
          }

          sessionStorage.setItem("Auth Token", result._tokenResponse.refreshToken);
        } catch (dbError) {
          console.error("Database error:", dbError);
          await auth.signOut();
          setNotice("Failed to create account. Please try again or contact support.");
          setIsProcessing(false);
          return;
        }
      } else {
        // User exists, ensure emailVerified is set to true
        const userData = userDoc.data();
        if (!userData.emailVerified) {
          await setDoc(doc(db, "users", user.uid), {
            ...userData,
            emailVerified: true
          }, { merge: true });
        }
        // Ensure Firebase Auth user is marked as verified
        if (!auth.currentUser.emailVerified) {
          await auth.currentUser.reload();
        }
        sessionStorage.setItem("Auth Token", result._tokenResponse.refreshToken);
      }
    } catch (error) {
      console.error("Error signing in with Google: ", error);
      let errorMessage;
      
      switch (error.code) {
        case 'auth/account-exists-with-different-credential':
          // With account linking enabled, this error shouldn't occur
          // but we'll handle it just in case
          errorMessage = "Please try signing in with your email and password first, then link your Google account.";
          if (error.email) {
            setEmail(error.email);
          }
          break;
        case 'auth/popup-closed-by-user':
          errorMessage = "Sign-in cancelled. Please try again.";
          break;
        case 'auth/cancelled-popup-request':
          errorMessage = "Only one popup can be open at a time. Please try again.";
          break;
        case 'auth/popup-blocked':
          errorMessage = "Popup was blocked by your browser. Please enable popups for this site.";
          break;
        case 'auth/email-already-in-use':
          // This might occur if the email is already in use but not yet linked
          errorMessage = "This email is already registered. Please sign in with email and password first.";
          if (error.email) {
            setEmail(error.email);
          }
          break;
        default:
          errorMessage = "An error occurred during sign in. Please try again.";
      }
      
      setNotice(errorMessage);
      // Ensure we're signed out in case of error
      try {
        await auth.signOut();
      } catch (signOutError) {
        console.error("Error signing out:", signOutError);
      }
    }
    setIsProcessing(false);
  };

  const loginWithUsernameAndPassword = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      sessionStorage.setItem("Auth Token", response._tokenResponse.refreshToken);
    } catch (error) {
      const errorMessage = getErrorMessage(error.code);
      setNotice(errorMessage);
      setIsProcessing(false);
      console.error("Error signing in: ", error);
    }
  };

  return (
    <main className="dark:bg-gray-900">
      <div className="relative flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                <a href="https://www.smilesflow.com" target="_blank" rel="noopener noreferrer">
                  <img alt="SmilesFlow" src={Logo} className="h-8" />
                </a>
                <div className="text-sm">
                  Don't have an account?{' '}
                  <Link to="/register" className="font-medium text-violet-500 hover:text-violet-600">
                    Sign up
                  </Link>
                </div>
              </div>
            </div>

            <div className="px-8 py-8 sm:px-6 sm:py-10">
              <div className="max-w-md mx-auto">
                <h2 className="text-2xl font-bold mb-2">Welcome back</h2>
                <p className="text-gray-600 mb-8">Sign in to your account to continue.</p>

                {notice && <div className="mb-6"><AlertError text={notice} dashboardButton={false} /></div>}

                {!showEmailForm ? (
                  <div className="space-y-6">
                    <div>
                      <button
                        onClick={handleGoogleLogin}
                        disabled={isProcessing}
                        className="w-full flex items-center justify-center gap-3 px-4 py-2.5 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
                      >
                        {isProcessing ? (
                          <span className="flex items-center">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Processing...
                          </span>
                        ) : (
                          <>
                            <svg className="h-5 w-5" viewBox="0 0 24 24">
                              <path
                                fill="#4285F4"
                                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                              />
                              <path
                                fill="#34A853"
                                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                              />
                              <path
                                fill="#FBBC05"
                                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                              />
                              <path
                                fill="#EA4335"
                                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                              />
                            </svg>
                            Continue with Google
                          </>
                        )}
                      </button>
                    </div>

                    <div className="relative">
                      <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300"></div>
                      </div>
                      <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500">Or</span>
                      </div>
                    </div>

                    <div>
                      <button
                        onClick={() => setShowEmailForm(true)}
                        className="w-full flex items-center justify-center px-4 py-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
                      >
                        Continue with Email
                      </button>
                    </div>
                  </div>
                ) : (
                  <form onSubmit={loginWithUsernameAndPassword} method="POST" className="space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        Email address
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 transition-all duration-200"
                        placeholder="name@company.com"
                      />
                    </div>

                    <div>
                      <div className="flex items-center justify-between mb-1">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                          Password
                        </label>
                        <button
                          type="button"
                          className="text-sm font-medium text-violet-500 hover:text-violet-600"
                          onClick={() => setShowResetModal(true)}
                        >
                          Forgot password?
                        </button>
                      </div>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        required
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full rounded-md border-0 py-2 px-3 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 transition-all duration-200"
                        placeholder="Enter your password"
                      />
                    </div>

                    <button
                      type="submit"
                      disabled={isProcessing}
                      className="flex w-full justify-center rounded-md bg-violet-500 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-600 focus:ring-2 focus:ring-violet-500 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isProcessing ? 'Signing in...' : 'Sign in'}
                    </button>

                    <div className="flex items-center justify-center">
                      <button
                        type="button"
                        onClick={() => setShowEmailForm(false)}
                        className="text-sm text-violet-600 hover:text-violet-500"
                      >
                        Back to Google sign-in
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/2 bg-gradient-to-br from-violet-400 via-violet-500 to-indigo-600 text-white">
          <div className="h-full flex flex-col justify-center p-6 lg:p-12">
            <div className="max-w-lg mx-auto">
              <h3 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6">Why businesses trust SmilesFlow</h3>
              
              <div className="space-y-4">
                <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
                  <p className="text-sm lg:text-base italic mb-3">
                    "SmilesFlow allowed us to create daily golf-based content for our socials. We have done all sorts from name the golf course from above, to guessing the golfer based on hints."
                  </p>
                  <div className="flex items-center">
                    <div className="w-8 h-8 bg-violet-200 rounded-full flex items-center justify-center">
                      <span className="text-violet-700 font-semibold text-sm">R</span>
                    </div>
                    <div className="ml-3">
                      <p className="font-semibold text-sm">Rob</p>
                      <p className="text-xs text-violet-200">Founder at Roballs</p>
                    </div>
                  </div>
                </div>

                <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
                  <p className="text-sm lg:text-base italic mb-3">
                    "Our business is small and local. The ability to create a game based on the local area that we can email out to drive our known customers to our store is unbelievable."
                  </p>
                  <div className="flex items-center">
                    <div className="w-8 h-8 bg-violet-200 rounded-full flex items-center justify-center">
                      <span className="text-violet-700 font-semibold text-sm">AH</span>
                    </div>
                    <div className="ml-3">
                      <p className="font-semibold text-sm">Amy Hahn</p>
                      <p className="text-xs text-violet-200">Founder at Smith Deli</p>
                    </div>
                  </div>
                </div>

                <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
                  <p className="text-sm lg:text-base italic mb-3">
                    "The ability to generate custom word games and quizzes tailored to our audience has been invaluable. Our followers love the new content, and we've seen a significant boost in both social media engagement and blog traffic."
                  </p>
                  <div className="flex items-center">
                    <div className="w-8 h-8 bg-violet-200 rounded-full flex items-center justify-center">
                      <span className="text-violet-700 font-semibold text-sm">SL</span>
                    </div>
                    <div className="ml-3">
                      <p className="font-semibold text-sm">Sarah L.</p>
                      <p className="text-xs text-violet-200">Marketing Director at WordPlay Media</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 text-center">
                <p className="text-xs text-violet-200">
                  Join hundreds of businesses already using SmilesFlow to improve their social engagement
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PasswordResetModal show={showResetModal} onClose={() => setShowResetModal(false)} auth={auth} />
    </main>
  );
}