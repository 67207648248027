import React, { useState, useEffect } from 'react';
import HeadlineCard from '../dashboard/HeadlineCard';
import { useUser } from '../userContext';
import { processImage } from '../utils/imageProcessor';
import { Tab } from '@headlessui/react';
import ColorPicker from '../components/ColorPicker';
import CustomFontPicker from '../dashboard/fontPicker';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

/**
 * This component implements a tabbed interface for editing headline profiles.
 * It replaces the previous popper-based implementation with tabs for:
 * 1. Preview - Shows how the headline will appear
 * 2. Image - Controls for uploading and styling the profile image
 * 3. Headline - Controls for editing the headline text and styling
 * 4. Subheadline - Controls for editing the subheadline text, URL, and styling
 * 
 * Changes are collected in local state and only applied when the user clicks "Save Changes".
 * This implementation aims to be more intuitive and user-friendly than the popper approach.
 */

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const HeadlineProfile = ({ options = {}, params, onOptionsUpdate }) => {
  const { user } = useUser();
  const [activeTab, setActiveTab] = useState(0);
  const [activeColorPicker, setActiveColorPicker] = useState(null);
  const [urlValidation, setUrlValidation] = useState({ isValid: false, message: '' });
  
  // Local state for editing
  const [localOptions, setLocalOptions] = useState({
    headline_url: '',
    headlinePhoto: '',
    headline: '',
    subheadline: '',
    imageShape: 'circle',
    headlineColor: '#000000',
    subheadlineColor: '#000000',
    iconColor: '#000000',
    imageBorderColor: 'transparent',
    imageBorderWidth: 0,
    headlineFont: 'Inter',
    subheadlineFont: 'Inter',
    showIcon: false,
  });

  // Initialize local options when props options change
  useEffect(() => {
    setLocalOptions({
      headline_url: options.headline_url || '',
      headlinePhoto: options.headlinePhoto || '',
      headline: options.headline || '',
      subheadline: options.subheadline || '',
      imageShape: options.imageShape || 'circle',
      headlineColor: options.headlineColor || '#000000',
      subheadlineColor: options.subheadlineColor || '#000000',
      iconColor: options.iconColor || '#000000',
      imageBorderColor: options.imageBorderColor || 'transparent',
      imageBorderWidth: options.imageBorderWidth || 0,
      headlineFont: options.headlineFont || 'Inter',
      subheadlineFont: options.subheadlineFont || 'Inter',
      showIcon: options.showIcon ?? false,
    });

    // Validate URL on mount if it exists
    if (options.headline_url) {
      validateUrl(options.headline_url);
    }
  }, [options]);

  const validateUrl = (url) => {
    if (!url) {
      setUrlValidation({ isValid: false, message: '' });
      return false;
    }

    try {
      new URL(url);
      setUrlValidation({ isValid: true, message: '' });
      return true;
    } catch (e) {
      setUrlValidation({ isValid: false, message: '' });
      return false;
    }
  };

  // Update parent's state without triggering a save
  const handleLocalUpdate = (updates) => {
    const newOptions = {
      ...localOptions,
      ...updates
    };
    setLocalOptions(newOptions);
    onOptionsUpdate(newOptions);
  };

  const handleUrlChange = (e) => {
    const url = e.target.value;
    validateUrl(url);
    handleLocalUpdate({ headline_url: url });
  };

  const handleImageUpload = async (file) => {
    try {
      // Process the image with smaller dimensions for headline photo
      const processedImage = await processImage(file, { width: 100, height: 100 });
      handleLocalUpdate({ headlinePhoto: processedImage });
    } catch (error) {
      console.error('Error processing image:', error);
    }
  };

  const tabs = [
    { name: 'Image', content: 'image' },
    { name: 'Headline', content: 'headline' },
    { name: 'Subheadline', content: 'subheadline' }
  ];

  return (
    <div className="w-full max-w-2xl">
      {/* Preview always visible at the top */}
      <div className="mb-8 p-6 bg-white shadow-sm rounded-xl border border-gray-100">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-sm font-medium text-gray-700">Live preview</h3>
        </div>
        <HeadlineCard
          customization={localOptions}
          params={params}
          user={user}
        />
      </div>

      <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
        <Tab.List className="flex space-x-1 rounded-xl bg-gray-50 p-1 mb-6">
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm font-medium leading-5 rounded-lg transition-all duration-200 flex items-center justify-center gap-2',
                  selected
                    ? 'bg-white shadow-sm text-violet-700 ring-1 ring-violet-200'
                    : 'text-gray-600 hover:bg-white/80 hover:text-gray-900'
                )
              }
            >
              {tab.name === 'Image' && (
                <svg className="hidden sm:block w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M14 8a2 2 0 11-4 0 2 2 0 014 0z" stroke="currentColor" strokeWidth="2"/>
                </svg>
              )}
              {tab.name === 'Headline' && (
                <svg className="hidden sm:block w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 6h16M4 12h16M4 18h7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              )}
              {tab.name === 'Subheadline' && (
                <svg className="hidden sm:block w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 7h16M4 11h16M4 15h10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              )}
              {tab.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="rounded-xl p-6 bg-white shadow-sm space-y-6 border border-gray-100">
            <div className="flex items-center justify-between border-b border-gray-100 pb-4">
              <h3 className="text-lg font-medium text-gray-900">Edit Image</h3>
            </div>
            <div className="flex justify-center mb-6">
              <div className="relative group">
                <img 
                  src={localOptions.headlinePhoto || "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"} 
                  alt="Profile"
                  className={`w-24 h-24 ${localOptions.imageShape === 'circle' ? 'rounded-full' : localOptions.imageShape === 'rounded' ? 'rounded-lg' : ''} transition-all duration-200`}
                />
                <div className="absolute inset-0 bg-black/0 group-hover:bg-black/10 rounded-full transition-all duration-200" />
              </div>
            </div>
            
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Upload Image
              </label>
              <div className="relative">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e.target.files[0])}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 transition-all duration-200"
                />
                <p className="mt-1 text-xs text-gray-500">
                  Recommended size: 100x100px. Image will be automatically resized.
                </p>
              </div>
            </div>
            
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-700">
                Image Shape
              </label>
              <div className="flex gap-4">
                <button
                  type="button"
                  onClick={() => handleLocalUpdate({ imageShape: 'circle' })}
                  className={`p-3 rounded-lg transition-all duration-200 ${
                    localOptions.imageShape === 'circle' 
                      ? 'bg-violet-50 ring-2 ring-violet-600' 
                      : 'bg-white ring-1 ring-gray-200 hover:ring-violet-200'
                  }`}
                >
                  <div className="w-10 h-10 rounded-full bg-gray-200" />
                </button>
                <button
                  type="button"
                  onClick={() => handleLocalUpdate({ imageShape: 'square' })}
                  className={`p-3 rounded-lg transition-all duration-200 ${
                    localOptions.imageShape === 'square' 
                      ? 'bg-violet-50 ring-2 ring-violet-600' 
                      : 'bg-white ring-1 ring-gray-200 hover:ring-violet-200'
                  }`}
                >
                  <div className="w-10 h-10 bg-gray-200" />
                </button>
                <button
                  type="button"
                  onClick={() => handleLocalUpdate({ imageShape: 'rounded' })}
                  className={`p-3 rounded-lg transition-all duration-200 ${
                    localOptions.imageShape === 'rounded' 
                      ? 'bg-violet-50 ring-2 ring-violet-600' 
                      : 'bg-white ring-1 ring-gray-200 hover:ring-violet-200'
                  }`}
                >
                  <div className="w-10 h-10 rounded-lg bg-gray-200" />
                </button>
              </div>
            </div>
          </Tab.Panel>
          
          <Tab.Panel className="rounded-xl p-6 bg-white shadow-sm space-y-6 border border-gray-100">
            <div className="flex items-center justify-between border-b border-gray-100 pb-4">
              <h3 className="text-lg font-medium text-gray-900">Edit Headline</h3>
            </div>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <label className="block text-sm font-medium text-gray-700">
                  Headline Text
                </label>
                <span className="text-xs text-gray-500">
                  {localOptions.headline.length}/30
                </span>
              </div>
              <input
                type="text"
                value={localOptions.headline}
                onChange={(e) => handleLocalUpdate({ headline: e.target.value })}
                className="block w-full text-sm border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-violet-500 focus:border-violet-500 transition-all duration-200"
                placeholder="Company name"
                maxLength={30}
              />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Font
              </label>
              <CustomFontPicker
                value={localOptions.headlineFont}
                onChange={(font) => handleLocalUpdate({ headlineFont: font })}
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <label className="block text-sm font-medium text-gray-700">
                  Color
                </label>
                {activeColorPicker && (
                  <button
                    type="button"
                    onClick={() => setActiveColorPicker(null)}
                    className="text-xs text-violet-600 hover:text-violet-700"
                  >
                    Done
                  </button>
                )}
              </div>
              <ColorPicker
                color={localOptions.headlineColor}
                onChange={(color) => handleLocalUpdate({ headlineColor: color })}
                isActive={activeColorPicker === 'headlineColor'}
                onToggle={() => setActiveColorPicker(
                  activeColorPicker === 'headlineColor' ? null : 'headlineColor'
                )}
              />
            </div>
          </Tab.Panel>
          
          <Tab.Panel className="rounded-xl p-6 bg-white shadow-sm space-y-6 border border-gray-100">
            <div className="flex items-center justify-between border-b border-gray-100 pb-4">
              <h3 className="text-lg font-medium text-gray-900">Edit Subheadline</h3>
            </div>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <label className="block text-sm font-medium text-gray-700">
                  Subheadline Text
                </label>
                <span className="text-xs text-gray-500">
                  {localOptions.subheadline.length}/40
                </span>
              </div>
              <input
                type="text"
                value={localOptions.subheadline}
                onChange={(e) => handleLocalUpdate({ subheadline: e.target.value })}
                className="block w-full text-sm border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-violet-500 focus:border-violet-500 transition-all duration-200"
                placeholder="Subheadline"
                maxLength={40}
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <label className="block text-sm font-medium text-gray-700">
                  Link URL (optional)
                </label>
                {localOptions.headline_url && (
                  <span className={`text-xs flex items-center gap-1 ${
                    urlValidation.isValid ? 'text-green-600' : 'text-red-600'
                  }`}>
                    {urlValidation.isValid ? (
                      <CheckCircleIcon className="w-3 h-3" />
                    ) : (
                      <XCircleIcon className="w-3 h-3" />
                    )}
                    {urlValidation.isValid ? 'Valid URL' : 'Invalid URL'}
                  </span>
                )}
              </div>
              <div className="space-y-2">
                <input
                  type="url"
                  value={localOptions.headline_url}
                  onChange={handleUrlChange}
                  className={`block w-full text-sm border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-violet-500 focus:border-violet-500 transition-all duration-200 ${
                    localOptions.headline_url && !urlValidation.isValid ? 'border-red-300' : ''
                  }`}
                  placeholder="https://example.com"
                  maxLength={2048}
                />
                <p className="text-xs text-gray-500">
                  Must start with http:// or https://
                </p>
              </div>
            </div>
            {localOptions.headline_url && urlValidation.isValid && (
              <div className="flex items-center justify-between py-2 px-4 bg-gray-50 rounded-lg">
                <label className="block text-sm font-medium text-gray-700">
                  Show Link Icon
                </label>
                <button
                  type="button"
                  onClick={() => handleLocalUpdate({ showIcon: !localOptions.showIcon })}
                  className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 ${
                    localOptions.showIcon ? 'bg-violet-600' : 'bg-gray-200'
                  }`}
                >
                  <span className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-all duration-200 ${
                    localOptions.showIcon ? 'translate-x-5' : 'translate-x-0'
                  }`} />
                </button>
              </div>
            )}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Font
              </label>
              <CustomFontPicker
                value={localOptions.subheadlineFont}
                onChange={(font) => handleLocalUpdate({ subheadlineFont: font })}
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <label className="block text-sm font-medium text-gray-700">
                  Color
                </label>
                {activeColorPicker && (
                  <button
                    type="button"
                    onClick={() => setActiveColorPicker(null)}
                    className="text-xs text-violet-600 hover:text-violet-700"
                  >
                    Done
                  </button>
                )}
              </div>
              <ColorPicker
                color={localOptions.subheadlineColor}
                onChange={(color) => handleLocalUpdate({ subheadlineColor: color })}
                isActive={activeColorPicker === 'subheadlineColor'}
                onToggle={() => setActiveColorPicker(
                  activeColorPicker === 'subheadlineColor' ? null : 'subheadlineColor'
                )}
              />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default HeadlineProfile;