import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '../../userContext';
import { useNavigate, Link } from 'react-router-dom';
import { db } from '../../firebase';
import { gameTypes } from '../../data/GameTypes';
import { getGameTypeColor } from '../../campaign/CreateCampaign';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
  writeBatch,
} from 'firebase/firestore';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePopper } from 'react-popper';
import { motion, AnimatePresence } from 'framer-motion';

const SuggestionContent = ({ suggestion, onRate, onImplement, isSkipping }) => {
  const navigate = useNavigate();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { 
        name: 'preventOverflow',
        options: {
          padding: 16,
          boundary: 'viewport'
        }
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-start', 'top-end', 'bottom-end'],
        },
      }
    ]
  });

  // Find the game type display name
  const gameTypeInfo = gameTypes.find(type => type.id.toLowerCase() === suggestion.gameType.toLowerCase());
  const gameTypeName = gameTypeInfo?.name || suggestion.gameType;
  const gameTypeColor = gameTypeInfo ? getGameTypeColor(gameTypeInfo.id) : '#4F46E5';
  const GameTypeIcon = gameTypeInfo?.icon;

  // Get color classes from the same mapping as SelectType
  const getColorClasses = (type) => {
    const colorMap = {
      "pindrop": {
        icon: "text-red-500 dark:text-red-400",
        bg: "bg-red-100 dark:bg-red-900/60",
      },
      "hint": {
        icon: "text-amber-500 dark:text-amber-400",
        bg: "bg-amber-100 dark:bg-amber-900/60",
      },
      "quiz": {
        icon: "text-blue-500 dark:text-blue-400",
        bg: "bg-blue-100 dark:bg-blue-900/60",
      },
      "mapselect": {
        icon: "text-emerald-500 dark:text-emerald-400",
        bg: "bg-emerald-100 dark:bg-emerald-900/60",
      },
      "snake": {
        icon: "text-purple-500 dark:text-purple-400",
        bg: "bg-purple-100 dark:bg-purple-900/60",
      }
    };

    return colorMap[type] || colorMap["quiz"];
  };

  const colors = getColorClasses(suggestion.gameType);

  const handleOpenFeedback = () => {
    setShowFeedbackModal(true);
  };

  const handleSkipWithFeedback = async () => {
    try {
      setShowFeedbackModal(false);
      await onRate(suggestion.id, feedback);
      setFeedback('');
    } catch (error) {
      console.error('Skip failed:', error);
    }
  };

  const popperContent = showFeedbackModal && (
    <div
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      className="bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 z-50"
    >
      <div className="flex flex-col gap-2 p-4">
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="How can we improve our suggestions?"
          className="w-full text-sm rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 shadow-sm focus:border-violet-500 focus:ring-violet-500"
          rows={3}
          autoFocus
        />
        <div className="flex justify-end gap-2">
          <button
            type="button"
            onClick={() => setShowFeedbackModal(false)}
            className="text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSkipWithFeedback}
            className="text-sm text-white bg-violet-500 hover:bg-violet-600 px-3 py-1 rounded-md transition-colors duration-200"
          >
            Skip
          </button>
        </div>
      </div>
    </div>
  );

  const handleImplement = () => {
    const params = new URLSearchParams({
      gameType: suggestion.gameType,
      title: suggestion.title
    }).toString();
    
    navigate(`/new?${params}`);
  };

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showFeedbackModal && 
          popperElement && 
          !popperElement.contains(event.target) &&
          referenceElement &&
          !referenceElement.contains(event.target)) {
        setShowFeedbackModal(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFeedbackModal, popperElement, referenceElement]);

  return (
    <motion.div 
      className="h-full flex flex-col p-6 rounded-2xl border transition-colors"
      style={{ 
        borderColor: `${gameTypeColor}15`,
        backgroundColor: `${gameTypeColor}04`
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
    >
      {/* Game Type Badge with AI indicator */}
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <div className={`relative flex items-center justify-center w-10 h-10 rounded-xl ${colors.bg}`}>
            {GameTypeIcon && (
              <GameTypeIcon className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5 h-5 ${colors.icon}`} />
            )}
          </div>
          <span 
            className={`ml-3 text-sm font-medium ${colors.icon}`}
          >
            {gameTypeName}
          </span>
        </div>
        <FontAwesomeIcon 
          icon={faWandMagicSparkles}
          className="w-5 h-5 text-violet-500 mb-1"
        />
      </div>

      {/* Game Topic */}
      <div className="flex-grow">
        <h3 className="text-gray-700 dark:text-gray-200 text-base leading-relaxed">
          {suggestion.title}
        </h3>
      </div>

      {/* Actions */}
      <div className="mt-6 flex justify-between items-center">
        <button
          ref={setReferenceElement}
          onClick={handleOpenFeedback}
          disabled={isSkipping}
          className={`text-sm ${
            isSkipping 
              ? 'text-gray-300 cursor-not-allowed' 
              : 'text-gray-400 hover:text-gray-600'
          }`}
        >
          Skip
        </button>
        <button
          onClick={handleImplement}
          className={`inline-flex items-center gap-2 text-sm font-medium ${colors.icon}`}
        >
          Create
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
            <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {popperContent}
    </motion.div>
  );
};

function AISuggestions() {
  const navigate = useNavigate();
  const { businessProfile } = useUser();
  const [suggestion, setSuggestion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSkipping, setIsSkipping] = useState(false);
  const [remainingSuggestions, setRemainingSuggestions] = useState(0);

  const hasAIProfile = businessProfile?.name && 
                    businessProfile?.description;

  const fetchSuggestion = async () => {
    if (!businessProfile?.id) return;
    if (!hasAIProfile) {
      setIsLoading(false);
      return;
    }

    try {
      const suggestionsRef = collection(db, 'company', businessProfile.id, 'aiSuggestions');
      const q = query(suggestionsRef, where('status', '==', 'new'));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        setSuggestion({ ...doc.data(), id: doc.id });
        setIsLoading(false);
      } else {
        await generateSuggestions();
      }
    } catch (err) {
      console.error('Error fetching suggestion:', err);
      setError('Failed to load suggestion');
      setIsLoading(false);
    }
  };

  const generateSuggestions = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Clear existing suggestions
      const suggestionsRef = collection(db, 'company', businessProfile.id, 'aiSuggestions');
      const q = query(suggestionsRef, where('status', '==', 'new'));
      const querySnapshot = await getDocs(q);

      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      // Generate new suggestions
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/app/generate-game-suggestions`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ companyId: businessProfile.id }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to generate suggestions');
      }

      await fetchSuggestion();
    } catch (err) {
      console.error('Error generating suggestions:', err);
      setError('Failed to generate new suggestions');
    } finally {
      setIsLoading(false);
    }
  };

  const countRemainingSuggestions = async () => {
    if (!businessProfile?.id) return 0;
    try {
      const suggestionsRef = collection(db, 'company', businessProfile.id, 'aiSuggestions');
      const q = query(suggestionsRef, where('status', '==', 'new'));
      const querySnapshot = await getDocs(q);
      return querySnapshot.size;
    } catch (error) {
      console.error('Error counting suggestions:', error);
      return 0;
    }
  };

  const rateSuggestion = async (suggestionId, rating, feedback) => {
    try {
      const suggestionRef = doc(db, 'company', businessProfile.id, 'aiSuggestions', suggestionId);
      await updateDoc(suggestionRef, {
        status: rating === 'like' ? 'implemented' : 'rejected',
        rating,
        feedback,
        ratedAt: serverTimestamp()
      });
      
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/app/update-suggestion-model`, {
        method: 'POST',
        body: JSON.stringify({ suggestionId, rating, feedback })
      });
    } catch (error) {
      console.error('Error rating suggestion:', error);
    }
  };

  const skipSuggestion = async (suggestionId, feedback) => {
    setIsSkipping(true);
    try {
      // Mark current suggestion as skipped with feedback
      const suggestionRef = doc(db, 'company', businessProfile.id, 'aiSuggestions', suggestionId);
      await updateDoc(suggestionRef, {
        status: 'skipped',
        rating: 'skip',
        feedback: feedback || null, // Store the feedback
        ratedAt: serverTimestamp()
      });

      // Count remaining suggestions
      const remaining = await countRemainingSuggestions();
      setRemainingSuggestions(remaining);

      // If running low on suggestions, generate more in the background
      if (remaining < 3) {
        generateSuggestionsInBackground();
      }

      // Fetch next suggestion
      await fetchSuggestion();
    } catch (error) {
      console.error('Error skipping suggestion:', error);
      setError('Failed to skip suggestion');
    } finally {
      setIsSkipping(false);
    }
  };

  const generateSuggestionsInBackground = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/app/generate-game-suggestions`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ companyId: businessProfile.id }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to generate suggestions in background');
      }
    } catch (error) {
      console.error('Error generating background suggestions:', error);
      // Don't show error to user since this is in the background
    }
  };

  const implementSuggestion = async (suggestionId) => {
    try {
      if (!suggestion) return;

      const suggestionRef = doc(db, 'company', businessProfile.id, 'aiSuggestions', suggestionId);
      await updateDoc(suggestionRef, {
        status: 'implemented',
        implementedAt: serverTimestamp()
      });

      navigate(`/new?gameType=${suggestion.gameType}&title=${encodeURIComponent(suggestion.title)}`);
    } catch (error) {
      console.error('Error implementing suggestion:', error);
      setError('Failed to implement suggestion');
    }
  };

  useEffect(() => {
    if (businessProfile) {
      fetchSuggestion();
      countRemainingSuggestions().then(setRemainingSuggestions);
    }
  }, [businessProfile]);

  return (
    <div className="bg-white shadow-sm rounded-xl pl-5 pt-5 pb-7 h-full flex flex-col">
      <div className="text-xs uppercase font-semibold text-gray-400">
        Quick Create
      </div>

      <div className="flex-grow flex flex-col min-h-0 mt-4">
        {/* New Campaign Button */}
        <div className="pr-5 pb-4 dark:border-gray-700">
          <Link
            to="/new"
            className="block w-full text-center bg-violet-500 hover:bg-violet-600 text-white px-4 py-2 rounded-md transition-colors duration-200"
          >
            New Campaign
          </Link>
        </div>

        {/* Suggestions Section */}
        <div className="flex-grow flex flex-col min-h-0 overflow-y-auto">
          <div className="pr-5 py-3">
            <h3 className="text-xs uppercase font-semibold text-gray-400">Suggestions</h3>
          </div>

          {!hasAIProfile ? (
            <div className="pr-5 flex flex-col items-center text-center py-8">
              <div className="rounded-full bg-indigo-50 p-3 mb-4">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 24 24" 
                  fill="currentColor" 
                  className="w-6 h-6 text-indigo-600"
                >
                  <path fillRule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5Z" clipRule="evenodd" />
                </svg>
              </div>
         
              <p className="text-sm text-gray-500 mb-4">
                Complete your company details to get personalized campaign suggestions.
              </p>
              <Link 
                to="/settings/company" 
                className="inline-flex items-center gap-1 text-sm font-medium text-indigo-600 hover:text-indigo-700"
              >
                Set up profile
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                  <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd" />
                </svg>
              </Link>
            </div>
          ) : error ? (
            <div className="text-red-500 text-sm text-center p-4">{error}</div>
          ) : (
            <div className="flex-grow pr-5">
              <AnimatePresence mode="wait">
                {(isLoading || isSkipping) ? (
                  <motion.div
                    key="loading"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="h-full flex flex-col p-6 rounded-2xl border border-gray-200 dark:border-gray-700"
                  >
                    {/* Shimmer loading effect for game type badge */}
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center gap-3">
                        <div className="w-10 h-10 rounded-xl bg-gray-200 dark:bg-gray-700 animate-pulse" />
                        <div className="w-20 h-4 rounded bg-gray-200 dark:bg-gray-700 animate-pulse" />
                      </div>
                      <div className="w-4 h-4 rounded bg-gray-200 dark:bg-gray-700 animate-pulse" />
                    </div>

                    {/* Shimmer loading effect for title */}
                    <div className="flex-grow space-y-3">
                      <div className="w-3/4 h-5 rounded bg-gray-200 dark:bg-gray-700 animate-pulse" />
                      <div className="w-1/2 h-5 rounded bg-gray-200 dark:bg-gray-700 animate-pulse" />
                    </div>

                    {/* Shimmer loading effect for actions */}
                    <div className="mt-6 flex justify-between items-center">
                      <div className="w-12 h-4 rounded bg-gray-200 dark:bg-gray-700 animate-pulse" />
                      <div className="w-16 h-4 rounded bg-gray-200 dark:bg-gray-700 animate-pulse" />
                    </div>
                  </motion.div>
                ) : suggestion ? (
                  <SuggestionContent
                    key={suggestion.id}
                    suggestion={suggestion}
                    onRate={skipSuggestion}
                    onImplement={implementSuggestion}
                    isSkipping={isSkipping}
                  />
                ) : null}
              </AnimatePresence>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AISuggestions;