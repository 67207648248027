import React, { useState, useEffect } from 'react';
import { useUser } from '../userContext';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import BeatLoader from 'react-spinners/BeatLoader'; // Ensure you have installed react-spinners
import { TrashIcon } from '@heroicons/react/24/outline';

// Initialize Firebase functions with the correct region
const functions = getFunctions(undefined, 'europe-west1');

// Initialize the function with the specific name
const createUserAndSendPasswordReset = httpsCallable(functions, 'createUserAndSendPasswordReset');

const deleteUser = httpsCallable(functions, 'deleteUser');

export default function AddUser() {
  const { user, isOwner, businessProfile, subscriptionType, userProfile } = useUser();
  const [email, setEmail] = useState('');
  const [addSuccess, setAddSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addingUser, setAddingUser] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]); // State to store the list of users
  const [userLimit, setUserLimit] = useState(0); // User limit based on the plan
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [infoBoxMessage, setInfoBoxMessage] = useState({
    type: null, // 'userAdded' | 'userRemoved' | 'error'
    message: ''
  });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  // Fetch users when the component mounts or when businessProfile changes
  const fetchUsers = async () => {
    if (!businessProfile?.id) return;
    try {
      setLoadingUsers(true);
      const usersQuery = query(
        collection(db, 'users'),
        where('companyId', '==', businessProfile.id)
      );
      const querySnapshot = await getDocs(usersQuery);
      const usersList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersList);
      setErrorMessage('');
    } catch (error) {
      console.error('Error fetching users:', error);
      setErrorMessage('Failed to load users.');
    } finally {
      setLoadingUsers(false);
    }
  };

  useEffect(() => {
    fetchUsers();

    // Set user limit based on subscription type
    if (subscriptionType === 'agency') {
      setUserLimit(25);
    } else {
      setUserLimit(1); // Changed from 5 to 1 for standard plan
    }
  }, [businessProfile, subscriptionType]);

  const handleDeleteUser = async (userId) => {
    setShowInfoBox(false); // Reset any existing messages
    try {
      setDeletingUserId(userId);
      await deleteUser({ userId });
      
      setInfoBoxMessage({
        type: 'userRemoved',
        message: 'User removed'
      });
      setShowInfoBox(true);
      
      await fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
      setInfoBoxMessage({
        type: 'error',
        message: 'Unable to remove user. Please try again.'
      });
      setShowInfoBox(true);
    } finally {
      setDeletingUserId(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !firstName || !lastName) {
      setInfoBoxMessage({
        type: 'error',
        message: 'All fields are required'
      });
      setShowInfoBox(true);
      return;
    }

    try {
      setAddingUser(true);
      const response = await createUserAndSendPasswordReset({
        email,
        firstName,
        lastName,
        companyId: businessProfile.id,
      });

      if (response.data) {
        setInfoBoxMessage({
          type: 'userAdded',
          message: 'They will receive an email to complete their account setup.'
        });
        setShowInfoBox(true);
        setEmail('');
        setFirstName('');
        setLastName('');
        fetchUsers();
      }
    } catch (error) {
      console.error('Error adding user:', error);
      setInfoBoxMessage({
        type: 'error',
        message: 'Unable to add user. Please try again.'
      });
      setShowInfoBox(true);
    } finally {
      setAddingUser(false);
    }
  };

  const isImpersonating = businessProfile?.id !== userProfile?.companyId;

  if (isImpersonating) {
    return (
      <div className="p-6 space-y-6 w-full">
        <div className="bg-yellow-100 w-full border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <p className="font-bold">Not Applicable</p>
          <p>User management is not available while impersonating a client.</p>
        </div>
      </div>
    );
  }

  if (!isOwner) {
    return (
      <div className="p-6 space-y-6 w-full">
        <div className="bg-yellow-100 w-full border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <p className="font-bold">Access Restricted</p>
          <p>Only the account administrator can manage users.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full px-8 py-6">
      <form onSubmit={handleSubmit}>
        <div className="space-y-12 sm:space-y-16">
          <div>
            <div className="flex items-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-700">Add User</h2>
              <span className={`ml-2 inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium ${
                users.length >= userLimit 
                  ? 'bg-red-100 text-red-800' 
                  : 'bg-gray-100 text-gray-800'
              }`}>
                {`${users.length}/${userLimit} users`}
              </span>
            </div>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Invite a new user to your company. They will receive an email with instructions to set up their account. New users can create and manage campaigns, but won't have access to billing, user management, or company settings.
            </p>

            {showInfoBox && (
              <div className={`mt-4 rounded-md ${
                infoBoxMessage.type === 'error' ? 'bg-red-50' : 'bg-green-50'
              } p-4`}>
                <div className="flex items-start justify-between">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      {infoBoxMessage.type !== 'error' ? (
                        <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                        </svg>
                      ) : (
                        <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
                        </svg>
                      )}
                    </div>
                    <div className="ml-3">
                      <p className={`text-sm ${
                        infoBoxMessage.type === 'error' ? 'text-red-700' : 'text-green-700'
                      }`}>
                        {infoBoxMessage.type === 'userAdded' 
                          ? 'Invitation sent! They will receive an email to set up their account.'
                          : infoBoxMessage.message}
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => setShowInfoBox(false)}
                    className={`ml-4 flex-shrink-0 p-0.5 rounded-full ${
                      infoBoxMessage.type === 'error' 
                        ? 'text-red-700 hover:text-red-600 hover:bg-red-100' 
                        : 'text-green-700 hover:text-green-600 hover:bg-green-100'
                    }`}
                  >
                    <span className="sr-only">Close</span>
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                    </svg>
                  </button>
                </div>
              </div>
            )}

            <div className="mt-6 bg-white shadow-sm ring-1 ring-gray-900/5 rounded-lg p-6">
              <div className="space-y-6">
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
                  <div>
                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                      First name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          className="block w-full border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          value={firstName}
                          required
                          placeholder="John"
                          onChange={(e) => setFirstName(e.target.value)}
                          disabled={addingUser || deletingUserId !== null || users.length >= userLimit}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                      Last name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          className="block w-full border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          value={lastName}
                          required
                          placeholder="Doe"
                          onChange={(e) => setLastName(e.target.value)}
                          disabled={addingUser || deletingUserId !== null || users.length >= userLimit}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <label htmlFor="user-email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                      <input
                        type="email"
                        name="user-email"
                        id="user-email"
                        className="block w-full border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        value={email}
                        required
                        placeholder="user@example.com"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrorMessage('');
                          setDeleteSuccess(false);
                          setAddSuccess(false);
                        }}
                        disabled={addingUser || deletingUserId !== null || users.length >= userLimit}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-start">
                  <button
                    type="submit"
                    disabled={!email || !firstName || !lastName || addingUser || deletingUserId !== null || users.length >= userLimit}
                    className={`inline-flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full sm:w-auto ${
                      email && !addingUser ? 'bg-violet-500 hover:bg-violet-600 focus-visible:outline-violet-600' : 'bg-gray-400 cursor-not-allowed'
                    }`}
                  >
                    {addingUser ? 'Sending invitation...' : 'Send Invitation'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="mt-10">
        <h2 className="text-base font-semibold leading-7 text-indigo-700">Current Users</h2>
        <p className="mt-1 text-sm text-gray-600">
          Users who have accepted their invitation and have access to your company.
        </p>
        {loadingUsers ? (
          <div className="flex justify-center items-center mt-4">
            <BeatLoader color="#4A90E2" loading={loadingUsers} />
          </div>
        ) : users.length === 0 ? (
          <p className="text-gray-500 mt-2">No users have accepted invitations yet.</p>
        ) : (
          <ul className="mt-4 space-y-2">
            {users.map((user) => (
              <li key={user.id} className="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm border border-gray-200">
                <div className="text-sm text-gray-700">
                  {user.email}{' '}
                  <span
                    className={`ml-2 inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium ${
                      user.id === businessProfile.ownerId ? 'bg-green-100 text-green-800' : ''
                    }`}
                  >
                    {user.id === businessProfile.ownerId ? 'Admin' : ''}
                  </span>
                </div>
                {user.id !== businessProfile.ownerId && (
                  <button
                    onClick={() => handleDeleteUser(user.id)}
                    disabled={addingUser || deletingUserId !== null}
                    className={`text-red-500 hover:text-red-700 transition-colors duration-200 ${
                      deletingUserId === user.id ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {deletingUserId === user.id ? (
                      <BeatLoader size={8} color="#EF4444" />
                    ) : (
                      <TrashIcon className="h-5 w-5" />
                    )}
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
