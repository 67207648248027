import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../userContext';
import Logo from '../images/logo.png';
import { getAuth, signOut } from 'firebase/auth';

function ErrorPage() {
  const navigate = useNavigate();
  const { user } = useUser();

  const handleContactUs = () => {
    // Redirect to your contact page or open a mailto link
    window.location.href = 'mailto:support@smilesflow.com'; // Replace with your contact URL or email
  };

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log('User signed out');
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-white via-gray-100 to-gray-300">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-2xl text-center max-w-sm w-full">
        <img src={Logo} alt="SmilesFlow Logo" className="mx-auto h-8 mb-6" />
        <h1 className="text-xl font-extrabold text-red-600 dark:text-red-400 mb-4">
          Oops! Something went wrong
        </h1>
        <p className="text-gray-700 dark:text-gray-300 mb-6">
          We're unable to load your account information. Please try signing out and logging in again or contact support for help.
        </p>
        <div className="space-y-4">
          {user && (
            <button
              onClick={handleSignOut}
              className="btn w-full bg-red-600 hover:bg-red-700 text-white py-2 rounded-md focus:outline-none shadow-md"
            >
              Sign Out
            </button>
          )}
          <button
            onClick={handleContactUs}
            className="btn w-full bg-violet-500 hover:bg-violet-600 text-white py-2 rounded-md focus:outline-none shadow-md"
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;