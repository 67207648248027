import { db } from '../firebase';
import { 
  collection, 
  doc, 
  query, 
  orderBy, 
  limit as limitQuery, 
  startAfter,
  where,
  getDocs, 
  addDoc, 
  deleteDoc, 
  updateDoc,
  getCountFromServer,
  getDoc
} from 'firebase/firestore';

export const leaderboardService = {
  async getTopScores(gameId, limit = 10, startAfterDoc = null) {
    try {
      const leaderboardRef = collection(doc(db, 'gameSummary', gameId), 'leaderboard');
      
      let queryRef = query(
        leaderboardRef,
        orderBy('score', 'desc'),
        limitQuery(limit)
      );

      if (startAfterDoc) {
        queryRef = query(queryRef, startAfter(startAfterDoc));
      }

      const snapshot = await getDocs(queryRef);
      const entries = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      return {
        entries,
        lastVisible: snapshot.docs[snapshot.docs.length - 1] || null,
        hasMore: snapshot.docs.length === limit
      };
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      throw error;
    }
  },

  async addScore(gameId, scoreData) {

    try {
      const leaderboardRef = collection(doc(db, 'gameSummary', gameId), 'leaderboard');
      const docRef = await addDoc(leaderboardRef, {
        ...scoreData,
        timestamp: new Date().toISOString()
      });
      const newEntry = await getDoc(docRef);
      return { id: newEntry.id, ...newEntry.data() };
    } catch (error) {
      console.error('Error adding score:', error);
      throw error;
    }
  },

  async updateScore(gameId, recordId, updates) {
    try {
      const docRef = doc(db, 'gameSummary', gameId, 'leaderboard', recordId);
      await updateDoc(docRef, {
        ...updates,
        timestamp: new Date().toISOString()
      });
      const updatedDoc = await getDoc(docRef);
      return { id: updatedDoc.id, ...updatedDoc.data() };
    } catch (error) {
      console.error('Error updating score:', error);
      throw error;
    }
  },

  async deleteEntry(gameId, entryId) {
    try {
      const docRef = doc(db, 'gameSummary', gameId, 'leaderboard', entryId);
      await deleteDoc(docRef);
    } catch (error) {
      console.error('Error deleting entry:', error);
      throw error;
    }
  },

  async getTotalEntries(gameId) {
    try {
      const leaderboardRef = collection(doc(db, 'gameSummary', gameId), 'leaderboard');
      const snapshot = await getCountFromServer(leaderboardRef);
      return snapshot.data().count;
    } catch (error) {
      console.error('Error fetching total leaderboard entries:', error);
      throw error;
    }
  },

  async getTopScoresWithUserRank(gameId, userScore, userName, recordId) {
    try {
      const leaderboardRef = collection(doc(db, 'gameSummary', gameId), 'leaderboard');
      
      // Query to get top 10 scores
      const topScoresQuery = query(
        leaderboardRef,
        orderBy('score', 'desc'),
        limitQuery(10)
      );

      // Query to count scores higher than or equal to user's score
      const userRankQuery = query(
        leaderboardRef,
        orderBy('score', 'desc'),
        where('score', '>=', userScore)
      );

      // If we have a recordId, also get the user's record
      const userRecordPromise = recordId ? getDoc(doc(leaderboardRef, recordId)) : null;

      const [topScoresSnapshot, userRankSnapshot, userRecord] = await Promise.all([
        getDocs(topScoresQuery),
        getDocs(userRankQuery),
        userRecordPromise
      ]);

      const topEntries = topScoresSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        isUser: doc.id === recordId
      }));

      // Find position of user's score in the sorted list
      const scoresAboveUser = userRankSnapshot.docs
        .filter(doc => doc.data().score > userScore).length;
      const userRank = scoresAboveUser + 1;

      // Check if user is in top 10
      const userInTopTen = topEntries.some(entry => entry.id === recordId);

      // If user is not in top 10, add their entry
      let userEntry = null;
      if (!userInTopTen && recordId) {
        const userData = userRecord?.data();
        userEntry = {
          id: recordId,
          name: userData?.name || userName,
          score: userData?.score || userScore,
          rank: userRank,
          isUser: true
        };
      }

      return {
        topEntries,
        userEntry,
        userRank
      };
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      throw error;
    }
  },

  async getAllScores(gameId) {
    try {
      const leaderboardRef = collection(db, 'gameSummary', gameId, 'leaderboard');
      const q = query(leaderboardRef, orderBy('score', 'desc'));
      const querySnapshot = await getDocs(q);
      
      const entries = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      return { entries };
    } catch (error) {
      console.error('Error fetching all scores:', error);
      throw error;
    }
  },
}; 