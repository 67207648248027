import React, { useState, useEffect } from 'react';
import { CheckIcon, StarIcon, BriefcaseIcon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useUser } from '../userContext';

function PlanSelection({ isModal = false }) {
  const { user, isOwner, subscriptionType, subscriptionStatus, trialEndDate, businessProfile, userProfile, hasActiveTrial } = useUser();
  const [annual, setAnnual] = useState(false);
  const [products, setProducts] = useState([]);
  const [loadingTier, setLoadingTier] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hasHadTrial, setHasHadTrial] = useState(false);

  const standardPlanFeatures = [
    'Unlimited campaigns',
    'AI game generation',
    'Access to all game types',
    'Branding customisation',
    '1 user account'
  ];

  const agencyPlanFeatures = [
    'All standard features',
    'Client management',
    'Game type proposals',
    'Advanced branding customisation',
    'Up to 25 user accounts',
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = collection(db, 'products');
        const activeProductsQuery = query(productsRef, where('active', '==', true));
        const querySnapshot = await getDocs(activeProductsQuery);

        const productsData = [];
        for (const doc of querySnapshot.docs) {
          const productData = doc.data();
          const pricesRef = collection(doc.ref, 'prices');
          const priceSnap = await getDocs(pricesRef);

          const prices = priceSnap.docs.map(priceDoc => ({
            id: priceDoc.id,
            ...priceDoc.data()
          }));
          productsData.push({ id: doc.id, ...productData, prices });
        }

        setProducts(productsData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const checkPreviousTrials = async () => {
      if (!businessProfile?.ownerId) return;
      
      try {
        const subscriptionsRef = collection(db, 'users', businessProfile.ownerId, 'subscriptions');
        const allSubscriptionsQuery = query(subscriptionsRef);
        const subscriptionDocs = await getDocs(allSubscriptionsQuery);
        
        // If they have any subscription records at all, they've had their trial
        const hasAnySubscription = !subscriptionDocs.empty;
        setHasHadTrial(hasAnySubscription);
        
      } catch (error) {
        console.error('Error checking subscription history:', error);
      }
    };

    checkPreviousTrials();
  }, [businessProfile?.ownerId]);

  const handleSelectTier = async (product) => {
    setSelectedProduct(product.id);
    const selectedPrice = product.prices.find(p => 
      (annual && (p.interval === 'year' || p.interval === 'yearly')) || 
      (!annual && p.interval === 'month')
    );
    if (selectedPrice) {
      setLoadingTier(product.id);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/app/create-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            priceId: selectedPrice.id,
            userId: user.uid
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.url) {
          window.location.href = data.url;
        } else {
          console.error('No checkout URL received');
          setLoadingTier(null);
        }
      } catch (error) {
        console.error('Error creating checkout session:', error);
        setLoadingTier(null);
        setSelectedProduct(null);
      }
    } else {
      console.error('No matching price found for the selected billing cycle');
      setSelectedProduct(null);
    }
  };

  const LoadingCard = () => (
    <div className="relative bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 shadow-sm rounded-b-lg animate-pulse">
      <div className="h-0.5 bg-gray-300 dark:bg-gray-500" aria-hidden="true"></div>
      <div className="px-5 pt-5 pb-6 border-b border-gray-300 dark:border-gray-600">
        <header className="flex items-center mb-2">
          <div className="w-6 h-6 rounded-full bg-gray-300 dark:bg-gray-500 mr-3"></div>
          <div className="h-6 bg-gray-300 dark:bg-gray-500 w-1/3 rounded"></div>
        </header>
        <div className="h-4 bg-gray-300 dark:bg-gray-500 w-2/3 mb-2 rounded"></div>
        <div className="h-8 bg-gray-300 dark:bg-gray-500 w-1/2 mb-4 rounded"></div>
        <div className="h-10 bg-gray-300 dark:bg-gray-500 w-full rounded"></div>
      </div>
      <div className="px-5 pt-4 pb-5">
        <div className="h-4 bg-gray-300 dark:bg-gray-500 w-1/4 mb-4 rounded"></div>
        <ul>
          {[1, 2, 3].map((_, index) => (
            <li key={index} className="flex items-center py-1">
              <div className="w-3 h-3 bg-gray-300 dark:bg-gray-500 rounded-full mr-2"></div>
              <div className="h-4 bg-gray-300 dark:bg-gray-500 w-3/4 rounded"></div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  const hasActiveSubscription = subscriptionStatus === 'active' || subscriptionStatus === 'trialing';

  const isImpersonating = businessProfile?.id !== userProfile?.companyId;

  if (isImpersonating) {
    return (
      <div className="p-6 space-y-6 w-full">
        <div className="bg-yellow-100 w-full border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <p className="font-bold">Not Applicable</p>
          <p>Plan management is not available while impersonating a client.</p>
        </div>
      </div>
    );
  }

  if (!isOwner) {
    return (
      <div className="p-6 space-y-6 w-full">
        <div className="bg-yellow-100 w-full border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <p className="font-bold">Access Restricted</p>
          <p>Only the account administrator can manage plans.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`w-full px-8 ${!isModal ? ' py-6' : ''}`}>
      {!isModal && (
        <>
          <div>
            <h2 className="text-base font-semibold leading-7 text-indigo-700">Plans</h2>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Manage your subscription and billing.
            </p>
          </div>

          {/* Current Plan Details */}
          <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
            <div className="flex flex-col space-y-2 xs:space-y-0 xs:flex-row xs:items-center xs:justify-between">
              <h3 className="text-sm font-medium text-gray-900">Current Plan</h3>
              
            </div>
            <div className="mt-2 text-sm text-gray-600 space-y-1">
              <p>
                Status: <span className="font-medium capitalize">{subscriptionStatus || 'No active subscription'}</span>
              </p>
              {subscriptionType && (
                <p>
                  Type: <span className="font-medium capitalize">{subscriptionType} Plan</span>
                </p>
              )}
              {trialEndDate && subscriptionStatus === 'trialing' && (
                <p>
                  Trial ends: <span className="font-medium">{new Date(trialEndDate).toLocaleDateString()}</span>
                </p>
              )}
              {subscriptionStatus === 'active' && (
                <p>
                  Billing cycle: <span className="font-medium capitalize">{annual ? 'Annual' : 'Monthly'}</span>
                </p>
              )}
            </div>
            {hasActiveSubscription && (
                <button
                  onClick={async () => {
                    setIsLoading(true);
                    try {
                      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/app/create-portal-session`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          userId: user.uid
                        })
                      });

                      if (!response.ok) {
                        throw new Error('Network response was not ok');
                      }

                      const data = await response.json();
                      if (data.url) {
                        window.location.href = data.url;
                      }
                    } catch (error) {
                      console.error('Error creating portal session:', error);
                      setIsLoading(false); // Reset loading state on error
                    }
                  }}
                  disabled={isLoading}
                  className="w-full mt-4 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-violet-500 hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 disabled:opacity-75 disabled:cursor-not-allowed"
                >
                  {isLoading ? 'Please wait...' : 'Manage subscription'}
                </button>
              )}
          </div>
        </>
      )}

      <div className="flex items-center space-x-3 mt-8 mb-6">
        <div className="text-sm text-gray-500 font-medium">Monthly</div>
        <Switch
          checked={annual}
          onChange={setAnnual}
          className={`${
            annual ? 'bg-green-500' : 'bg-gray-400'
          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
        >
          <span
            className={`${
              annual ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
          />
        </Switch>
        <div className="text-sm text-gray-500 font-medium">
          Annually <span className="text-green-500">(-20%)</span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {isLoading ? (
          <>
            <LoadingCard />
            <LoadingCard />
          </>
        ) : (
          products.map((product) => {
            const price = product.prices.find(p => 
              (annual && (p.interval === 'year' || p.interval === 'yearly')) || 
              (!annual && p.interval === 'month')
            );

            const isStandardPlan = product.id === 'standard' || product.name.toLowerCase() === 'standard';
            const isAgencyPlan = product.id === 'agency' || product.name.toLowerCase() === 'agency';

            let planFeatures;
            if (isStandardPlan) {
              planFeatures = standardPlanFeatures;
            } else if (isAgencyPlan) {
              planFeatures = agencyPlanFeatures;
            } else {
              planFeatures = product.features;
            }

            return (
              <div key={product.id} className="relative bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700/60 shadow-sm rounded-b-lg">
                <div className={`absolute top-0 left-0 right-0 h-0.5 ${isAgencyPlan ? 'bg-violet-500' : 'bg-green-500'}`} aria-hidden="true"></div>
                <div className="px-5 pt-5 pb-6 border-b border-gray-200 dark:border-gray-700/60">
                  <header className="flex items-center mb-2">
                    <div className={`w-6 h-6 rounded-full shrink-0 ${isAgencyPlan ? 'bg-violet-500' : 'bg-green-500'} mr-3 flex justify-center items-center`}>
                      {isAgencyPlan ? (
                        <BriefcaseIcon className="w-4 h-4 fill-current text-white" aria-hidden="true" />
                      ) : (
                        <StarIcon className="w-4 h-4 fill-current text-white" aria-hidden="true" />
                      )}
                    </div>
                    <h3 className="text-lg text-gray-800 dark:text-gray-100 font-semibold">
                      {product.name}
                      {product.name.toLowerCase() === subscriptionType?.toLowerCase() && (
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 text-xs font-medium rounded-full bg-green-100 text-green-800">
                          Current Plan
                        </span>
                      )}
                    </h3>
                  </header>
                  <div className="text-sm mb-2">{product.description}</div>
                  <div className="text-gray-800 dark:text-gray-100 font-bold mb-4">
                    {price ? (
                      <>
                        {!hasActiveSubscription && !hasHadTrial && (
                          <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 dark:bg-green-900/30 text-green-800 dark:text-green-400 mb-3">
                            7-day free trial
                          </div>
                        )}
                        <div className="flex items-baseline">
                          <span className="text-3xl font-bold">
                            £{(price.unit_amount / 100).toFixed(2)}
                          </span>
                          <span className="text-gray-500 dark:text-gray-400 font-medium text-sm ml-1">
                            /{price.interval}
                          </span>
                        </div>
                      </>
                    ) : (
                      <span className="text-sm">Price not available</span>
                    )}
                  </div>
                  {!hasActiveSubscription && (
                    <>
                      <button
                        onClick={() => handleSelectTier(product)}
                        className={`btn py-3 rounded-lg text-white text-base font-medium w-full transition-all ${
                          selectedProduct && selectedProduct !== product.id
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-gray-800 hover:bg-gray-900 hover:shadow-md'
                        }`}
                        disabled={loadingTier === product.id || !price || (selectedProduct && selectedProduct !== product.id)}
                      >
                        {loadingTier === product.id ? 'Please wait...' : hasHadTrial ? 'Select Plan' : 'Start Your Free Trial'}
                      </button>
                      {!hasHadTrial && (
                        <p className="text-center text-xs text-gray-500 dark:text-gray-400 mt-2">
                          Cancel anytime during your trial period
                        </p>
                      )}
                    </>
                  )}
                </div>
                <div className="px-5 pt-4 pb-5">
                  <div className="text-xs text-gray-800 dark:text-gray-100 font-semibold uppercase mb-4">What's included</div>
                  <ul>
                    {planFeatures?.map((feature, index) => (
                      <li key={index} className="flex items-center py-1">
                        <CheckIcon className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" aria-hidden="true" />
                        <div className="text-sm">{feature}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default PlanSelection;
