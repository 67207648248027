import React, { useState, useRef } from 'react';
import { PulseLoader } from 'react-spinners';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';

export default function RegenerateButton({ onClick, isRegenerating, label = "Regenerate" }) {
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const buttonRef = useRef(null);
  const popperRef = useRef(null);
  const tooltipRef = useRef(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const { styles, attributes } = usePopper(buttonRef.current, popperRef.current, {
    placement: 'bottom',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'arrow', options: { element: arrowRef } },
      {
        name: 'preventOverflow',
        options: { 
          boundary: 'viewport',
          padding: 8
        }
      },
      {
        name: 'flip',
        options: { 
          fallbackPlacements: ['top', 'right', 'left'],
          boundary: 'viewport'
        }
      }
    ]
  });

  const handleOpenComment = () => {
    setShowCommentInput(true);
  };

  const handleRegenerate = async () => {
    try {
      setIsLoading(true);
      setShowCommentInput(false);
      await onClick(comment);
      setComment('');
    } catch (error) {
      console.error('Regeneration failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateTooltipPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top - 30, // Position above the button
        left: rect.left + (rect.width / 2) // Center horizontally
      });
    }
  };

  // Update tooltip position on hover
  const handleMouseEnter = () => {
    setShowTooltip(true);
    updateTooltipPosition();
  };

  return (
    <>
      <div className="relative group">
        <button
          ref={buttonRef}
          onClick={handleOpenComment}
          disabled={isRegenerating || isLoading}
          type='button'
          className={`text-sm inline-flex items-center gap-1 ${
            isRegenerating || isLoading
              ? 'text-gray-400' 
              : 'text-indigo-600 hover:text-indigo-900'
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {(!label || label === "Regenerate") && (
            <SparklesIcon className={`h-4 w-4 transition-transform duration-200 ease-in-out hover:scale-125 ${
              isLoading || isRegenerating ? 'animate-pulse' : ''
            }`} />
          )}
          {label && label !== "Regenerate" && (
            <span className={isLoading || isRegenerating ? 'animate-pulse' : ''}>
              {label}
            </span>
          )}
        </button>

        {/* Tooltip Portal */}
        {showTooltip && createPortal(
          <div 
            style={{
              position: 'fixed',
              top: `${tooltipPosition.top}px`,
              left: `${tooltipPosition.left}px`,
              transform: 'translateX(-50%)',
              zIndex: 99999
            }}
            className="px-2 py-1 text-xs font-medium text-white bg-gray-900 rounded-md shadow-sm whitespace-nowrap transition-opacity duration-200"
          >
            Smart Generate
          </div>,
          document.body
        )}
      </div>

      {showCommentInput && createPortal(
        <div
          ref={popperRef}
          style={{
            ...styles.popper,
            zIndex: 99999,
            position: 'absolute'
          }}
          {...attributes.popper}
          className="w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700"
        >
          <div className="flex flex-col gap-2 p-4">
            <input
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Any specific requests?"
              className="w-full text-sm rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              autoFocus
            />
            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={() => setShowCommentInput(false)}
                className="text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleRegenerate}
                className="text-sm text-white bg-violet-500  hover:bg-violet-600 px-3 py-1 rounded-md"
              >
                Submit
              </button>
            </div>
          </div>
          <div
            ref={setArrowRef}
            style={styles.arrow}
            className="absolute w-3 h-3 bg-white dark:bg-gray-800 border-t border-r border-gray-200 dark:border-gray-700 transform rotate-45"
          />
        </div>,
        document.body
      )}
    </>
  );
}
