import React, { useState, useEffect } from 'react';
import { useUser } from '../userContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import HeadlineProfile from './HeadlineSetupProfile';
import { BeatLoader } from 'react-spinners';
import { HexColorPicker } from 'react-colorful';
import ColorPicker from '../components/ColorPicker';
import CustomFontPicker from '../dashboard/fontPicker';
import StartCard from '../games/StartCard';

const defaultTheme = {
  colors: {
    primary: '#7C3AED',
    accent: '#4B5563',
  },
  typography: {
    primaryFont: 'Inter',
    secondaryFont: 'Inter'
  }
};

export default function Company() {
  const { user, businessProfile, isOwner, userProfile } = useUser();
  const [options, setOptions] = useState({
    headline: '',
    subheadline: '',
    headline_url: '',
    headlinePhoto: '',
    displayHeadline: true,
    name: '',
    description: '',
    theme: defaultTheme,
    imageShape: 'circle',
    headlineColor: '#374151',
    subheadlineColor: '#6B7280',
    iconColor: '#6B7280',
    imageBorderColor: 'transparent',
    imageBorderWidth: 0,
    headlineFont: '',
    subheadlineFont: '',
    showIcon: false
  });
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showStartPicker, setShowStartPicker] = useState(false);
  const [showCardPicker, setShowCardPicker] = useState(false);
  const [activeColorPicker, setActiveColorPicker] = useState(null);

  useEffect(() => {
    if (businessProfile) {
      setOptions({
        ...options,
        headline: businessProfile.headline || '',
        subheadline: businessProfile.subheadline || '',
        headline_url: businessProfile.headline_url || '', // Make sure this is handled consistently
        headlinePhoto: businessProfile.headlinePhoto || '',
        displayHeadline: true,
        name: businessProfile.name || '',
        description: businessProfile.description || '',
        theme: {
          colors: {
            primary: businessProfile.theme?.colors?.primary || defaultTheme.colors.primary,
            accent: businessProfile.theme?.colors?.accent || defaultTheme.colors.accent,
          },
          typography: {
            primaryFont: businessProfile.theme?.typography?.primaryFont || defaultTheme.typography.primaryFont,
            secondaryFont: businessProfile.theme?.typography?.secondaryFont || defaultTheme.typography.secondaryFont
          }
        },
        imageShape: businessProfile.imageShape || 'circle',
        headlineColor: businessProfile.headlineColor || '#374151',
        subheadlineColor: businessProfile.subheadlineColor || '#6B7280',
        iconColor: businessProfile.iconColor || '#6B7280',
        imageBorderColor: businessProfile.imageBorderColor || 'transparent',
        imageBorderWidth: businessProfile.imageBorderWidth || 0,
        headlineFont: businessProfile.headlineFont || 'inherit',
        subheadlineFont: businessProfile.subheadlineFont || 'inherit',
        showIcon: businessProfile.headline_url ? (businessProfile.showIcon !== false) : false
      });
    }
  }, [businessProfile]);

  if (loading || !businessProfile) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOptions({
      ...options,
      [name]: value
    });
    setUnsavedChanges(true);
    setSaveSuccess(false); // Reset success message on change
  };

  const onOptionsUpdate = (updatedOptions) => {

    setOptions(prev => {
      const newOptions = {
        ...prev,
        ...updatedOptions,
        headline_url: updatedOptions.headline_url || '', // Ensure consistent empty state
      };
      return newOptions;
    });
    setUnsavedChanges(true);
    setSaveSuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (businessProfile && user) {
      setLoading(true);
      const businessDocRef = doc(db, 'company', businessProfile.id);
      
      const updateData = {
        headline: options.headline,
        subheadline: options.subheadline,
        headline_url: options.headline_url, // This should match what we're saving
        headlinePhoto: options.headlinePhoto,
        name: options.name,
        description: options.description,
        theme: options.theme,
        imageShape: options.imageShape,
        headlineColor: options.headlineColor,
        subheadlineColor: options.subheadlineColor,
        iconColor: options.iconColor,
        imageBorderColor: options.imageBorderColor,
        imageBorderWidth: options.imageBorderWidth,
        headlineFont: options.headlineFont,
        subheadlineFont: options.subheadlineFont,
        showIcon: options.showIcon
      };
      
      
      try {
        await updateDoc(businessDocRef, updateData);
        setUnsavedChanges(false);
        setSaveSuccess(true);
      } catch (error) {
        console.error("Error updating document: ", error);
      } finally {
        setLoading(false);
        setTimeout(() => setSaveSuccess(false), 3000);
      }
    }
  };

  const handleThemeUpdate = (updates) => {
    setOptions(prev => ({
        ...prev,
        theme: {
            ...prev.theme,
            colors: {
                ...prev.theme.colors,
                // Only update primary/accent colors
                ...(updates.colors?.primary && { primary: updates.colors.primary }),
                ...(updates.colors?.accent && { accent: updates.colors.accent })
            },
            typography: {
                ...prev.theme.typography,
                // Add typography updates
                ...(updates.typography && updates.typography)
            }
        }
    }));
    setUnsavedChanges(true);
  };

  // Check if user is agency managing client
  const canManageCompany = () => {
    // Allow if user is owner
    if (isOwner) return true;

    // Allow if user is from agency and viewing client
    if (businessProfile?.parentCompany === userProfile?.companyId) {
      return true;
    }

    return false;
  };

  if (!canManageCompany()) {
    return (
      <div className="p-6 space-y-6 w-full">
        <div className="bg-yellow-100 w-full border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <p className="font-bold">Access Restricted</p>
          <p>Only the account administrator or agency manager can manage company information.</p>
        </div>
      </div>
    );
  }



  return (
    <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <form onSubmit={handleSubmit}>
        <div className="space-y-10">
          {/* Header Section */}
          <div className="border-b border-gray-200 pb-6">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div>
                <div className="flex items-center mb-1">
                  <h2 className="text-base font-semibold leading-7 text-indigo-700">Company Information</h2>
                  {unsavedChanges && !saveSuccess && (
                    <span className="ml-2 inline-flex items-center rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">
                      Unsaved changes
                    </span>
                  )}
                  {saveSuccess && (
                    <span className="ml-2 inline-flex items-center rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                      Changes saved
                    </span>
                  )}
                </div>
                <p className="text-sm leading-6 text-gray-600">
                  Manage your company information.
                </p>
              </div>
              <button
                type="submit"
                disabled={!unsavedChanges || loading}
                className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 min-w-[100px] transition-all duration-200 ${
                  unsavedChanges && !loading 
                    ? 'bg-violet-500 hover:bg-violet-600 focus-visible:outline-violet-600' 
                    : 'bg-gray-400 cursor-not-allowed'
                }`}
              >
                {loading ? (
                  <BeatLoader size={8} color="#ffffff" />
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </div>

          {/* Basic Information Section */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-100">
              <h2 className="text-lg font-medium text-gray-900">Basic Information</h2>
              <p className="mt-1 text-sm text-gray-600">
                This information helps us understand your business better and powers AI-driven suggestions.
              </p>
            </div>
            <div className="px-6 py-6 space-y-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Business Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={options.name}
                    onChange={handleChange}
                    placeholder="Enter your business name"
                    className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-violet-500 focus:border-violet-500 sm:text-sm transition-all duration-200"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  About Your Business
                </label>
                <textarea
                  name="description"
                  value={options.description}
                  onChange={handleChange}
                  placeholder="e.g., We're a family-owned bakery specializing in artisanal breads and pastries..."
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-violet-500 focus:border-violet-500 sm:text-sm transition-all duration-200"
                  rows={4}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Tell us about your business to help our AI provide more relevant and personalized suggestions.
                </p>
              </div>
            </div>
          </div>

          {/* Header Section */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-100">
              <h2 className="text-lg font-medium text-gray-900">Header</h2>
              <p className="mt-1 text-sm text-gray-600">
                Changes will update your company headline across all campaigns.
              </p>
            </div>
            <div className="px-6 py-6">
              <HeadlineProfile
                options={options}
                params={businessProfile.id}
                handleChange={handleChange}
                onOptionsUpdate={onOptionsUpdate}
              />
            </div>
          </div>

          {/* Brand Settings Section */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-100">
              <h2 className="text-lg font-medium text-gray-900">Brand Settings</h2>
              <p className="mt-1 text-sm text-gray-600">
                Define your brand colors and typography.
              </p>
            </div>
            <div className="px-6 py-6 space-y-8">
              <div className="space-y-6">
                <h3 className="text-sm font-medium text-gray-900">Brand Colors</h3>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                  <ColorPicker
                    label="Primary Color"
                    color={options.theme?.colors?.primary || defaultTheme.colors.primary}
                    onChange={(color) => handleThemeUpdate({
                      colors: {
                        primary: color
                      }
                    })}
                    isActive={activeColorPicker === 'primary'}
                    onToggle={() => setActiveColorPicker(activeColorPicker === 'primary' ? null : 'primary')}
                  />

                  <ColorPicker
                    label="Accent Color"
                    color={options.theme?.colors?.accent || defaultTheme.colors.accent}
                    onChange={(color) => handleThemeUpdate({
                      colors: {
                        accent: color
                      }
                    })}
                    isActive={activeColorPicker === 'accent'}
                    onToggle={() => setActiveColorPicker(activeColorPicker === 'accent' ? null : 'accent')}
                  />
                </div>
              </div>
              
              <div className="space-y-6">
                <h3 className="text-sm font-medium text-gray-900">Typography</h3>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                  <CustomFontPicker 
                    label="Primary Font"
                    value={options.theme?.typography?.primaryFont || defaultTheme.typography.primaryFont}
                    onChange={(font) => handleThemeUpdate({
                      typography: {
                        ...options.theme?.typography,
                        primaryFont: font
                      }
                    })}
                  />
                  <CustomFontPicker 
                    label="Secondary Font"
                    value={options.theme?.typography?.secondaryFont || defaultTheme.typography.secondaryFont}
                    onChange={(font) => handleThemeUpdate({
                      typography: {
                        ...options.theme?.typography,
                        secondaryFont: font
                      }
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}