import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import ColorPicker from '../components/ColorPicker';
import CustomFontPicker from './fontPicker';
import FileUpload from '../games/FileUpload';
import { usePopper } from 'react-popper';

const getContrastingBackground = (color) => {
  return '#ffffff';
};

const getContrastingHover = (color) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)';
};

const HeadlineCard = ({ 
  customization = {}, 
  params,
  user
}) => {
  // Initialize with empty strings to prevent null/undefined issues
  const defaultPhotoUrl = 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541';
  
  // Image shape styles with default
  const getImageShape = () => {
    const shape = customization?.imageShape;
    switch (shape) {
      case 'square': return 'rounded-none';
      case 'rounded': return 'rounded-md';
      case 'circle':
      default: return 'rounded-full';
    }
  };

  // Format URL with http/https if needed
  const formattedUrl = customization?.headline_url && !customization.headline_url.startsWith('http') 
    ? `http://${customization.headline_url}` 
    : customization?.headline_url;

  // Container logic - use anchor tag if URL exists
  const Container = formattedUrl ? 'a' : 'div';
  const containerProps = formattedUrl
    ? {
        href: formattedUrl,
        target: "_blank",
        rel: "noopener noreferrer",
        className: "group block flex-shrink-0 pb-5",
      }
    : {
        className: "block flex-shrink-0 pb-5",
      };

  return (
    <Container {...containerProps}>
      <div className="flex flex-col">
        <div className="flex items-center">
          <img
            className={`inline-block h-9 w-9 ${getImageShape()}`}
            src={customization?.headlinePhoto || defaultPhotoUrl}
            alt="Profile"
            style={{
              borderColor: customization?.imageBorderColor || 'transparent',
              borderWidth: `${customization?.imageBorderWidth || 0}px`
            }}
          />
          <div className="ml-3">
            {customization?.headline && (
              <p 
                className="text-sm font-medium" 
                style={{
                  color: customization.headlineColor,
                  fontFamily: customization.headlineFont
                }}
              >
                {customization.headline}
              </p>
            )}
            {customization?.subheadline && (
              <p 
                className="text-xs font-medium flex items-center"
                style={{
                  color: customization.subheadlineColor,
                  fontFamily: customization.subheadlineFont
                }}
              >
                {customization.subheadline}
                {formattedUrl && customization?.showIcon && (
                  <ArrowTopRightOnSquareIcon 
                    className="ml-1 h-3 w-3" 
                    style={{ color: customization.subheadlineColor }}
                    aria-hidden="true" 
                  />
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HeadlineCard;