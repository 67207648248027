import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from './userContext';
import DefaultLoading from './common/DefaultLoading';
import SubscriptionModal from './Onboarding/SubscriptionModal';
import { getAuth, signOut } from 'firebase/auth';

const PrivateRoute = ({ children }) => {
  const { user, loading, subscriptionStatus, refreshUser } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    let modalTimeout;

    const checkSubscriptionStatus = () => {
      if (!loading) {
        if (user && (location.search.includes('session_id') || 
            subscriptionStatus === 'inactive' ||
            !['active', 'trialing', 'payment_overdue'].includes(subscriptionStatus))) {
          modalTimeout = setTimeout(() => {
            setShowModal(true);
          }, 500);
        } else {
          setShowModal(false);
        }
        setIsReady(true);
      }
    };

    checkSubscriptionStatus();

    return () => {
      if (modalTimeout) {
        clearTimeout(modalTimeout);
      }
    };
  }, [user, subscriptionStatus, location.search, loading]);

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        // Handle sign out error silently
      });
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/');
  };

  if (loading || !isReady) {
    return <DefaultLoading />;
  }

  if (!user) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return (
    <>
      {children}

      <SubscriptionModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleSignOut={handleSignOut}
        onClose={handleModalClose}
      />
    </>
  );
};

export default PrivateRoute;
