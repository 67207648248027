import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, orderBy, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import CampaignsCard from '../partials/campaigns/CampaignsCard';
import CampaignEmptyState from "./NoLiveCampaign";
import Loading from "../common/DefaultLoading";
import DropdownFilter from '../components/DropdownFilter';
import { useUser } from '../userContext';
import useAuth from '../dashboard/useAuth';
import { MegaphoneIcon } from '@heroicons/react/24/outline';

export default function FullWidthGrid() {
  const [campaigns, setCampaigns] = useState([]); // All campaigns loaded from the database
  const [visibleCampaigns, setVisibleCampaigns] = useState([]); // Currently visible campaigns after filtering
  const [isLoading, setIsLoading] = useState(true); // Loading state for the initial load
  const [selectedFilters, setSelectedFilters] = useState({
    draft: true,
    ready: true,
    live: true,
    complete: false, // By default, exclude completed campaigns
  });
  const { user, loading } = useAuth(); // Auth hook
  const navigate = useNavigate();
  const { businessProfile } = useUser();
  useEffect(() => {
    async function fetchData() {
      try {
        if (!user) return;

        // Fetch the initial batch of campaigns
        const q = query(
          collection(db, 'campaign'),
          where("companyId", "==", businessProfile.id),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        const fetchedCampaigns = [];
        querySnapshot.forEach((doc) => {
          fetchedCampaigns.push({ id: doc.id, ...doc.data() });
        });

        // Update the campaigns and visible campaigns
        setCampaigns(fetchedCampaigns);
        applyFilters(fetchedCampaigns, selectedFilters);
      } catch (error) {
        console.error("Error fetching data: ", error);
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [user, navigate]);

  // Handle filter changes
  const handleFilterChange = (status) => {
    const updatedFilters = {
      ...selectedFilters,
      [status]: !selectedFilters[status],
    };
    setSelectedFilters(updatedFilters);
    applyFilters(campaigns, updatedFilters); // Reapply the filters
  };

  // Apply filters to the loaded campaigns
  const applyFilters = (allCampaigns, filters) => {
    const filtered = allCampaigns.filter(campaign => filters[campaign.status]);
    setVisibleCampaigns(filtered);
  };

  // Show loading spinner or campaigns based on the state
  if (loading || isLoading) {
    return <Loading />;
  }

  const slideoverTitle = "New Campaign";

  return (
    <div className="sm:px-4 pb-5 max-w-7xl mx-auto">
      <div className="mb-5 pb-3 flex justify-between items-center">
        <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Campaigns</h1>

        <div className="flex items-center gap-2">
          {/* Filter Button */}
          <DropdownFilter selectedFilters={selectedFilters} onFilterChange={handleFilterChange} />

          {/* Create Campaign Button */}
          <button
            className="flex items-center gap-2 px-4 py-2 bg-violet-500 text-white hover:bg-violet-600 rounded-md transition-colors duration-200"
            onClick={() => navigate('/new')}
          >
            <span className="text-sm">New Campaign</span>
          </button>
        </div>
      </div>

      {/* Check if user has any campaigns */}
      {campaigns.length === 0 ? (
        <div className="text-center py-12 mt-8">
          <MegaphoneIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-4 text-lg font-medium text-gray-900">No campaigns yet</h3>
          <p className="mt-2 text-sm text-gray-500">
            Get started by creating your first campaign
          </p>
          <div className="mt-6">
            <button
              onClick={() => navigate('/new')}
              className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-white bg-violet-500 hover:bg-violet-600 transition-colors duration-200"
            >
              Create Campaign
            </button>
          </div>
        </div>
      ) : (
        <>
          {/* Check if any campaigns match the filters */}
          {visibleCampaigns.length > 0 ? (
            <div className="grid grid-cols-12 gap-6">
              {visibleCampaigns.map((campaign) => (
                <CampaignsCard key={campaign.id} campaign={campaign} onClick={() => navigate(`/campaign/${campaign.id}`, { state: { from: '/campaigns' } })} />
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500 py-10">
              No campaigns matching that filter.
            </div>
          )}
        </>
      )}
    </div>
  );
}
