export const processImage = async (file, maxDimensions = { width: 1200, height: 1200 }) => {
  // Check if file is an image
  if (!file.type.startsWith('image/')) {
    throw new Error('File must be an image');
  }

  // Determine if this is a small game asset (like cube images)
  const isGameAsset = maxDimensions.width <= 256 && maxDimensions.height <= 256;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = maxDimensions.width;
        const MAX_HEIGHT = maxDimensions.height;
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions maintaining aspect ratio
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        // For game assets, ensure we have a square canvas with the image centered
        if (isGameAsset) {
          const size = Math.max(MAX_WIDTH, MAX_HEIGHT);
          canvas.width = size;
          canvas.height = size;
          
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, size, size);
          
          // Center the image in the canvas
          const x = (size - width) / 2;
          const y = (size - height) / 2;
          
          // Enable high quality image smoothing
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';
          
          // Draw image centered
          ctx.drawImage(img, 0, 0, img.width, img.height, x, y, width, height);
        } else {
          // For regular images, just resize to fit within maxDimensions
          canvas.width = width;
          canvas.height = height;
          
          const ctx = canvas.getContext('2d');
          // Use high quality image smoothing
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';
          ctx.drawImage(img, 0, 0, width, height);
        }

        // Choose format and quality based on image type
        const outputFormat = isGameAsset ? 'image/png' : file.type;
        const quality = isGameAsset ? 1.0 : 0.92; // High quality for all images
        
        // Convert to data URL with appropriate quality
        const dataUrl = canvas.toDataURL(outputFormat, quality);
        resolve(dataUrl);
      };
      img.onerror = reject;
    };
    reader.onerror = reject;
  });
}; 